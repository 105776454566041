/*COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. 
*/
import React, { useState, useEffect } from "react";
import { alertActions } from "../../../_actions";
import PropTypes from "prop-types";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TextField,
  Paper,
  InputAdornment,
  Tab,
  Button,
} from "@material-ui/core";
import ChipInput from "material-ui-chip-input";
import _, { range } from "lodash";
import FilterDropdown from "_components/FilterDropdown";
import SearchIcon from "@material-ui/icons/Search";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import SaveIcon from "@mui/icons-material/Save";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import CommentBankIcon from "@mui/icons-material/CommentBank";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { makeStyles } from "@material-ui/core";
import langConstant from "_lang";
import pdfLogo from "_assets/fileFormats/PDF.png";
import jpgLogo from "_assets/fileFormats/JPG.png";
import xlsLogo from "_assets/fileFormats/XLS.png";
import docLogo from "_assets/fileFormats/DOC.png";
import pngLogo from "_assets/fileFormats/PNG.png";
import bmpLogo from "_assets/fileFormats/BMP.png";
import csvLogo from "_assets/fileFormats/CSV.png";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import evidenceAction from "../actions";
import RemarkPopUp from "./RemarkPopup";
import ImageViewer from "react-simple-image-viewer";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import moment from "moment";
import { DateRangePicker } from "react-date-range";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Menu from "@mui/material/Menu";
import ContextMenu from "./ContextMenu";
import sessionService from "_services/session.service";
import ApproversList from "./ApproversList";
import RemarksList from "./RemarksList";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ViewListIcon from "@mui/icons-material/ViewList";
import apiService from "_services/api.service";

const useStyles = makeStyles(() => ({
  stickyColl: {
    position: "sticky",
    right: 0,
  },
  inputProfileField: {
    padding: "10px 7px !important",
    color: "#000",
  },
  statusIcon: {
    fontSize: 20,
    margin: "0px 5px 0px 0px",
  },
  heading: {
    textTransform: "uppercase",
  },
  marginBottom: {
    marginBottom: "15px",
  },
  boxShadowAccordian: {
    boxShadow: "2px 2px 7px lightgrey",
  },
  accordianBoxPaddingTB: {
    padding: "0px 15px",
    position: "relative",
    paddingRight: "50px",
  },
  accordianTGray: {
    textTransform: "uppercase",
    color: "grey",
    fontWeight: "bold",
  },
  accordianTDash: {
    display: "inline-block",
    marginLeft: "8px",
    marginRight: "8px",
  },
  accordianTText: {
    color: "#000",
    fontWeight: "bold",
  },
  accordianArrow: {
    width: "8px",
    height: "8px",
    borderLeft: "2px solid #000",
    borderBottom: "2px solid #000",
    display: "inline-block",
    transform: "rotate(-135deg)",
    position: "absolute",
    top: "29px",
    right: "20px",
  },
  accordianDownArrow: {
    width: "8px",
    height: "8px",
    display: "inline-block",
    position: "absolute",
    top: "29px",
    right: "20px",
  },
  currentTab: {
    color: "#007bff",
    fontWeight: "600",
  },
  csRadioWrap: {
    display: "inline-block",
    width: "22px",
    height: "22px",
    border: "1px solid grey",
    borderRadius: "4px",
    position: "absolute",
    top: "calc(50% - 2px)",
    transform: "translateY(-50%)",
    left: "14px",
  },
  csRadioWrapActive: {
    backgroundColor: "#007bff",
  },
  csRadioTick: {
    position: "absolute",
    width: "12px",
    height: "7px",
    display: "inline-block",
    borderLeft: "2px solid #fff",
    borderBottom: "2px solid #fff",
    transform: "translateY(-50%) rotate(-45deg)",
    top: "calc(50% - 2px)",
    left: "4px",
  },
  chipInput: {
    borderRadius: "10px",
    border: "1px solid #cbcbcb",
    padding: "5px 7px 0px 7px",
  },
  borderNone: {
    border: "none",
  },
}));

const EvidenceTable = ({
  rows,
  columns,
  className,
  displayFilters,
  isAccordion,
  type,
  submissionId,
}) => {
  const classes = useStyles();

  const userID = sessionService.getUserId();
  const defaultDocUrl = useSelector(
    (state) => state.authentication.detailedInfo.DEFAULT_DOC_URL
  );

  const docStorageIP = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_IP
  );
  const docStorageURL = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_URL
  );
  const docStorageType = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_TYPE
  );

  const [filteredRows, setFilteredRows] = useState({});
  const [evidenceTabsDetail, setEvidenceTabsDetail] = useState({});
  const [remarkPopUp, setRemarkPopUp] = useState(false);
  const [selectedEvidence, setSelectedEvidence] = useState({});
  const [editTagsData, setEditTagsData] = useState({});
  const [expandedEvidenceList, setExpandedEvidenceList] = useState([]);
  const [previewImage, setPreviewImage] = useState([]);
  const [showApprovers, setShowApprovers] = useState(false);
  const [showRemarks, setShowRemarks] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
    key: "selection",
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const datePickerOpen = Boolean(anchorEl);

  const [anchorElement, setAnchorElement] = useState(null);

  const open = Boolean(anchorElement);
  const handleMenuClick = (event) => {
    event.preventDefault();
    setAnchorElement(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorElement(null);
  };

  const imageFormat = ["png", "jpeg", "jpg"];

  const dispatch = useDispatch();

  const initialFilterObj = {
    TEXT: "",
    EVIDENCE_SHORTNAME: "",
    UPLOADED_BY: "",
    TAGS: "",
  };
  const [filterBy, setFilterBy] = useState(initialFilterObj);

  const rowsByQuestionId = (rowsData) => {
    if (rowsData) {
      let tempEvidenceObj = {};
      let tempEvidenceTabs = {};
      for (let evidence of rowsData) {
        if (Object.keys(tempEvidenceObj).includes(evidence.QUESTION_ID)) {
          tempEvidenceObj[evidence.QUESTION_ID] = [
            ...tempEvidenceObj[evidence.QUESTION_ID],
            evidence,
          ];
        } else {
          tempEvidenceObj[evidence.QUESTION_ID] = [evidence];
          tempEvidenceTabs[evidence.QUESTION_ID] = {
            currentTab: "view_evidence",
          };
        }
      }
      setFilteredRows(tempEvidenceObj);
      setEvidenceTabsDetail(tempEvidenceTabs);
    }
  };

  const resetFilter = () => {
    setFilterBy(initialFilterObj);
    rowsByQuestionId(rows);
    setDateRange({ startDate: null, endDate: null, key: "selection" });
  };

  useEffect(() => {
    let tempRows = [...rows];
    if (dateRange.startDate) {
      tempRows = tempRows.filter((item) =>
        moment(item.UPLOAD_DATE, "YYYY-MM-DD h:mm:ss").isBetween(
          moment(dateRange.startDate),
          moment(dateRange.endDate)
        )
      );
    }
    findFilteredRows(tempRows);
  }, [filterBy, dateRange]);

  useEffect(() => {
    //* Find which Evidence Version is the latest
    let allEvidenceTypes = _.map(rows, "EVIDENCE_SHORTNAME");
    let uniqueEvidences = _.uniq(allEvidenceTypes).sort();
    let latestEvidences = [];

    latestEvidences = uniqueEvidences.map((evidenceType) => {
      let highestVersion = 1;

      rows.forEach((evidence) => {
        if (evidence.EVIDENCE_SHORTNAME == evidenceType) {
          if (evidence.VERSION > highestVersion) {
            highestVersion = evidence.VERSION;
          }
        }
      });

      return { evidenceName: evidenceType, latestVersion: highestVersion };
    });

    let tempRows = [...rows];
    if (dateRange.startDate) {
      tempRows = tempRows.filter((item) =>
        moment(item.UPLOAD_DATE, "YYYY-MM-DD h:mm:ss").isBetween(
          moment(dateRange.startDate),
          moment(dateRange.endDate)
        )
      );
    }

    const handleLinkClick = async (storageFolderURL, fileName) => {
      const authToken = sessionService.getItem("jwtToken");

      try {
        const response = await fetch(
          `${window["REACT_APP_API_URL"]}/evidenceLocker/api/v1/downloadFile?fileLocation=${storageFolderURL}&fileName=${fileName}&storageType=${docStorageType}&preProcessingIP=${docStorageIP}&preProcessingURL=${docStorageURL}`,
          {
            method: "GET",
            headers: {
              "Content-type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        if (response.status === 200) {
          const blob = await response.blob();

          // Create a temporary URL to the blob
          const url = window.URL.createObjectURL(blob);

          // Create a link element and simulate a click to trigger the download
          const link = document.createElement("a");
          link.href = url;
          link.download = fileName; // Set the desired filename
          document.body.appendChild(link);
          link.click();

          // Cleanup: remove the link and revoke the object URL
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        } else {
          dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
        }
      } catch (error) {
        console.error("Error downloading file:", error);
      }
    };

    //* Update rows with new items
    const updatedRows =
      tempRows.length > 0
        ? tempRows.map((item, index) => {
            let data = item;

            data["Version_Label"] = item.VERSION ? (
              <Box display="flex" alignItems="center">
                <Typography style={{ minWidth: "16px", fontSize: "14px" }}>
                  {item.VERSION}
                </Typography>

                {latestEvidences.map((latestEvidence) => {
                  if (
                    item.EVIDENCE_SHORTNAME == latestEvidence.evidenceName &&
                    item.VERSION == latestEvidence.latestVersion
                  ) {
                    return (
                      <Box
                        style={{
                          backgroundColor: "#9CF1AA",
                          padding: "5px 10px",
                          fontSize: "10px",
                          borderRadius: "5px",
                          marginLeft: "6px",
                          minWidth: "63px",
                          fontWeight: "600",
                        }}
                      >
                        {langConstant.LATEST}
                      </Box>
                    );
                  }
                })}
              </Box>
            ) : (
              ""
            );

            data["Tags_Label"] = item.KEYWORDS
              ? item.KEYWORDS
              : langConstant.NO_TAGS;

            let fileLogo = "";
            if (item.FILE_FORMAT == "pdf") {
              fileLogo = pdfLogo;
            } else if (
              item.FILE_FORMAT == "jpg" ||
              item.FILE_FORMAT == "jpeg"
            ) {
              fileLogo = jpgLogo;
            } else if (
              item.FILE_FORMAT == "doc" ||
              item.FILE_FORMAT == "docx"
            ) {
              fileLogo = docLogo;
            } else if (
              item.FILE_FORMAT == "xls" ||
              item.FILE_FORMAT == "xlsx"
            ) {
              fileLogo = xlsLogo;
            } else if (item.FILE_FORMAT == "png") {
              fileLogo = pngLogo;
            } else if (item.FILE_FORMAT == "csv") {
              fileLogo = csvLogo;
            } else if (item.FILE_FORMAT == "bmp") {
              fileLogo = bmpLogo;
            }

            const attachmentItem = (
              <Box display="flex" alignItems="center">
                <img src={fileLogo} style={{ width: 30 }} />
                <Box ml="5px">
                  <Box>
                    {imageFormat.includes(item.FILE_FORMAT) ? (
                      <Typography
                        style={{
                          fontSize: "14px",
                          color: "#007bff",
                        }}
                        onClick={() =>
                          setPreviewImage([
                            `${defaultDocUrl}${item.STORAGE_FOLDER_URL}${item.FILE_NAME}`,
                          ])
                        }
                      >
                        {item.FILE_NAME}
                      </Typography>
                    ) : (
                      <Typography
                        style={{
                          fontSize: "14px",
                          color: "#007bff",
                        }}
                      >
                        <Link
                          style={{
                            textDecoration: "none",
                          }}
                          rel="noreferrer"
                          onClick={() =>
                            handleLinkClick(
                              item.STORAGE_FOLDER_URL,
                              item.FILE_NAME
                            )
                          }
                        >
                          {item.FILE_NAME}
                        </Link>
                      </Typography>
                    )}
                  </Box>
                  <Box display="flex">
                    <span
                      style={{
                        textDecoration: "none",
                        color: "grey",
                      }}
                    >
                      {item.FILE_FORMAT ? item.FILE_FORMAT.toUpperCase() : ""}{" "}
                    </span>
                    <span
                      style={{
                        display: "inline-block",
                        marginLeft: "10px",
                        marginRight: "10px",
                      }}
                    >
                      |
                    </span>
                    <Box style={{ cursor: "pointer" }}>
                      <Link
                        style={{
                          textDecoration: "none",
                          color: "grey",
                        }}
                        rel="noreferrer"
                        onClick={() =>
                          handleLinkClick(
                            item.STORAGE_FOLDER_URL,
                            item.FILE_NAME
                          )
                        }
                      >
                        {langConstant.DOWNLOAD}
                      </Link>
                    </Box>
                  </Box>
                </Box>
              </Box>
            );

            data["Attachment_Label"] = item.FILE_NAME
              ? attachmentItem
              : langConstant.NO_ATTACHMENTS;

            const evidenceWordCloud = (
              <Box display="flex" alignItems="center">
                <img src={pngLogo} style={{ width: 30 }} />
                <Box ml="5px">
                  <Box>
                    <Typography
                      style={{
                        fontSize: "14px",
                        color: "#007bff",
                      }}
                      // onClick={()=>setPreviewImage(['https://d2tase8fjfnxes.cloudfront.net/'+item.STORAGE_FOLDER_URL+'/'+item.WORDCLOUD_FILE_NAME])}
                      onClick={() => {
                        setPreviewImage([
                          "data:image/png;base64," +
                            window.atob(item.EVIDENCE_WORDCLOUD),
                        ]);
                      }}
                    >
                      {item.WORDCLOUD_FILE_NAME}
                    </Typography>
                  </Box>
                  <Box display="flex">
                    <span
                      style={{
                        textDecoration: "none",
                        color: "grey",
                      }}
                    >
                      PNG
                    </span>
                    <span
                      style={{
                        display: "inline-block",
                        marginLeft: "10px",
                        marginRight: "10px",
                      }}
                    >
                      |
                    </span>
                    <Box style={{ cursor: "pointer" }}>
                      <Link
                        style={{
                          textDecoration: "none",
                          color: "grey",
                        }}
                        rel="noreferrer"
                        onClick={() =>
                          handleLinkClick(
                            item.STORAGE_FOLDER_URL,
                            item.FILE_NAME
                          )
                        }
                      >
                        {langConstant.DOWNLOAD}
                      </Link>
                    </Box>
                  </Box>
                </Box>
              </Box>
            );

            data["Evidence_WordCloud"] = item.EVIDENCE_WORDCLOUD
              ? evidenceWordCloud
              : null;

            data["Uploaded_By"] =
              item.FIRST_NAME && item.LAST_NAME ? (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  {item.FIRST_NAME} {item.LAST_NAME}
                </Box>
              ) : (
                ""
              );

            return data;
          })
        : [];

    rowsByQuestionId(updatedRows);
  }, [rows]);

  const updateEvidenceTags = () => {
    dispatch(
      evidenceAction.updateEvidenceLog(
        editTagsData.QUESTION_ID,
        editTagsData.EVIDENCE_LOG_ID,
        editTagsData.KEYWORDS,
        editTagsData.COMMENTS,
        setEditTagsData,
        type
      )
    );
  };

  const createFilterDropdown = (fieldName, label) => {
    let options = null;
    if (fieldName == "TAGS") {
      const tagRows = rows.map((item) => {
        const keywords = item.KEYWORDS.split(";");
        let newItem = { TAGS: [] };
        newItem.TAGS = keywords;
        return newItem;
      });

      let allOptions = _.flattenDeep(_.map(tagRows, fieldName));
      const unsortedOptions = _.reject(_.uniq(allOptions), _.isNull);
      options = unsortedOptions.sort(function (a, b) {
        return a.toLowerCase().localeCompare(b.toLowerCase());
      });
    } else if (fieldName == "UPLOADED_BY") {
      const nameRows = rows.map((item) => {
        const fullName = item.FIRST_NAME + " " + item.LAST_NAME;
        let newItem = { UPLOADED_BY: [] };
        newItem.UPLOADED_BY = fullName;
        return newItem;
      });

      let allOptions = _.flattenDeep(_.map(nameRows, fieldName));
      options = _.reject(_.uniq(allOptions).sort(), _.isNull);
    } else {
      options = _.reject(_.uniq(_.map(rows, fieldName)).sort(), _.isNull);
    }

    if (options.length > 0 && options[0]) {
      options = options.map((item) => {
        return { value: item, name: item };
      });
    }
    if (options.length > 0) {
      return (
        <FilterDropdown
          options={options}
          label={label}
          fieldName={fieldName}
          value={filterBy[fieldName]}
          onChange={onFilterChange}
          icon={true}
        />
      );
    }
  };

  const onFilterChange = (fieldName, e) => {
    const filterValue = e.target.value;

    const filterObj = { ...filterBy, [fieldName]: filterValue };
    setFilterBy(filterObj);
  };

  const findFilteredRows = (rowsData) => {
    let filtered_array = [...rowsData];
    if (filterBy["TEXT"]) {
      filtered_array = filtered_array.filter((item) => {
        return (
          item.QUESTION_ID.toLowerCase().includes(
            filterBy["TEXT"].toLowerCase()
          ) ||
          item.QUESTION_TEXT.toLowerCase().includes(
            filterBy["TEXT"].toLowerCase()
          )
        );
      });
    }
    if (filterBy["EVIDENCE_SHORTNAME"]) {
      filtered_array = filtered_array.filter(
        (item) => filterBy["EVIDENCE_SHORTNAME"] == item["EVIDENCE_SHORTNAME"]
      );
    }
    if (filterBy["UPLOADED_BY"]) {
      filtered_array = filtered_array.filter(
        (item) =>
          filterBy["UPLOADED_BY"] ==
          item["FIRST_NAME"] + " " + item["LAST_NAME"]
      );
    }
    if (filterBy["TAGS"]) {
      filtered_array = filtered_array.filter((item) =>
        item.KEYWORDS.split(";").includes(filterBy["TAGS"])
      );
    }
    rowsByQuestionId(filtered_array);
  };

  const handleTabChange = (tabName, evidenceName) => {
    setEvidenceTabsDetail((prevState) => ({
      ...prevState,
      [evidenceName]: { currentTab: tabName },
    }));
  };

  const updateEvidenceStatus = async (status) => {
    dispatch(
      await evidenceAction.updateEvidenceStatus(
        selectedEvidence["EVIDENCE_LOG_ID"],
        status,
        userID
      )
    );
  };

  const handleTagsChange = (tagsData) => {
    let tagsString = "";
    if (tagsData.length === 1) {
      tagsString = tagsData[0];
    } else if (tagsData.length > 1) {
      tagsData.map((item) => (tagsString = tagsString + ";" + item));
      tagsString = tagsString.substring(1);
    }
    setEditTagsData((prevState) => ({ ...prevState, KEYWORDS: tagsString }));
  };

  const handleCommnetsChange = (commentString) => {
    setEditTagsData((prevState) => ({ ...prevState, COMMENTS: commentString }));
  };

  const handleRemarkPopUp = () => {
    if (Object.keys(selectedEvidence).length) {
      setRemarkPopUp(true);
    } else {
      dispatch(alertActions.error(langConstant.PLEASE_SELECT_EVIDENCE));
    }
  };

  const updateExpandList = (question) => {
    if (expandedEvidenceList.includes(question)) {
      let tempList = [...expandedEvidenceList];
      tempList = tempList.filter((item) => item !== question);
      setExpandedEvidenceList(tempList);
    } else {
      setExpandedEvidenceList((prevState) => [...prevState, question]);
    }
  };

  const expandCollapseAll = (type) => {
    if (type === "expand") {
      setExpandedEvidenceList(Object.keys(filteredRows));
    } else {
      setExpandedEvidenceList([]);
    }
  };

  const closeImageViewer = () => {
    setPreviewImage([]);
  };

  const datePickerClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const datePickerClose = () => {
    setAnchorEl(null);
  };

  const dateRangeSelector = (ranges) => {
    if (moment(ranges.selection.endDate).isAfter(moment())) {
      setDateRange((prevState) => ({
        ...prevState,
        startDate: moment(ranges.selection.startDate).startOf("day")._d,
        endDate: moment().endOf("day")._d,
      }));
    } else {
      setDateRange((prevState) => ({
        ...prevState,
        startDate: moment(ranges.selection.startDate).startOf("day")._d,
        endDate: moment(ranges.selection.endDate).endOf("day")._d,
      }));
    }
  };

  return (
    <Box>
      <Box mb={2} mt={3}>
        {displayFilters ? (
          <>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12} md={10}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={3}>
                    <TextField
                      size="small"
                      variant="outlined"
                      fullWidth
                      id="searchText"
                      label=""
                      name="searchText"
                      placeholder={langConstant.FILTER_TABLE}
                      value={filterBy["TEXT"]}
                      onChange={(e) => onFilterChange("TEXT", e)}
                      InputProps={{
                        autoComplete: "off",
                        startAdornment: (
                          <InputAdornment position="start">
                            {" "}
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    {createFilterDropdown(
                      "EVIDENCE_SHORTNAME",
                      langConstant.EVIDENCE_TYPE
                    )}
                  </Grid>
                  <Grid item xs={12} md={3}>
                    {createFilterDropdown("TAGS", langConstant.TAGS)}
                  </Grid>
                  <Grid item xs={12} md={3}>
                    {createFilterDropdown(
                      "UPLOADED_BY",
                      langConstant.UPLOADED_BY
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={2} style={{ textAlign: "center" }}>
                <TextField
                  variant="outlined"
                  onClick={datePickerClick}
                  placeholder={langConstant.SELECT_DATE_RANGE}
                  disabled={true}
                  style={{ width: "100%" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CalendarMonthIcon />
                      </InputAdornment>
                    ),
                    classes: { input: classes.inputProfileField },
                  }}
                  aria-controls={datePickerOpen ? "long-menu" : undefined}
                  aria-expanded={datePickerOpen ? "true" : undefined}
                  aria-haspopup="true"
                  value={
                    dateRange.startDate
                      ? moment(dateRange.startDate).format("ll") +
                        " - " +
                        moment(dateRange.endDate).format("ll")
                      : ""
                  }
                />
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    "aria-labelledby": "long-button",
                  }}
                  anchorEl={anchorEl}
                  open={datePickerOpen}
                  onClose={datePickerClose}
                >
                  <DateRangePicker
                    ranges={[dateRange]}
                    onChange={dateRangeSelector}
                    maxDate={moment()._d}
                    showPreview={true}
                  />
                </Menu>
              </Grid>
            </Grid>
            <Grid style={{ textAlign: "right" }}>
              {(filterBy["TEXT"] ||
                filterBy["EVIDENCE_SHORTNAME"] ||
                filterBy["TAGS"] ||
                filterBy["UPLOADED_BY"] ||
                dateRange["startDate"]) && (
                <Button
                  variant="text"
                  color="primary"
                  onClick={resetFilter}
                  style={{ fontWeight: "bold", marginRight: "20px" }}
                >
                  {langConstant.RESET_ALL}
                </Button>
              )}
            </Grid>
          </>
        ) : (
          ""
        )}
      </Box>
      <Grid container style={{ padding: "0px 0px 10px" }}>
        <Grid item md={6} xs={6}>
          {isAccordion && Object.keys(filteredRows).length > 0 && (
            <Grid>
              {expandedEvidenceList.length > 1 ? (
                <Button
                  color="primary"
                  onClick={() => expandCollapseAll("collapse")}
                  style={{ fontWeight: "bold" }}
                >
                  <UnfoldLessIcon style={{ marginRight: "5px" }} />{" "}
                  {langConstant.COLLAPSE_ALL}
                </Button>
              ) : (
                <Button
                  color="primary"
                  onClick={() => expandCollapseAll("expand")}
                  style={{ fontWeight: "bold" }}
                >
                  <UnfoldMoreIcon style={{ marginRight: "5px" }} />{" "}
                  {langConstant.EXPAND_ALL}
                </Button>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container>
        {Object.keys(filteredRows).map((item, index) => {
          return (
            <Grid key={index} xs={12} className={classes.marginBottom}>
              <Accordion
                className={classes.boxShadowAccordian}
                expanded={expandedEvidenceList.includes(item)}
              >
                {isAccordion && (
                  <AccordionSummary
                    className={classes.accordianBoxPaddingTB}
                    style={{
                      position: "relative",
                      marginBottom: "5px",
                      paddingTop: "7px",
                    }}
                    onClick={() => updateExpandList(item)}
                  >
                    <Grid>
                      <span className={classes.accordianTGray}>{item}</span>
                      <span className={classes.accordianTDash}> - </span>
                      <span className={classes.accordianTText}>
                        {filteredRows[item][0].QUESTION_TEXT}
                      </span>
                      <span
                        className={classes.accordianTText}
                        style={{
                          position: "absolute",
                          top: "4px",
                          right: "4px",
                          padding: "4px 10px",
                          borderRadius: "4px",
                          backgroundColor: "#6bfe8d",
                          fontSize: "10px",
                          lineHeight: "10px",
                        }}
                      >
                        {filteredRows[item][0].QUESTION_STATUS}
                      </span>
                      {expandedEvidenceList.includes(item) ? (
                        <KeyboardArrowDownIcon
                          className={classes.accordianDownArrow}
                        />
                      ) : (
                        <span className={classes.accordianArrow}></span>
                      )}
                    </Grid>
                  </AccordionSummary>
                )}
                <AccordionDetails style={{ padding: "0px" }}>
                  <Grid style={{ width: "100%" }}>
                    <TabContext value={evidenceTabsDetail[item]["currentTab"]}>
                      <Box
                        style={{
                          borderBottom: "1px solid #e5e5e5",
                          marginBottom: "15px",
                        }}
                      >
                        <TabList
                          style={{ paddingLeft: "25px", paddingRight: "25px" }}
                          TabIndicatorProps={{
                            style: {
                              backgroundColor: "#007bff",
                              height: "3px",
                              borderRadius: "20px",
                            },
                          }}
                        >
                          <Tab
                            className={
                              evidenceTabsDetail[item]["currentTab"] ===
                              "view_evidence"
                                ? classes.currentTab
                                : ""
                            }
                            onClick={() =>
                              handleTabChange("view_evidence", item)
                            }
                            label="VIEW EVIDENCE"
                            value="view_evidence"
                          />
                          <Tab
                            className={
                              evidenceTabsDetail[item]["currentTab"] === "Tags"
                                ? classes.currentTab
                                : ""
                            }
                            onClick={() => handleTabChange("tags", item)}
                            label="TAGS"
                            value="tags"
                          />
                        </TabList>
                      </Box>
                      <TabPanel
                        value="view_evidence"
                        style={{ padding: "0px" }}
                      >
                        <Grid>
                          <TableContainer
                            component={Paper}
                            style={{
                              border: "none",
                              boxShadow: "unset",
                              borderRadius: "0px",
                            }}
                          >
                            <Table
                              className={className}
                              aria-label="Regulation List"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    style={{
                                      // backgroundColor: "#f8f8f8",
                                      border: "none",
                                      textTransform: "capitalize",
                                      fontSize: "12px",
                                      lineHeight: "18px",
                                    }}
                                    component="th"
                                    scope="row"
                                  ></TableCell>
                                  {columns["view_evidence"].map(
                                    (item, index) => (
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        key={"column_" + index + item.field}
                                        className={
                                          (item.sticky && classes.stickyColl) +
                                          " " +
                                          classes.heading
                                        }
                                        style={{
                                          minWidth: item?.minWidth,
                                          maxWidth: item?.maxWidth,
                                          border: "none",
                                          textTransform: "capitalize",
                                          fontSize: "12px",
                                          lineHeight: "18px",
                                        }}
                                      >
                                        {item.headerName}
                                      </TableCell>
                                    )
                                  )}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {filteredRows[item].map((row, index) => (
                                  <TableRow
                                    key={
                                      index +
                                      row[columns["view_evidence"][0].field]
                                    }
                                  >
                                    <TableCell
                                      component="td"
                                      scope="row"
                                      style={{
                                        wordBreak: "break-word",
                                        wordWrap: "break-word",
                                        position: "relative",
                                      }}
                                    >
                                      <input
                                        style={{
                                          width: "20px",
                                          height: "20px",
                                          border: "1px solid #dddddd",
                                          position: "relative",
                                          zIndex: "4",
                                          opacity: "0.0000001",
                                        }}
                                        type="radio"
                                        name="evidencelogid"
                                        value={row["EVIDENCE_LOG_ID"]}
                                        onClick={() => setSelectedEvidence(row)}
                                      />
                                      <span
                                        className={
                                          selectedEvidence.QUESTION_ID ===
                                            row.QUESTION_ID &&
                                          selectedEvidence.EVIDENCE_LOG_ID ===
                                            row.EVIDENCE_LOG_ID
                                            ? classes.csRadioWrap +
                                              " " +
                                              classes.csRadioWrapActive
                                            : classes.csRadioWrap
                                        }
                                      >
                                        <span
                                          className={
                                            selectedEvidence.QUESTION_ID ===
                                              row.QUESTION_ID &&
                                            selectedEvidence.EVIDENCE_LOG_ID ===
                                              row.EVIDENCE_LOG_ID
                                              ? classes.csRadioTick +
                                                " " +
                                                classes.csRadioWrapActive
                                              : classes.csRadioTick
                                          }
                                        ></span>
                                      </span>
                                    </TableCell>
                                    {columns["view_evidence"].map(
                                      (column, index) => {
                                        //const Comp = column.component && column.component
                                        let value = row[column.field];
                                        if (
                                          column.field === "METADATA" &&
                                          value &&
                                          value !== "MQ=="
                                        ) {
                                          let tempData = null;
                                          try {
                                            tempData = JSON.parse(
                                              window.atob(value)
                                            );
                                          } catch (error) {
                                            console.log(error);
                                          }
                                          if (tempData?.score) {
                                            value = tempData.score;
                                          }
                                        } else if (value === "MQ==") {
                                          value = [];
                                        }
                                        return (
                                          <TableCell
                                            component="td"
                                            scope="row"
                                            key={
                                              "column_" +
                                              index +
                                              row[
                                                columns["view_evidence"][0]
                                                  .field
                                              ]
                                            }
                                            className={
                                              column.sticky &&
                                              classes.stickyColl
                                            }
                                            style={{
                                              wordBreak: "break-word",
                                              wordWrap: "break-word",
                                            }}
                                          >
                                            {column.format
                                              ? column.format(value)
                                              : value}
                                          </TableCell>
                                        );
                                      }
                                    )}
                                  </TableRow>
                                ))}

                                {filteredRows.length == 0 && (
                                  <TableRow style={{ height: 53 }}>
                                    <TableCell
                                      style={{ textAlign: "center" }}
                                      colSpan={columns.length}
                                    >
                                      {langConstant.NO_RECORD_FOUND}
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                        <Grid
                          container
                          style={{ padding: "17px 40px", alignItems: "center" }}
                        >
                          <Grid
                            style={{
                              cursor: "pointer",
                              fontSize: "0.875rem",
                              textTransform: "uppercase",
                              color: "#007bff",
                              fontWeight: "600",
                              letterSpacing: "0.02857em",
                              marginRight: "40px",
                              alignItems: "center",
                            }}
                            onClick={(event) => {
                              if (Object.keys(selectedEvidence).length > 0) {
                                event.preventDefault();
                                handleMenuClick(event);
                              } else {
                                dispatch(
                                  alertActions.error(
                                    langConstant.PLEASE_SELECT_EVIDENCE
                                  )
                                );
                              }
                            }}
                          >
                            <DoneAllIcon
                              style={{ position: "relative", top: "6px" }}
                            />{" "}
                            {langConstant.APPROVE}
                          </Grid>
                          {/* <Grid style={{cursor:"pointer",fontSize:"0.875rem",textTransform:"uppercase",color:"#007bff",fontWeight:"600",letterSpacing:"0.02857em",marginRight:"40px",}}>
                            <RestorePageIcon style={{position:"relative",top:"6px",}} /> ASK TO REUPLOAD
                          </Grid> */}
                          <Grid
                            style={{
                              cursor: "pointer",
                              fontSize: "0.875rem",
                              textTransform: "uppercase",
                              color: "#007bff",
                              fontWeight: "600",
                              letterSpacing: "0.02857em",
                              marginRight: "40px",
                            }}
                            onClick={() => handleRemarkPopUp()}
                          >
                            <CommentBankIcon
                              style={{ position: "relative", top: "9px" }}
                            />{" "}
                            {langConstant.ADD_REMARK}
                          </Grid>
                          <Grid
                            style={{
                              cursor: "pointer",
                              fontSize: "0.875rem",
                              textTransform: "uppercase",
                              color: "#007bff",
                              fontWeight: "600",
                              letterSpacing: "0.02857em",
                              marginRight: "40px",
                            }}
                            onClick={() =>
                              Object.keys(selectedEvidence).length
                                ? setShowApprovers(true)
                                : dispatch(
                                    alertActions.error(
                                      langConstant.PLEASE_SELECT_EVIDENCE
                                    )
                                  )
                            }
                          >
                            <VisibilityIcon
                              style={{ position: "relative", top: "9px" }}
                            />{" "}
                            {langConstant.SHOW_APPROVERS}
                          </Grid>
                          <Grid
                            style={{
                              cursor: "pointer",
                              fontSize: "0.875rem",
                              textTransform: "uppercase",
                              color: "#007bff",
                              fontWeight: "600",
                              letterSpacing: "0.02857em",
                              marginRight: "40px",
                            }}
                            onClick={() =>
                              Object.keys(selectedEvidence).length
                                ? setShowRemarks(true)
                                : dispatch(
                                    alertActions.error(
                                      langConstant.PLEASE_SELECT_EVIDENCE
                                    )
                                  )
                            }
                          >
                            <ViewListIcon
                              style={{ position: "relative", top: "9px" }}
                            />{" "}
                            {langConstant.SHOW_REMARKS}
                          </Grid>
                        </Grid>
                      </TabPanel>
                      <TabPanel value="tags" style={{ padding: "0px" }}>
                        <Grid>
                          <TableContainer
                            component={Paper}
                            style={{
                              border: "none",
                              boxShadow: "unset",
                              borderRadius: "0px",
                            }}
                          >
                            <Table
                              className={className}
                              aria-label="Regulation List"
                            >
                              <TableHead>
                                <TableRow>
                                  {columns["tags"].map((item, index) => (
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      key={"column_" + index + item.field}
                                      className={
                                        (item.sticky && classes.stickyColl) +
                                        " " +
                                        classes.heading
                                      }
                                      style={{
                                        minWidth: item?.minWidth,
                                        maxWidth: item?.maxWidth,
                                        border: "none",
                                        textTransform: "capitalize",
                                        fontSize: "13px",
                                      }}
                                    >
                                      {item.headerName}
                                    </TableCell>
                                  ))}
                                  <TableCell
                                    style={{
                                      border: "none",
                                      textTransform: "capitalize",
                                      fontSize: "13px",
                                    }}
                                    component="th"
                                    scope="row"
                                  ></TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {filteredRows[item].map((row, index) => (
                                  <TableRow
                                    key={index + row[columns["tags"][0].field]}
                                  >
                                    {columns["tags"].map((column, index) => {
                                      let value = row[column.field];

                                      return (
                                        <TableCell
                                          component="td"
                                          scope="row"
                                          key={
                                            "column_" +
                                            index +
                                            row[columns["tags"][0].field]
                                          }
                                          className={
                                            column.sticky && classes.stickyColl
                                          }
                                          style={{
                                            wordBreak: "break-word",
                                            wordWrap: "break-word",
                                          }}
                                        >
                                          {column.field === "Tags_Label" ||
                                          column.field === "COMMENTS_INPUT" ? (
                                            <>
                                              {column.field ===
                                                "Tags_Label" && (
                                                <ChipInput
                                                  className={
                                                    row.EVIDENCE_LOG_ID ===
                                                    editTagsData.EVIDENCE_LOG_ID
                                                      ? classes.chipInput
                                                      : ""
                                                  }
                                                  disabled={
                                                    row.EVIDENCE_LOG_ID ===
                                                      editTagsData.EVIDENCE_LOG_ID &&
                                                    row.QUESTION_ID ===
                                                      editTagsData.QUESTION_ID
                                                      ? false
                                                      : true
                                                  }
                                                  disableUnderline={true}
                                                  defaultValue={
                                                    row.KEYWORDS
                                                      ? row.KEYWORDS.split(";")
                                                      : []
                                                  }
                                                  onChange={(chips) =>
                                                    handleTagsChange(chips)
                                                  }
                                                />
                                              )}
                                              {column.field ===
                                                "COMMENTS_INPUT" && (
                                                <TextField
                                                  disabled={
                                                    row.EVIDENCE_LOG_ID ===
                                                      editTagsData.EVIDENCE_LOG_ID &&
                                                    row.QUESTION_ID ===
                                                      editTagsData.QUESTION_ID
                                                      ? false
                                                      : true
                                                  }
                                                  onChange={(e) =>
                                                    handleCommnetsChange(
                                                      e.target.value
                                                    )
                                                  }
                                                  defaultValue={
                                                    row.COMMENTS
                                                      ? row.COMMENTS
                                                      : ""
                                                  }
                                                  variant="outlined"
                                                  InputProps={{
                                                    classes: {
                                                      input:
                                                        classes.inputProfileField,
                                                      notchedOutline:
                                                        row.EVIDENCE_LOG_ID ===
                                                          editTagsData.EVIDENCE_LOG_ID &&
                                                        row.QUESTION_ID ===
                                                          editTagsData.QUESTION_ID
                                                          ? ""
                                                          : classes.borderNone,
                                                    },
                                                  }}
                                                />
                                              )}
                                            </>
                                          ) : column.format ? (
                                            column.format(value)
                                          ) : (
                                            value
                                          )}
                                        </TableCell>
                                      );
                                    })}
                                    <TableCell
                                      component="td"
                                      scope="row"
                                      style={{
                                        wordBreak: "break-word",
                                        wordWrap: "break-word",
                                      }}
                                    >
                                      {Object.keys(editTagsData) &&
                                      row["EVIDENCE_LOG_ID"] ===
                                        editTagsData["EVIDENCE_LOG_ID"] &&
                                      row["QUESTION_ID"] ===
                                        editTagsData["QUESTION_ID"] ? (
                                        <Button
                                          style={{
                                            color: "#007bff",
                                            fontSize: "13px",
                                            height: "auto",
                                            border: "1px solid #007bff",
                                            borderRadius: "5px",
                                            padding: "1px 10px",
                                            minWidth: "80px",
                                          }}
                                          variant="outlined"
                                          onClick={() => updateEvidenceTags()}
                                        >
                                          <SaveIcon
                                            style={{
                                              marginRight: "3px",
                                              width: "16px",
                                              position: "relative",
                                              top: "-1px",
                                            }}
                                          />{" "}
                                          Save
                                        </Button>
                                      ) : (
                                        <Button
                                          style={{
                                            color: "#007bff",
                                            fontSize: "13px",
                                            height: "auto",
                                            border: "1px solid #007bff",
                                            borderRadius: "5px",
                                            padding: "1px 10px",
                                            minWidth: "80px",
                                          }}
                                          variant="outlined"
                                          onClick={() => setEditTagsData(row)}
                                        >
                                          <CreateOutlinedIcon
                                            style={{
                                              marginRight: "3px",
                                              width: "16px",
                                              position: "relative",
                                              top: "-1px",
                                            }}
                                          />{" "}
                                          Edit
                                        </Button>
                                      )}
                                    </TableCell>
                                  </TableRow>
                                ))}

                                {filteredRows.length == 0 && (
                                  <TableRow style={{ height: 53 }}>
                                    <TableCell
                                      style={{ textAlign: "center" }}
                                      colSpan={columns.length}
                                    >
                                      {langConstant.NO_RECORD_FOUND}
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                      </TabPanel>
                    </TabContext>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          );
        })}
      </Grid>
      {Object.keys(filteredRows).length === 0 && (
        <Grid container style={{ padding: "100px 0px" }}>
          <Typography
            style={{
              textAlign: "center",
              width: "100%",
              color: "#999696",
              fontWeight: "bold",
            }}
          >
            {langConstant.NO_RECORD_FOUND}
          </Typography>
        </Grid>
      )}
      {remarkPopUp && (
        <Grid>
          <RemarkPopUp
            show={remarkPopUp}
            setShow={setRemarkPopUp}
            selectedEvidence={selectedEvidence}
          />
        </Grid>
      )}
      {previewImage.length > 0 && (
        <Grid>
          {console.log(previewImage, "==========")}
          <ImageViewer
            src={previewImage}
            currentIndex={0}
            onClose={closeImageViewer}
            disableScroll={false}
            backgroundStyle={{
              backgroundColor: "rgba(0,0,0,0.7)",
            }}
            closeOnClickOutside={true}
          />
        </Grid>
      )}
      {open && (
        <ContextMenu
          open={open}
          anchorEl={anchorElement}
          handleMenuClose={handleMenuClose}
          updateEvidenceStatus={updateEvidenceStatus}
        />
      )}
      {showApprovers && (
        <ApproversList
          show={showApprovers}
          setShow={setShowApprovers}
          evidenceLogId={selectedEvidence["EVIDENCE_LOG_ID"]}
        />
      )}
      {showRemarks && (
        <RemarksList
          show={showRemarks}
          setShow={setShowRemarks}
          submissionId={selectedEvidence["SUBMISSION_ID"]}
          evidenceLogId={selectedEvidence["EVIDENCE_LOG_ID"]}
          questionId={selectedEvidence["QUESTION_ID"]}
        />
      )}
    </Box>
  );
};

EvidenceTable.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.object.isRequired,
  className: PropTypes.string,
  displayFilters: PropTypes.bool,
  isAccordion: PropTypes.bool,
  type: PropTypes.string,
  submissionId: PropTypes.number,
};

export default EvidenceTable;
