import React, { useEffect, useState } from "react";
import {
  Button,
  Typography,
  Box,
  Grid,
  TextField,
  makeStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import { UseModalPropType } from "_hooks";
import SubscriptionServices from "./../../../services";
import CloseIcon from "@mui/icons-material/Close";
import langConstant from "_lang";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import sessionService from "_services/session.service";
import { useDispatch, useSelector } from "react-redux";
import { alertActions, loadingActions } from "_actions";
import Select from "react-select";

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="left" ref={ref} {...props} />;
// });

const useStyles = makeStyles(() => ({
  width100: {
    width: "100%",
  },
  noOutline: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },

  inputProfileField: {
    padding: "5px",
    color: "#000000",
  },

  AddressGrid: {
    marginBottom: "20px",
  },
}));

const AddNewSubscriptionPopup = ({ modal }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const customerId = useSelector(
    (state) => state?.authentication?.detailedInfo?.CUSTOMER_ID
  );
  const userID = sessionService.getUserId();
  // const username = useSelector(
  //   (state) => state.authentication.detailedInfo?.USER_NAME
  // );
  const [subscriptionTypeOptions, setSubscriptionTypeOptions] = useState([
    {
      value: "Package",
      label: "Package",
    },
    {
      value: "Regulation",
      label: "Regulation",
    },
    {
      value: "Regulation Scanning",
      label: "Regulation Scanning",
    },
  ]);
  const [subscriptionRegulationPlan, setSubscriptionRegulationPlan] = useState(
    []
  );
  const [subscriptionPackagePlan, setSubscriptionPackagePlan] = useState([]);
  const [packageSelected, setPackageSelected] = useState(false);
  const [regulationSelected, setRegulationSelected] = useState(false);
  const [subscriptionOptions, setSubscriptionOptions] = useState([]);
  const [durationOptions, setDurationOptions] = useState([
    {
      value: 1,
      label: "Monthly",
    },
    {
      value: 12,
      label: "Annual",
    },
  ]);
  const [customerSubscriptionData, setCustomerSubscriptionData] = useState([]);
  const [amount, setAmount] = useState("");
  const [uniqueSubscriptionData, setUniqueSubscriptionData] = useState([]);
  const initialData = {
    SUBSCRIPTION_TYPE: "",
    SUBSCRIPTION: null,
    COST_OF_SUBSCRIPTION: "",
    PLAN_ID: "",
    COUPON_CODE: "",
    PAY_STATUS: "",
    SUBSCRIPTION_STATUS: "",
    BILLING: 1,
    USERS: 1,
  };

  const fetchPriceForSubscription = async (planID, users, billingFrequency) => {
    dispatch(loadingActions.start());
    const subscriptionResponse =
      await SubscriptionServices.getSubscriptionPrices(
        planID,
        users,
        billingFrequency
      );
    dispatch(loadingActions.end());

    if (subscriptionResponse && subscriptionResponse["#result-set-1"]) {
      setAmount(
        `£${subscriptionResponse["#result-set-1"][0]["SUBSCRIPTION_AMOUNT"]}`
      );
    } else {
      dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
    }
  };

  const fetchCustomerSubscriptionPlans = async () => {
    dispatch(loadingActions.start());
    const subscriptionResponse = await SubscriptionServices.getPlanDetails();
    dispatch(loadingActions.end());

    if (subscriptionResponse && subscriptionResponse["#result-set-1"]) {
      let uniqueType = [];
      let uniqueSubscriptionType = {};
      const regulationPlan = [];
      const packagePlan = [];

      subscriptionResponse["#result-set-1"].forEach((item) => {
        const planType = item.PLAN_TYPE.toString().trim();
        const subscriptionName = item.PLAN_NAME.toString().trim();

        if (planType == "Package") {
          const tempPlan = {
            value: item.PLAN_ID,
            label: item.PLAN_NAME,
          };
          packagePlan.push(tempPlan);
        } else {
          const tempPlan = {
            value: item.PLAN_ID,
            label: item.PLAN_NAME,
          };
          regulationPlan.push(tempPlan);
        }

        if (!uniqueSubscriptionType[planType]) {
          uniqueSubscriptionType[planType] = [];
          uniqueType.push({
            value: planType,
            label: planType,
          });
        }
        uniqueSubscriptionType[planType].push(item);
      });
      setSubscriptionTypeOptions(uniqueType);
      setUniqueSubscriptionData(uniqueSubscriptionType);
      setSubscriptionPackagePlan(packagePlan);
      setSubscriptionRegulationPlan(regulationPlan);
    } else {
      dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
    }
  };

  useEffect(() => {
    fetchCustomerSubscriptionPlans();
  }, []);

  const validationSchema = Yup.object().shape({
    SUBSCRIPTION_TYPE: Yup.string().required("Subscription type is required"),
    SUBSCRIPTION: Yup.string().required("Subscription is required"),
    BILLING: Yup.string().required("Billing is Required"),
  });

  const saveNewSubscription = (values) => {
    // SubscriptionServices.putCustomerSubscriptions(customerId, userID, values);
    // modal?.closeModal();
  };

  const handleClose = () => {
    modal?.closeModal();
  };

  return (
    <Dialog
      open={modal?.isOpen}
      maxWidth="md"
      fullWidth
      keepMounted
      onClose={modal?.closeModal}
    >
      <div className={classes.padding}>
        <DialogTitle className={classes.title}>
          <Grid container style={{ justifyContent: "end" }} justify="flex-end">
            <Typography style={{ flex: 1 }} variant="h6" align="center">
              {langConstant.NEW_SUBSCRIPTION}
            </Typography>
            <IconButton
              style={{ position: "absolute", right: 4 }}
              aria-label="close"
              onClick={modal?.closeModal}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>

        <DialogContent>
          <Formik
            enableReinitialize={true}
            initialValues={initialData}
            validationSchema={validationSchema}
            onSubmit={saveNewSubscription}
          >
            {({ values, errors, handleChange, setFieldValue, resetForm }) => (
              <Form>
                <Grid
                  container
                  className={classes.AddressGrid}
                  style={{ alignItems: "center", marginTop: "10px" }}
                >
                  <Grid item xs={8} style={{ display: "flex" }}>
                    <Grid item xs={3}>
                      <Typography>{langConstant.SUBSCRIPTION_TYPE}</Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Field name="SUBSCRIPTION_TYPE">
                        {({ field: { value } }) => (
                          <Select
                            className={classes.select100Width}
                            value={subscriptionTypeOptions.find(
                              (type) => type.value == value
                            )}
                            options={subscriptionTypeOptions}
                            onChange={(type) => {
                              if (type.value == "Package") {
                                setPackageSelected(true);
                                setRegulationSelected(false);
                                setSubscriptionOptions(subscriptionPackagePlan);
                              } else if (
                                type.value == "Regulation" ||
                                type.value == "Regulation Scanning"
                              ) {
                                setPackageSelected(false);
                                setRegulationSelected(true);
                                setSubscriptionOptions(
                                  subscriptionRegulationPlan
                                );
                              } else {
                                setPackageSelected(false);
                                setRegulationSelected(false);
                              }

                              setFieldValue("SUBSCRIPTION_TYPE", type.value);
                              setFieldValue("SUBSCRIPTION", null);
                            }}
                            placeholder={langConstant.SELECT_SUBSCRIPTION_TYPE}
                            menuPortalTarget={document.body}
                          />
                        )}
                      </Field>

                      <ErrorMessage name="SUBSCRIPTION_TYPE">
                        {(msg) => (
                          <Typography style={{ color: "red" }}>
                            {msg}
                          </Typography>
                        )}
                      </ErrorMessage>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  className={classes.AddressGrid}
                  style={{ alignItems: "center" }}
                >
                  <Grid item xs={12} style={{ display: "flex" }}>
                    <Grid item xs={8} style={{ display: "flex" }}>
                      <Grid item xs={3}>
                        <Typography>{langConstant.SUBSCRIPTION}</Typography>{" "}
                      </Grid>
                      <Grid item xs={9}>
                        <Field name="SUBSCRIPTION">
                          {({ field: { value } }) => (
                            <Select
                              className={classes.select100Width}
                              value={subscriptionOptions.find(
                                (subscription) => subscription.value == value
                              )}
                              options={subscriptionOptions}
                              onChange={async (subscription) => {
                                setFieldValue(
                                  "SUBSCRIPTION",
                                  subscription.value
                                );

                                const tempPlanID = uniqueSubscriptionData[
                                  values.SUBSCRIPTION_TYPE
                                ]?.find(
                                  (item) => item.PLAN_ID == subscription.value
                                )["PLAN_ID"];
                                setFieldValue("PLAN_ID", tempPlanID);

                                //Calling getSubscriptionPrices
                                fetchPriceForSubscription(
                                  tempPlanID,
                                  values.USERS,
                                  values.BILLING
                                );
                              }}
                              placeholder={
                                packageSelected
                                  ? langConstant.SELECT_PACKAGE
                                  : regulationSelected
                                  ? langConstant.SELECT_REGULATION
                                  : langConstant.SELECT_SUBSCRIPTION
                              }
                              menuPortalTarget={document.body}
                            />
                          )}
                        </Field>

                        <ErrorMessage name="SUBSCRIPTION">
                          {(msg) => (
                            <Typography style={{ color: "red" }}>
                              {msg}
                            </Typography>
                          )}
                        </ErrorMessage>
                      </Grid>
                    </Grid>
                    {packageSelected && (
                      <Grid
                        item
                        xs={4}
                        style={{ display: "flex", marginLeft: "10px" }}
                      >
                        <Grid item xs={2}>
                          <Typography>{langConstant.USERS}</Typography>{" "}
                        </Grid>
                        <Grid item xs={9}>
                          <Field name="USERS">
                            {({ field: { value } }) => (
                              <TextField
                                className={classes.textField}
                                size="small"
                                variant="outlined"
                                fullWidth
                                value={value}
                                onChange={(e) => {
                                  setFieldValue("USERS", e.target.value);
                                  //Calling getSubscriptionPrices
                                  fetchPriceForSubscription(
                                    values.PLAN_ID,
                                    e.target.value,
                                    values.BILLING
                                  );
                                }}
                                onPaste={(e) => e.preventDefault()}
                                inputProps={{
                                  style: {
                                    whiteSpace: "normal",
                                    wordBreak: "break-all",
                                  },
                                }}
                                multiline
                              />
                            )}
                          </Field>

                          <ErrorMessage name="USERS">
                            {(msg) => (
                              <Typography style={{ color: "red" }}>
                                {msg}
                              </Typography>
                            )}
                          </ErrorMessage>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                <Grid
                  container
                  className={classes.AddressGrid}
                  style={{ alignItems: "center" }}
                >
                  <Grid item xs={8} style={{ display: "flex" }}>
                    <Grid item xs={3}>
                      <Typography>{langConstant.ENTER_COUPON_CODE}</Typography>{" "}
                    </Grid>
                    <Grid item xs={9}>
                      <Field name="COUPON_CODE">
                        {({ field: { value } }) => (
                          <TextField
                            className={classes.textField}
                            size="small"
                            variant="outlined"
                            fullWidth
                            value={values.COUPON_CODE}
                            onChange={(coupon) => {
                              setFieldValue("COUPON_CODE", coupon.target.value);
                            }}
                            onPaste={(e) => e.preventDefault()}
                            inputProps={{
                              style: {
                                whiteSpace: "normal",
                                wordBreak: "break-all",
                              },
                            }}
                            multiline
                          />
                        )}
                      </Field>

                      <ErrorMessage name="COUPON_CODE">
                        {(msg) => (
                          <Typography style={{ color: "red" }}>
                            {msg}
                          </Typography>
                        )}
                      </ErrorMessage>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={2}
                    style={{
                      marginLeft: "5px",
                      textAlign: "left",
                      alignItems: "left",
                      alignContent: "left",
                    }}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      type="button"
                      size="small"
                      style={{ alignItems: "center" }}
                      onClick={async () => {
                        dispatch(loadingActions.start());
                        const subscriptionResponse =
                          await SubscriptionServices.calculateSubscriptionCostWithCouponForAdmin(
                            values.COUPON_CODE,
                            customerId,
                            values.PLAN_ID,
                            values.BILLING
                          );
                        dispatch(loadingActions.end());

                        if (
                          subscriptionResponse &&
                          subscriptionResponse["#result-set-1"]
                        ) {
                          if (
                            subscriptionResponse["#result-set-1"][0][
                              "Message"
                            ] &&
                            subscriptionResponse["#result-set-1"][0][
                              "Message"
                            ] == "Invalid Coupon!!"
                          )
                            dispatch(alertActions.error("Invalid Coupon!!"));
                          else {
                            setFieldValue(
                              "COST_OF_SUBSCRIPTION",
                              subscriptionResponse["#result-set-1"][0][
                                "SUBSCRIPTION_AMOUNT"
                              ]
                            );
                            setAmount(
                              `£${subscriptionResponse["#result-set-1"][0]["SUBSCRIPTION_AMOUNT"]}`
                            );
                          }
                        } else {
                          dispatch(
                            alertActions.error(
                              langConstant.SOMETHING_WENT_WRONG
                            )
                          );
                        }
                      }}
                    >
                      {" "}
                      {langConstant.APPLY}{" "}
                    </Button>
                  </Grid>
                </Grid>
                <Grid
                  container
                  className={classes.AddressGrid}
                  style={{ alignItems: "center" }}
                >
                  <Grid item xs={8} style={{ display: "flex" }}>
                    <Grid item xs={3}>
                      <Typography>{langConstant.BILLING}</Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Field name="BILLING">
                        {({ field: { value } }) => (
                          <Select
                            className={classes.select100Width}
                            value={durationOptions.find(
                              (duration) => duration.value == value
                            )}
                            options={durationOptions}
                            onChange={(duration) => {
                              setFieldValue("BILLING", duration.value);
                              //Calling getSubscriptionPrices
                              fetchPriceForSubscription(
                                values.PLAN_ID,
                                values.USERS,
                                duration.value
                              );
                            }}
                            menuPortalTarget={document.body}
                          />
                        )}
                      </Field>

                      <ErrorMessage name="BILLING">
                        {(msg) => (
                          <Typography style={{ color: "red" }}>
                            {msg}
                          </Typography>
                        )}
                      </ErrorMessage>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  className={classes.AddressGrid}
                  style={{ alignItems: "center" }}
                >
                  <Grid item xs={8} style={{ display: "flex" }}>
                    <Grid item xs={4}>
                      <Typography>
                        {langConstant.COST_OF_SUBSCRIPTION}
                      </Typography>{" "}
                    </Grid>
                    <Grid item xs={6}>
                      <Field name="COST_OF_SUBSCRIPTION">
                        {({ field: { value } }) => (
                          <TextField
                            value={amount}
                            disabled={true}
                            className={classes.noOutline}
                            variant="outlined"
                            InputProps={{
                              classes: {
                                input: classes.inputProfileField,
                                notchedOutline: classes.noOutline,
                              },
                            }}
                          />
                        )}
                      </Field>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid
                    item
                    xs={12}
                    style={{ marginTop: "5px", textAlign: "right" }}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      size="small"
                    >
                      {" "}
                      {langConstant.CONFIRM_AND_PAY}{" "}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </div>
    </Dialog>
  );
};

AddNewSubscriptionPopup.propTypes = {
  modal: UseModalPropType.isRequired,
};

export default AddNewSubscriptionPopup;
