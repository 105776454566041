/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

import apiService from "./api.service";
import sessionService from "./session.service";
import _ from "lodash";

import moment from "moment";

const login = (username, password) => {
  return apiService
    .apiCall("user")
    .send("/auth", {
      username: username,
      password: password,
    })
    .then(apiService.handleResponse)
    .then((user) => {
      if (user && user.authenticated) {
        sessionService.setItem("authData", JSON.stringify(user));
        sessionService.setItem("jwtToken", user.jwtToken);
        sessionService.setItem("refreshToken", user.refreshToken);
      }
      return user;
    });
};

const loginWithMFA = (username, password) => {
  return apiService
    .apiCall("user")
    .send("/auth/mfa", {
      username: username,
      password: password,
    })
    .then(apiService.handleResponse)
    .then((user) => {
      // if (user && user.authenticated) {
      // sessionService.setItem("authData", JSON.stringify(user));
      if (user && user.authenticated && user.mfaType == "QR") {
        sessionService.setItem("QRCode", user.mfaRegisterCode);
      }
      //   sessionService.setItem("jwtToken", user.jwtToken);
      // }

      sessionService.setItem("validLicence", user.validLicence);
      return user;
    });
};

const validateOTP = (otp, userID, mfaType) => {
  return apiService
    .apiCall("user")
    .send("/auth/mfa/validateOTP", {
      loginOTP: otp,
      userID: userID,
      mfaType: mfaType,
    })
    .then(apiService.handleResponse)
    .then((user) => {
      if (user && user.validOTP) {
        sessionService.setItem("authData", JSON.stringify(user));
        sessionService.setItem("jwtToken", user.jwtToken);
        sessionService.setItem("refreshToken", user.refreshToken);
        sessionService.removeItem("QRCode");
      }
      return user;
    });
};

const getUserDetails = (params) => {
  return apiService
    .apiCall("user")
    .send("/userProfileInfo", params)
    .then(apiService.handleResponse)
    .then((response) => {
      if (response && response.userName) {
        const finalData = {
          userName: response.userName,
          userRole: response.userRole,
          userRoleId: response.userRoleId,
          userImageUrl: response.userImageUrl,
        };
        sessionService.setItem("userData", JSON.stringify(finalData));
        return finalData;
      } else {
        return response;
      }
    });
};
const getUserImageDetails = (params) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetUserDetailsV1", params)
    .then((response) => {
      if (response && response.data["#result-set-1"]) {
        // const userAvatar = response.data['#result-set-1'][0].AVATAR_IMAGE;
        // const primaryLogo = response.data['#result-set-1'][0].PRIMARY_LOGO;
        sessionService.setItem(
          "tempLogin",
          JSON.stringify({
            tempLogin: response.data["#result-set-1"][0].TEMP_LOGIN,
          })
        );
        sessionService.setItem(
          "customerId",
          response.data["#result-set-1"][0].CUSTOMER_ID
        );
        let allowedNavItems = [];
        response.data["#result-set-2"].forEach((item) =>
          allowedNavItems.push(item.FEATURE_NAME)
        );
        const userDetails = {
          ...response.data["#result-set-1"][0],
          AVATAR_IMAGE:
            "data:image/png;base64," +
            window.atob(response.data["#result-set-1"][0].AVATAR_IMAGE),
          PRIMARY_LOGO:
            "data:image/png;base64," +
            window.atob(response.data["#result-set-1"][0].PRIMARY_LOGO),
          TABLEAU_DEFAULT_DASHBOARDS: response.data["#result-set-3"]
            ? response.data["#result-set-3"]
            : [],
          NAVIGATION_DETAILS: response.data["#result-set-4"]
            ? response.data["#result-set-4"]
            : [],
          SYSTEM_MESSAGE: response.data["#result-set-9"]
            ? response.data["#result-set-9"]
            : [],
          FEATURE_LIST: response.data["#result-set-5"],
          EVENT_COUNT: response.data["#result-set-6"][0]["EVENT_COUNT"]
            ? parseInt(response.data["#result-set-6"][0]["EVENT_COUNT"])
            : 0,
          PRIVACY_DATALIST: response.data["#result-set-8"],
          ASSISTANT_DATA: response.data["#result-set-7"],
          ALLOWED_NAV_ITEMS: allowedNavItems,
          userName: response.data["#result-set-1"][0].Name,
          userRole: response.data["#result-set-1"][0].ROLE_NAME,
          userRoleId: response.data["#result-set-1"][0].ROLE_ID,
          userImageUrl:
            "data:image/png;base64," +
            window.atob(response.data["#result-set-1"][0].AVATAR_IMAGE),
        };
        const finalData = {
          userName: response.data["#result-set-1"][0].Name,
          userRole: response.data["#result-set-1"][0].ROLE_NAME,
          userRoleId: response.data["#result-set-1"][0].ROLE_ID,
          userImageUrl:
            "data:image/png;base64," +
            window.atob(response.data["#result-set-1"][0].AVATAR_IMAGE),
        };
        sessionService.setItem("userData", JSON.stringify(finalData));
        return userDetails;
      }
    });
};

const logout = (userID) => {
  //* remove user from local storage to log user out

  return apiService
    .apiCall("user")
    .send("/auth/mfa/logout", {
      userId: parseFloat(userID),
      token: sessionService.getItem("jwtToken"),
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        sessionService.clear();
        return response;
      },
      (error) => {
        sessionService.clear();
        return error;
      }
    );
};

const getAuthDetails = (userId) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetAuthDetails", {
      i_USER_ID: userId,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        return response;
      },
      (error) => {
        return error;
      }
    );
};

export default {
  login,
  getUserDetails,
  getUserImageDetails,
  logout,
  loginWithMFA,
  validateOTP,
  getAuthDetails,
};
