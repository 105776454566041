/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

import React, { useEffect, useState } from "react";
import { Box, Typography, Button } from "@material-ui/core";
import QuestionsPanel from "./QuestionsPanel";
import PropTypes from "prop-types";
import _ from "lodash";
import ExploreOutlinedIcon from "@material-ui/icons/ExploreOutlined";

const AssessmentNavigator = ({
  questionList,
  setPageNumber,
  onClose,
}) => {
  const [filteredList, setFilteredList] = useState();

  const [currentCategory, setCurrentCategory] = useState();
  let uniqueCategories;

  //* Find all unique categories
  const findCategories = (questionList) => {
    let allCategories = _.map(questionList, "CISO_CONTROL_NAME");

    uniqueCategories = _.uniq(allCategories).sort();
  };
  findCategories(questionList);

  useEffect(() => {
    setCurrentCategory(uniqueCategories[0]);
  }, []);

  //* Changes filtered List of questions everytime category is changed
  useEffect(() => {
    let filteredQuestions;

    filteredQuestions = questionList.filter((question) => {
      return question.CISO_CONTROL_NAME == currentCategory;
    });

    setFilteredList(filteredQuestions);
  }, [currentCategory]);

  return (
    <Box style={{ overflow: "hidden" }}>
      {/* // * Button to close Assessment Navigator */}
      <Button
        onClick={() => {
          onClose();
        }}
        style={{
          position: "absolute",
          left: "-50px",
          top: "50vh",
          backgroundColor: "rgba(240, 20, 47, 1)",
          color: "white",
          borderRadius: "6px 0px 0px 6px",
          zIndex: "100",
          minWidth: "50px ",
        }}
      >
        <ExploreOutlinedIcon />
      </Button>

      <Box
        style={{
          display: "flex",
          height: "100%",
          padding: "65px 0px 80px 0px",
        }}
      >
        <QuestionsPanel
          questionsList={filteredList}
          setPageNumber={setPageNumber}
        />
        {/* //* Map through Categories  */}
        <Box
          style={{
            padding: "20px 0 40px 0",
            overflowY: "auto",
          }}
        >
          {uniqueCategories &&
            uniqueCategories.map((categoryItem, index) => {
              return (
                <Button
                  key={categoryItem}
                  style={{
                    padding: "40px 50px 40px 20px",
                    display: "flex",
                    justifyContent: "left",

                    width: "100%",
                    borderRadius: "0px",
                    borderBottom: "1px solid rgba(0,0,0,0.2)",
                    backgroundColor: `${
                      categoryItem == currentCategory ? "#2196f3" : ""
                    }`,
                  }}
                  onClick={() => setCurrentCategory(categoryItem)}
                >
                  <Typography
                    style={{
                      fontSize: "27px",
                      fontWeight: "bold",
                      marginRight: "20px",

                      color: `${
                        categoryItem == currentCategory ? "#fff" : "#666"
                      }`,
                    }}
                  >
                    {(index + 1).toLocaleString("en-US", {
                      minimumIntegerDigits: 2,
                      useGrouping: false,
                    })}
                  </Typography>
                  <Typography
                    style={{
                      textTransform: "none",
                      maxWidth: "350px",
                      textAlign: "left",
                      color: `${
                        categoryItem == currentCategory ? "#fff" : "#666"
                      }`,
                    }}
                  >
                    {categoryItem}
                  </Typography>
                </Button>
              );
            })}
        </Box>
      </Box>
    </Box>
  );
};

AssessmentNavigator.propTypes = {
  onClose: PropTypes.func,
  setPageNumber: PropTypes.func,
  questionList: PropTypes.array,
};
export default AssessmentNavigator;
