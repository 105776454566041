/*COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. 
*/

import React from "react";

export default {
  // Common
  CONTINUE: "Continue",
  GO_BACK: "Go Back",
  YES_PLEASE: "Yes Please",
  NO_THANKS: "No Thanks",
  UPLOAD_FILE: "Upload File",
  FILE_UPLOADED_SUCCESSFULLY: "File Uploaded Successfully",
  BROWSE_FILE: "Browse File",
  DATA_TYPE: "Data Type",
  SELECT: "Select",
  CANCEL: "Cancel",
  SAVE: "Save",
  LOGOUT_FAILURE: "Logout Failure",
  UPLOADING: "Uploading...",
  EXTRACTING: "Extracting...",
  FILE_LESS_THAN_20: "File size should be less than 20MB",
  RESET: "Reset",
  EDIT: "Edit",
  DELETE: "Delete",

  // Page Headings
  EVIDENCE_LOCKER: "EVIDENCE LOCKER",

  //VRM Related
  VRM_TITLE: "VENDOR RISK MANAGEMENT",
  VRM_LIST_NOT_FOUND: "VRM Assessment list not found",
  CATEGORY: "Category",
  DURATION: "Duration:",
  SERVICE: "Service:",
  ADDRESS: "Address:",
  SUPPLIER: "SUPPLIER:",

  // SQM related
  SQM_TITLE: "SUPPLIER QUESTIONNAIRE MANAGEMENT",
  EVIDENCE_ATTACHMENT_MANDETORY: "Evidence attachment mandetory",
  NO_RESPONSE_PROVIDED: "No response provided",
  DELETE_ALL: "Delete All",

  //SUPPLIERS related
  SUPPLIER_NAME: "Supplier Name",
  SUPPLIERS_LIST_NOT_FOUND: "Suppliers list not found",
  SUPPLIER_CATEGORY: "Supplier Category",
  SUPPLIER_ADDRESS: "Supplier Address",
  SUPPLIER_RISK_RATING: "Supplier Risk Rating",
  NEW_SUPPLIER_SUCCESSFULLY_ADDED:
    "New Supplier added successfully. It is mandatory to create atleast one supplier contact.",
  SUPPLIER_SUCCESSFULLY_UPDATED: "Supplier updated successfully",
  SUPPLIER_CONTACT_SUCCESSFULLY_ADDED: "Supplier contact added successfully",
  SUPPLIER_CONTACT_SUCCESSFULLY_UPDATED:
    "Supplier contact updated successfully",
  PLATFORM_LOGIN_CREATED_SUCCESSFULLY: "Platform login created successfully",
  VIEW_SUPPLIER_RECORD: "View Supplier Record",
  ADD_SUPPLIER_CONTACT: "Add Supplier Contact",
  ADD_NEW_SUPPLIER: "Add New Supplier",
  CREATE_PLATFORM_LOGIN: "Create Platform Login",
  USERNAME_ALREADY_EXISTS: "Username already exists",
  CONTACT_NAME: "Contact Name",
  ADD_MORE_CONTACTS: "Add More Contacts",
  DATA_CLASSIFICATION: "Data Classification",
  TEAM: "Team",
  TEAM_NAME: "Team Name",
  ADD_NEW_TEAM: "Add New Team",
  NEW_TEAM_ADDED_SUCCESSFULLY: "New Team Added Successfully",
  // Calendar related
  OPEN_CALENDAR: "Open Calendar",
  UPDATE_CALENDAR_ENTRIES_SUCCESS: "Calendar entry Updated Successfully",
  UPDATE_CALENDAR_ENTRIES_FAILURE: "Calendar entry not Updated",
  CREATE_MEETING_SUCCESS: "Meeting created Successfully",
  CREATE_MEETING_FAILURE: "Meeting not created",
  UPDATE_MEETING_SUCCESS: "Meeting updated Successfully",
  UPDATE_MEETING_FAILURE: "Meeting not updated",
  GET_CALENDAR_ENTRIES_FAILURE: "User calendar entries not found",

  // Create meeting
  TITLE: "Title",
  DESCRIPTION: "Description",
  START_DATE: "Start Date",
  START_TIME: "Start Time",
  END_DATE: "End Date",
  END_TIME: "End Time",
  ALL_DAY_EVENT: "All day event",
  OWNER: "Owner",
  ATTENDEES: "Attendees",
  SAVE_CHANGES_BUTTON: "SAVE CHANGES",
  CREATE_BUTTON: "CREATE",
  CREATE_NEW_MEETING: "CREATE NEW MEETING",
  UPDATE_MEETING: "UPDATE MEETING",
  // Rules Builder
  RULES_BUILDER: "Rules Builder",

  // Manage Question Bank Questions
  RESPONSE_TYPE: "Response Type",
  ADD_HEADER: "Add Header",
  ADD_DESCRIPTION: "Add Description",
  REGULATOR_RESPONSE_CRITERIA: "REGULATOR RESPONSE CRITERIA",
  GUIDANCE_INFORMATION: "GUIDANCE INFORMATION",
  NO_GUIDANCE_INFORMATION_AVAILABLE: "No guidance information available",
  NO_QUESTION_TEXT_AVAILABLE: "No question text available",
  RESPONSE: "RESPONSE",
  DATAPOINTS_AND_CONTROLS_MAPPED: "DATAPOINTS AND CONTROLS MAPPED",
  PLEASE_SELECT_CONTROLS: "Please select Controls",
  QUESTION_CONTROLS: "Question Controls",
  ADD_MORE_CONTROLS: "Add More Controls",
  ADD_MORE_RESPONSE_CRITERIA: "Add More Response Criteria",
  ADD_MORE_DATAPOINTS: "Add More Datapoints",
  ADD_EVIDENCE_TYPE: "Add Evidence Type",
  NARRATIVE: "NARRATIVE",
  QUESTION_DATAPOINTS: "Question Datapoints",
  PLEASE_SELECT_DATAPOINTS: "Please select Datapoints",
  DUPLICATE_CONTROLS_FOUND: "Duplicate Controls found",
  DUPLICATE_DATAPOINTS_FOUND: "Duplicate Datapoints found",
  DATA_AND_AUTOMATION_RULES: "DATA & AUTOMATION RULES",
  EVIDENCE_LIST: "EVIDENCE LIST",
  CREATE_RULE: "CREATE RULE",
  OPEN_RULES_BUILDER: "OPEN RULES BUILDER",
  ADD_EVIDENCE_LIST_THAT_NEEDS_TO_BE_UPLOADED:
    "ADD EVIDENCE LIST THAT NEEDS TO BE UPLOADED",
  DUPLICATE_EVIDENCE_TYPES_FOUND: "Duplicate Evidence Types found",
  PLEASE_SELECT_EVIDENCE_TYPES: "Please select Evidence types",
  PLEASE_SELECT_EVIDENCE: "Please select Evidence",
  PLEASE_SELECT_EVIDENCE_WEIGHTS: "Please select Evidence weights",
  EVIDENCE_WEIGHTS_MORE_THAN_100: "Evidence weights add up to more than 100%",
  EVIDENCE_WEIGHTS_LESS_THAN_100: "Evidence weights add up to less than 100%",
  NO_RESPONSE_MAPPED_TO_QUESTION: "No Response are mapped to this question",
  NO_CONTROLS_MAPPED_TO_QUESTION:
    "No Controls are mapped to this question / control",
  NO_DATAPOINTS_MAPPED_TO_QUESTION:
    "No Datapoints are mapped to this question / control",
  NO_NARRATIVE_MAPPED_TO_QUESTION:
    "No narrative is assigned to this question / control",
  NO_AUTOMATION_DEFINED_FOR_QUESTION:
    "No automation rules are defined for this question / control",
  NO_EVIDENCE_MAPPED_FOR_QUESTION:
    "No Evidence types are mapped to this question / control",
  QUESTION_GUIDANCE_UPDATED_SUCCESSFULLY:
    "Question Guidance Updated Successfully",
  QUESTION_TEXT_UPDATED_SUCCESSFULLY: "Question Text Updated Successfully",
  QUESTION_RESPONSE_UPDATED_SUCCESSFULLY:
    "Question Response Updated Successfully",
  QUESTION_RESPONSE_UPDATE_FAILED: "Question Response Update Failed",
  QUESTION_RESPONSE_REMOVE_SUCCESSFULLY:
    "Question Response Removed Successfully",
  QUESTION_RESPONSE_REMOVE_FAILED: "Question Response Remove Failed",
  QUESTION_RESPONSE_INSERTED_SUCCESSFULLY:
    "Question Response Inserted Successfully",
  QUESTION_RESPONSE_INSERTED_FAILED: "Question Response Inserte Failed",
  QUESTION_GUIDANCE_UPDATE_FAILED: "Question Guidance Update Failed",
  QUESTION_CONTROLS_UPDATED_SUCCESSFULLY:
    "Question Controls Updated Successfully",
  QUESTION_DATAPOINTS_UPDATED_SUCCESSFULLY:
    "Question Datapoints Updated Successfully",
  QUESTION_EVIDENCE_TYPES_UPDATED_SUCCESSFULLY:
    "Question Evidence Types Updated Successfully",
  QUESTION_NARRATIVE_UPDATED_SUCCESSFULLY:
    "Question Narrative Updated Successfully",
  SET: "Set",
  IF_NONE_OF_THE_CONDITIONS_ARE_MET:
    "if none of the previous condition are met",

  // Upload Data
  TYPE_FILE_DONT_MATCH: "Data Type and Uploaded File name don't match",
  DATA_TYPE_NOT_FOUND: "Data types not found",
  DATA_TYPE_REQUIRED: "Data Type is required",
  FILE_IS_REQUIRED: "File is required",
  EXTENSION_NOT_SUPPORTED:
    "Uploaded file is not supported. Please upload file with extension .csv or .json",
  ADD_MORE_ATTACHMENT: "Add More Attachment",

  // View Evidence Locker
  FILTER_TABLE: "Filter Table",
  EVIDENCE_TYPE: "Evidence Type",
  TAGS: "Tags",
  UPLOADED_BY: "Uploaded By",
  NO_ATTACHMENTS: "No Attachments",
  NO_WORDCLOUD: "No Wordcloud",
  NO_TAGS: "No Tags",
  LATEST: "LATEST",
  DOWNLOAD: "Download",
  VERSION: "Version",
  UPLOAD_DATE_AND_TIME: "Upload Date & Time",
  EVIDENCE_NOT_FOUND: "Evidence not found",
  USER_NOT_FOUND: "User Not Found",
  ASSESSMENT_EVIDENCE_NOT_FOUND: "Assessment evidence not found",
  VIEW_ATTACHED_EVIDENCE: "View Attached Evidence",
  EVIDENCE_LOCKER_TITLE: "EVIDENCE LOCKER",
  VIEW_EVIDENCE_LOCKER: "View Evidence Locker",

  // Attach Evidence Table
  EVIDENCE_TYPE_REQUIRED: "Evidence Type*",
  ADD_CLASSIFICATION_REQUIRED: "Add Classification*",
  ADD_TAGS_REQUIRED: "Add Tags*",
  UPLOAD_FILE_REQUIRED: "Upload File*",
  DELETE_ROW: "DELETE ROW",
  SELECT_TYPE: "Select Type",
  SELECT_CLASSIFICATION: "Select Classification",
  EXTENSION_NOT_SUPPORTED_ATTACH_EVIDENCE:
    "Uploaded file is not supported. Please upload file with extension .pdf .jpeg .doc .xlsx .csv .bmp or .png",
  CLASSIFICATION_NOT_SELECTED: "Please select classification for the file",
  EVIDENCE_TYPE_NOT_SELECTED: "Please select evidence type for the file",

  // Notifications
  NOTIFICATIONS: "NOTIFICATIONS",
  MARK_ALL_AS_READ: "MARK ALL AS READ",
  SEE_EARLIER_NOTIFICATIONS: "See all earlier notifications",

  //Manage RFI
  TOTAL_RFI_RECEIVED: "Total RFI Received",
  TOTAL_RFI_RESOLVED: "Total RFI Resolved",
  RFI_PENDING_TEXT: "RFI Pending",
  RFI_NEARING_DUE_DATE: "RFI Nearing Due Date",
  RFI_PASSED_DUE_DATE: "RFI Passed Due Date",
  RFIS_SUCCESSFULLY_SENT_FOR_APPROVAL: "Rfis successfully sent for approval",
  NO_RFI_IS_DUE_WITHIN_THIS_TIME_PERIOD:
    "No RFI is due within this time period",
  PROVIDE_NARRATIVE: "Provide narrative",
  PROVIDE_ANSWER: "Provide answer",
  ASSIGNED_BY: "Assigned By",
  ASSIGN_BY: "Assign By",
  ACTION_LIST: "Action List",
  EVIDENCE_ATTACHMENT_MANDATORY: " Evidence attachment mandatory",
  ADD_NARRATIVES: "ADD NARRATIVES",

  //Initiate Assessment
  REVIEW_VRM_ASSESSMENT: "REVIEW ASSESSMENT",
  APPROVE_ASSESSMENT: "APPROVE ASSESSMENT",
  COMPLETE_SQM: "COMPLETE SQM",
  COMPLETE_ASSESSMENT: "COMPLETE ASSESSMENT",
  CONFIRMATION: "CONFIRMATION",
  SEND_FOR_REVIEW: "SEND FOR REVIEW",
  TOP_SIMILAR_ANSWERS: "Top 3 most similar answers in previous years",
  CURRENT_ANSWER: "Current Answer",
  MARK_AS_COMPLETED: "Mark as completed",
  MARK_AS_ACCEPTED: "Mark as accepted",
  MARK_AS_RESOLVED: "Mark as resolved",
  REQUEST_RFI: "Request Rfi",
  ASSIGN_RFI: "Assign Rfi",
  ASSIGN_RFI_TO: "Assign RFI to",
  COVER_NOTE: "Cover Note",
  DUE_DATE: "Due Date",
  ASSIGN_NOW: "Assign Now",
  RFI_FILTER: "RFI Filter",
  DATA_TRACE: "Data Trace",
  NO_RECORD_FOUND: "No Record Found",
  RFI_DETAILS: "RFI Details",
  PAST_DUE_DATE: "past due date",
  CHANGE_RFI_DATE: "Change RFI Date",
  CHANGE_RFI_DUE_DATE: "Change RFI Due Date",
  CANCEL_RFI_PROMPT: "Are you sure you want to cancel the RFI assigned?",
  CANCEL_ASSIGNED_RFI: "Cancel Assigned RFI",
  CANCEL_RFI: "Cancel RFI",
  SEND_CHASER_EMAIL: "Send Chaser Email",
  CHASER_EMAIL_RFI_PROMPT:
    "Are you sure you want to send reminder request for this RFI?",
  CHASER_EMAIL_CONFIRMATION: "Chaser Email Confirmation",
  RESOLVE_RFI_PROMPT_FN: (content_QUESTION_REF) => {
    return `Are you sure you want to resolve <b> ${content_QUESTION_REF} </b> and mark it as complete?`;
  },
  SEND_BACK_TO_SUPPLIER_FN: () => {
    return `Confirm question is to be sent back to the Supplier SPOC?`;
  },
  CREATE_RFI_ALERT_PROMPT_SUCCESS: (content_QUESTION_REF) => {
    return `RFI assigned for ${content_QUESTION_REF} successfully`;
  },
  RESOLVED_RFI_ALERT_PROMPT: (content_QUESTION_REF) => {
    return `RFI resolved for ${content_QUESTION_REF} successfully`;
  },
  DUE_DATE_CHANGED_RFI_ALERT_PROMPT: (content_QUESTION_REF) => {
    return `Due Date changed for ${content_QUESTION_REF} successfully`;
  },
  RFI_CANCELLED_ALERT_PROMPT: (content_QUESTION_REF) => {
    return `RFI cancelled for <b>${content_QUESTION_REF}</b> successfully`;
  },
  RESOLVE_RFI_CONFIRMATION: "RESOLVE RFI CONFIRMATION",
  RESOLVE_RFI: "Resolve RFI",
  RESOLVED_ON: "Resolved on",
  SEND_BACK_TO_SUPPLIER_SPOC: "SEND BACK TO SUPPLIER SPOC",

  // Approve Regulation
  ERROR_IN_QUESTIONS_LIST: "Error in extracted Questions list",
  NARRATIVES_WITHIN_SCOPE: (NARRATIVES) => {
    return `We have ${NARRATIVES} within scope`;
  },
  NARRATIVES: "NARRATIVES",
  METADATA: "METADATA",
  SEND_BACK_CONFIRMATION: "SEND BACK CONFIRMATION",
  REVIEW_QUESTION_CONFIRMATION: "REVIEW CONFIRMATION",
  SELECTED_QUESTION_SENT_BACK: "Selected Question(s) have been 'SENT BACK'",
  SELECTED_QUESTION_REVIEWED:
    "Selected Question(s) have been marked as 'REVIEWED'",
  REGULATION_APPROVED:
    "Regulaton has been approved & is available in the system.",
  APPROVE_REGULATION_CONFIRMATION: "APPROVE REGULATION CONFIRMATION",
  APPROVE_REGULATION_CONFIRMATION_MESSAGE: (regulation) => {
    return `Are you sure you want to approve the Regulation <b>${regulation}</b> ?`;
  },
  REMOVE_RESPONSE_OPTION_CONFIRMATION: "REMOVE RESPONSE OPTION CONFIRMATION",
  DELETING_RESPONSE_CONFIRMATION_MESSAGE: (responseOption) => {
    return `Please confirm you wish to delete '<b> ${responseOption}</b>'. By deleting, you will be DELETING all the Rules that are assigned to that '<b>${responseOption}</b>'`;
  },
  RETURN_REGULATION: "RETURN REGULATION",
  RETURN_REGULATION_CONFIRMATION: "RETURN REGULATION CONFIRMATION",

  CONFIRM_REJECT_REGULATION_FN: (name) => {
    return `Are you sure you want to '<b style="color:red">REJECT</b>' the Regulation <b>${name}</b>?`;
  },
  REGULATION_SENT_BACK_ALERT: (regulationName, initiator) =>
    `${regulationName} has been Rejected and sent back to ${initiator} successfully`,
  UNCATEGORIZED: "Uncategorized",
  NO_METADATA_AVAILABLE: "No Metadata Available",
  NO_NARRATIVES_AVAILABLE: "No Narratives Available",
  INSERT_NARRATIVE_TEMPLATE: "Insert Narrative Template",

  // Comments
  REMARKS_HISTORY: "Remarks History",
  ADD_REMARKS: "Add Remark(s)",
  ADD_REMARK: "Add Remark",
  REMARK: "Remark",
  REMARKS: "Remarks",

  // TAB
  DASHBOARD: "Dashboard",
  OPEN: "Open",
  ALL: "All",
  RFI_SENT: "RFI Sent",
  RFI_PENDING: "RFI Pending",
  RFI_OVERDUE: "RFI Overdue",
  RFI_RESOLVED: "RFI RESOLVED",
  RFI_ASSIGNED: "RFI ASSIGNED ",
  SEND_BACK: "Send Back",
  ANSWER: "ANSWER",
  REVIEWED: "Reviewed",
  SUBMITTED: "SUBMITTED",
  SUBMIT: "Submit",
  ACCEPTED: "ACCEPTED",

  //Progress
  COMPLETED: "Completed",
  COMPLETED_AND_LOCKED: "Completed and Locked",
  SENT_BACK: "Sent Back",
  PENDING_DATA: "Pending Data",
  READY_FOR_REVIEW: "Ready for Review",
  FINAL: "Final",

  QUESTIONS_ADDED_TO_QUESTION_BANK: "Questions added to Question Bank",
  QUESTIONS_EXTRACTED: "Questions Extracted",
  SELECT_CONTROLS: "Select Controls",
  SELECT_DATAPOINTS: "Select Datapoints",

  //Validation Message
  ERROR_DATAPOINTS_IS_REQUIRED: "Datapoints is required",
  ERROR_CONTROLS_IS_REQUIRED: "Controls is required",
  ERROR_ASSESSMENT_IS_REQUIRED: "Assessment is required",
  ERROR_TEAM_MEMBER_IS_REQUIRED: "Team Member is required",
  ERROR_PRIORITY_IS_REQUIRED: "Priority is required",
  ERROR_SUBMISSION_DEADLINE_IS_REQUIRED: "Submission Deadline is required",
  ERROR_TARGET_COMPLETION_DATE_IS_REQUIRED:
    "Target Completion Date is required",
  ERROR_TARGET_COMPLETION_DATE_MUST_BE_EQUAL_OR_LESS_THAN_SUBMISSION_DEADLINE:
    "Target Completion Date must be equal or less than Submission Deadline",
  VIEW_ASSESSMENT: "View Assessment",
  TRUST_SCORE: "Trust Score",
  DATAPOINTS_LIST: "Datapoints List",
  HIGH: "High",
  MEDIUM: "Medium",
  LOW: "Low",
  VIEW_DETAIL: "View Detail",
  NO_DATAPOINTS_AVAILABLE: "No Datapoints Available",
  REVIEW_ASSESSMENT: "Review Assessment",
  REVIEW_QUESTIONS: "REVIEW QUESTIONS",
  APPROVE_REGULATION: "APPROVE REGULATION",
  SUBMIT_TO_REGULATOR: "Submit to Regulator",
  SUBMIT_TO_MANAGER: "Submit to Manager",
  SELECT_ALL_QUESTIONS: "Select All Questions",
  SELECT_ALL: "Select All",

  //Button
  ATTACHMENT: "Attachment",
  ATTACHMENTS: "Attachments",
  ATTACH_EVIDENCE: "Attach Evidence",
  ATTACHING_EVIDENCE_DISABLED: "Attaching Evidence Disabled",
  MARK_AS_REVIEWED: "Mark as Reviewed",
  ASSIGN_ASSESSMENT: "Assign Assessment",
  CONFIRM_SENT_BACK_FN: (item) => {
    return `Are you sure you want to move <b>${item.refId}</b> to SENT BACK Tab ?`;
  },
  CONFIRM_REVIEWED_QUESTIONS_FN: () => {
    return `Are you sure you want to mark the question(s) as '<b>REVIEWED</b>'?`;
  },
  SEND_BACK_QUESTIONS_CONFIRMATION_FN: () => {
    return `Are you sure you want to send back the question(s) to the Analyst? <br/><br/>
     NOTE: This action cannot be undone.`;
  },
  SEND_BACK_QUESTION_CONFIRMATION: "SEND BACK QUESTION CONFIRMATION",

  //Messages
  ERROR_LISTING_ASSESSMENT_QUESTIONS: "Error in listing Assessment Questions",

  FILE_DOWNLOAD_SUCCESSFULLY: "File download successfully",

  LOGOUT_MESSAGE_FN: () => {
    return `Are you sure you want to '<b>LOG OUT</b>'?`;
  },

  //Menu Item
  POLICY_MANAGEMENT: "Policy Management",
  MANAGE_REGULATIONS: "Manage Regulations",
  SELECTED_QUESTION_MARK_AS_COMPLETE:
    "Selected question(s) have been marked as complete and locked",
  SELECTED_QUESTION_MARK_AS_SEND_BACK: "Selected question has been SENT BACK",
  ASSESSMENT_SUBMITTED_FOR_REVIEW_SUCCESSFULLY:
    "Assessment submitted for review successfully",
  ERROR_GETTING_ASSESSMENT_DETAIL: "Error in Assessment Questions",
  NO_ASSESSMENT_IS_AVAILABLE: "No assessment is available",

  NO_OF_QUESTIONS: "No. of Questions",
  DRAFT: "Draft",
  DRAFT_UPDATED_SUCCESSFULLY: "Draft updated successfully",
  REGULATION_SENT_SUCCESSFULLY: "Regulation Sent For Review Successfully",
  PROGRESS: "Progress",
  TARGET_COMPLETION_DATE: "Target Completion Date",
  ACTIONS: "Actions",

  MY_TEAM: "My Team",
  TOTAL_ASSESSMENT: "Total Assessment",
  TOTAL_SUBMITTED: "Total Submitted",
  PASSED_DUE_DATE: "Passed Due Date",
  NEARING_DUE_DATE: "Nearing Due Date",
  TOTAL_EFFORT_LOGGED: "Total Effort logged",
  ASSESSMENT_NAME: "Assessment Name",
  LEGAL_ENTITY: "Legal Entity",
  DATE_INITIATED_FROM: "Date Initiated(From)",
  SUBMISSION_DATE_TO: "Submission Date(To)",
  EFFORT_LOGGED_EXPECTED: "Effort Logged/Expected (In Hrs)(%)",
  SUBMISSION_DEADLINE_TO_REGULATOR: "Submission Deadline to Regulator",
  DAYS_LEFT_FOR_SUBMISSION: "Days Left for Submission",
  STATUS: "Status",
  SELECT_STATUS: "Select Status",
  SELECT_TEAM_MEMBER: "Select Team Member",
  DATA_ASSET_UPDATED_SUCCESSFULLY: "Data assets updated successfully",
  COMPLIANCE_UPDATED_SUCCESSFULLY: "Compliance Decision updated successfully",
  QUESTION_UPDATED_SUCCESSFULLY: "Question updated successfully",
  QUESTION_REF: "QUESTION REF.",
  ASSESSMENT: "Assessment",
  CHECK_RFI: "Check RFI",
  VIEW: "View",

  //Navigation Item
  MANAGE_TEAM: "Manage Team",

  //Reject Assessment
  REJECT_ASSESSMENT: "Reject Assessment",
  REJECT_ASSESSMENT_CONFIRMATION: "Reject Assessment Confirmation",
  CONFIRM_REJECT_ASSESSMENT_FN: (name) => {
    return `Are you sure you want to '<b style="color:red">REJECT</b>' the Assessment <b>${name}</b>?`;
  },
  REJECT_ASSESSMENT_MESSAGE_FN: (name, regulatorName) => {
    return `${regulatorName} has been rejected and sent back to ${name}`;
  },
  //Assign Assessment
  MEMBER_IS_CURRENTLY_WORKING_ON_FN: (name) => {
    return `${name} is currently working on`;
  },
  REASSIGN_ASSESSMENT_CONFIRMATION: "Reassign Assessment Confirmation",
  SUCCESS_ASSIGN_ASSESSMENT_FN: (assessmentName, name) => {
    return `${assessmentName} has been assigned to ${name}`;
  },
  CONFIRM_REASSIGN_ASSESSMENT_FN: (assessmentName, name) => {
    return `<p><b>${assessmentName}</b> is currently assigned to <b>${name}</b>.<br /> Are you sure you want to reassign it to any other team member?</p> <p>NOTE: All the history of the assessment and any changes made by this user will be retained</p>`;
  },
  //Form Labels
  SELECT_ASSESSMENT: "Select Assessment",
  SELECT_TEAM_MEMBER_TO_ASSIGN: "Select Team Member to Assign",
  SELECT_PRIORITY: "Select Priority",

  SELECT_SUBMISSION_DEADLINE: "Select Submission Deadline",
  CHANGE_DATE: "change date",
  SELECT_TARGET_COMPLETION_DATE: "Select Target Completion Date",

  //Button Label
  REASSIGN: "Reassign",

  REGULATION_NAME: "Regulation Name",
  REGULATOR: "Regulator",
  REF_NO: "Ref No",
  QUESTION: "Question",
  RULE_TYPE: "Rule Type",
  MANUAL: "Manual",
  AUTOMATION: "Automation",
  SEARCH_QUESTION: "Search Question",
  RESET_ALL: "Reset All",
  VIEW_ORIGINAL_DOC: "View Original Doc",
  RULE_BUILDER_TOOLTIP: "Rules Builder",

  PLEASE_SELECT_ATTRIBUTE: "Please select Attribute for all options",
  PLEASE_SELECT_CONDITION: "Please select Condition for all options",
  PLEASE_SELECT_TYPE: "Please select Type for all options",
  PLEASE_ADD_VALUE: "Please add Value to compare for all options",

  // validation required
  INPUT_REQUIRED: "This field is required",

  //update remark
  REMARK_ADDED: "Remark Successfully Added",
  //update tags
  EVIDENCE_TAGS_UPDATED: "Evidence Tags Updated",
  //update evidence status
  EVIDENCE_ACCEPTED: "Evidence Accepted",

  COLLAPSE_ALL: "COLLAPSE ALL",
  EXPAND_ALL: "EXPAND ALL",

  //something went wrong
  SOMETHING_WENT_WRONG: "Something Went Wrong",

  //success messages
  USER_DETAILS_SUCCESSFULLY_UPDATED: "User Details Successfully Updated",

  //Policy explorer
  POLICY_EXPLORER: "Policy Explorer",
  POLICY_COMPARISON_ANALYSIS: "Policy Comparison Analysis",
  RUN_COMPARISON: "Run Comparison",
  NEW_POLICY: "New Policy",

  //Policy explorer table
  POLICY_NAME: "Policy Name",
  POLICY_FILE_NAME: "Policy File Name",
  UPLOADED_DATE_TIME: "Uploaded date time",
  PLEASE_SELECT_TARGET_POLICY: "Please Select Target Policy",
  PLEASE_SELECT_BOTH_POLICY_WITH_SAME_LANGUAGE:
    "Please Select Both Policy With Same Language",
  PLEASE_SELECT_TWO_POLICY: "Please Select Two Policy",
  POLICY_LIST_NOT_FOUND: "Policy list not found",

  //policy comparison
  POLICY_COMPARISON: "Policy Comparison",
  COMPARISON_FILE_NAME: "Comparison Output Data File Name",
  COMPARISON_DATE_TIME: "Comparison Date Time",

  COMPARISON_NAME_REQUIRED: "Comparison Name Required",
  COMPARISON_TYPE_REQUIRED: "Comparison Type Required",
  THRESHOLD_SETTINGS_REQUIRED: "Threshold settings Required",
  DISTANCE_CALCULATOR_REQUIRED: "Distance Calculator Required",

  //Policy Execute Comparison
  POLICY_EXECUTE_COMPARISON: "Policy Execute Comparison",
  UPLOAD_FILES_ONLY: "Upload Files Only",
  UPLOAD_AND_EXECUTE_COMPARISON: "Upload And Execute Comparison",
  EXECUTE_COMPARISON_ONLY: "Execute Comparison",
  GENERATE_COMAPRISON: "Generate Comparison",
  COMPARISON_RECIPE: "Comparison Recipe",
  SEARCH_BASE_POLICY_HEADINGS: "Search Base Policy Headings",

  BASE_DOCUMENT: "Base Document",
  BASE_DOCUMENT_NAME: "Base Document Name",
  BASE_DOCUMENT_FILENAME: "Base Document FileName",

  BASE_DOCUMENT_REQUIRED: "Base Document Required",
  BASE_DOCUMENT_NAME_REQUIRED: "Base Document Name Required",
  BASE_DOCUMENT_FILENAME_REQUIRED: "Base Document FileName Required",

  TARGET_DOCUMENT: "Target Document",
  TARGET_DOCUMENT_NAME: "Target Document Name",
  TARGET_DOCUMENT_FILENAME: "Target Document FileName",

  TARGET_DOCUMENT_REQUIRED: "Target Document Required",
  TARGET_DOCUMENT_NAME_REQUIRED: "Target Document Name Required",
  TARGET_DOCUMENT_FILENAME_REQUIRED: "Target Document FileName Required",

  COMPARISON_PARAMETERS: "Comparison Parameters",
  COMPARISON_NAME: "Comparison Name",
  COMPARISON_TYPE: "Comparison Type",
  THRESHOLD_SETTINGS: "Threshold Settings",
  DISTANCE_CALCULATOR: "Distance Calculator",
  //Manage Team
  MANGE_MY_TEAM_PROFILE: "Manage My Team Profile",
  BASIC_INFORMATION: "BASIC INFORMATION",

  //Unlock User
  UNLOCK_USER: "UNLOCK USER",
  USER_SUCCESSFULLY_UNLOCKED: "USER SUCCESSFULLY UNLOCKED",
  USER_AVATAR_SUCCESSFULLY_UPDATED: "USER AVATAR SUCCESSFULLY UPDATED",
  USER_DEACTIVATED: "USER DEACTIVATED",
  USER_ACTIVATED: "USER ACTIVATED",

  //Send Temporary Password
  SEND_TEMPORARY_PASSWORD: "SEND TEMPORARY PASSWORD",
  TEMP_PASSWORD_GENERATED: "TEMPORARY PASSWORD SUCCESSFULLY GENERATED",

  //Change Entitlement
  CHANGE_ENTITLEMENT: "CHANGE ENTITLEMENT",
  ACTIVE_ENTITLEMENT: "Active Entitlement",
  SELECT_ENTITLEMENT: "Select Entitlement",
  SELECT_ROLE: "Select Role",
  USER_ENTITLEMENT_CHANGED: "User Entitlement Successfully Changed",

  FILE_UPLOADING: "File Is Uploading...",
  COMPARISON_UNDER_PROCESS: "Comparison Under Process ...",
  COMPARISON_IN_PROGRESS: "Comparison In Progress",

  //Select
  SELECT_: "Select ",

  //ADD NEW REGULATOR
  ADD_NEW_REGULATOR: "ADD NEW REGULATOR",
  ADDRESS_OF_THE_PRIMARY_OFFICE: "Address of the primary office",
  REGULATOR_URL: "Regulator URL",
  JURISDICTION: "Jurisdiction",
  REGULATION_VERSION: "Regulation Version",
  STORAGE_FOLDER: "Storage Folder",
  REGULATION_DOCUMENT_ARTEFECT_LINK: "Regulation Document artefect Link",
  ADD_MORE_KEY_PERSON: "ADD MORE KEY PERSON",
  KEY_PERSON: "Key Person",
  DESIGNATION: "Designation",
  EMAIL_ADDRESS: "Email Address",
  EMAIL: "Email",
  PHONE_NUMBER: "Phone Number",
  ADD_NEW_REGULATION: "Add New Regulation",
  NEW_REGULATION: "New Regulation",
  REGULATOR_INFO: "Regulator Info",
  REGULATION_LIST: "Regulation List",
  REGULATOR_RESPONSE_TYPE: "Regulator Response Type",
  REGULATORY_DOMAIN: "Regulatory Domain",
  ADD_KEY_PERSON: "Add Key Person",
  REGULATOR_NAME: "Regulator Name",
  KEY_PERSON_PHONE_NUMBER: "Phone Number",
  KEY_PERSON_EMAIL_ADDRESS: "Email Address",
  REGULATOR_SUCCESSFULLY_UPDATED: "Regulator Successfully Updated",
  REGULATOR_SUCCESSFULLY_ADDED: "Regulator Successfully Added",
  REGULATION_SUCCESSFULLY_UPDATED: "Regulation Successfully Updated",
  REGULATION_SUCCESSFULLY_ADDED: "Regulation Successfully Added",
  SHOW_MORE: "Show More...",
  SHOW_MORE_DETAIL: "Show More Detail",

  REGULATION_DETAILS: "Regulation Details",
  DOCUMENTS_REQUIRED: "Document Required",
  CONTROL_LEVEL: "Control Level",

  SELECT_QUESTIONNAIRES: "Select Questionnaires",
  ASSIGN_ASSESSMENT_CAP: "ASSIGN ASSESSMENT",
  ASSIGN_VENDOR_ASSESSMENT: "ASSIGN VENDOR ASSESSMENT",
  SELECT_SUPPLIER_TO_ASSIGN_TO: "Select Supplier to Assign to",
  SELECT_SUPPLIER_SPOC_TO_ASSIGN_TO: "Select Supplier SPOC to Assign to",
  SELECT_SUBMISSION_DEADLINE_DATE: "Select Submission Deadline Date",
  ASSIGN_ASSESSMENT_SUCCESS: "Assign Assessment Successfully Done",

  QUESTIONNAIRES_REQUIRED: "Questionnaire is Required",
  SUPPLIER_ASSIGN_REQUIRED: "Supplier to Assign to is Required",
  PRIORITY_REQUIRED: "Priority is Required",
  SUPPLIER_SPOC_ASSIGN_REQUIRED: "Supplier SPOC to Assign to is Required",
  SUBMISSION_DATE_REQUIRED: "Submission Date is Required",

  SUMMARY: "Summary",
  SUMMARY_CAPS: "SUMMARY",
  GRAPH: "GRAPH",
  COMPARE: "Compare",
  NO_OF_COMPLETE_MATCHES_FOUND: "No. Complete Of Matches Found",
  NO_OF_HIGH_MATCHES_FOUND: "No. of High Probability Matches",
  NO_OF_LOW_MATCHES_FOUND: "No. of Low Probability Matches",
  NO_OF_NO_MATCHES_FOUND: "No. Of No Matches Found",

  SENSITIVITY: "SENSITIVITY",
  THRESHOLD: "THRESHOLD",
  CHANGE_SETTINGS: "CHANGE SETTINGS",
  SENSITIVITY_SETTINGS: "Sensitivity Settings",

  BASE_POLICY: "Base Policy",
  TARGET_POLICY: "Target Policy",

  NO_OF_PAGES: "No. Of Pages",
  NO_OF_ROWS: "No. Of Rows",

  NEXT: "Next",
  NEXT_CAPS: "NEXT",
  PREV: "Prev",
  PREV_CAPS: "PREV",
  INCLUDE_CAPS: "INCLUDE",
  EXCLUDE_CAPS: "EXCLUDE",
  BACK_TO_CONTENT: "Back To Content",
  SIMILAR_SENTENCES: "Similar Sentences",

  RULES_CONDITION: {
    IS_EQUAL_TO: "is equal to",
    IS_NOT_EQUAL_TO: "is not equal to",
    IS_GREATER_THAN: "is greater than",
    IS_LESS_THAN: "is less than",
    STARTS_WITH: "starts with",
    ENDS_WITH: "ends with",
    CONTAINS: "contains",
    DOESNT_CONTAIN: "doesn't contain",
    IS_EMPTY: "is empty",
    IS_NOT_EMPTY: "is not empty",
  },

  INTEGER: "Integer",
  TEXT: "Text",
  DATE: "Date",
  BOOLEAN: "Boolean",
  SAVE_RULES: "Save Rules",
  TEST_RULES: "Test Rules",

  PAGE_NO: "Page",
  ROW_NO: "Row",
  SENTENCE_TYPE: "Type",
  SEMANTIC_SCORE: "Score",
  SENTENCE: "SENTENCE",

  EUCLIDEAN:
    "Euclidean Distance represents the smallest distance between each pair of points. In other words, it is the shortest path to go from point A to point B(2-D Cartesian coordinate system). It is useful to use this formula whenever you want to compute the distance between 2 points in the absence of obstacles on the pathway. Euclidean distance fails to give us useful information is when path follows the curvature, not a straight line. Moreover the angular similarity is not calculated which is important in case of vectors.",
  COSINE:
    "Cosine similarity formula would compute the difference between the two documents in terms of directions and not magnitude. In many text similarity applications it is found that there is a huge difference in the length of two documents which may create a problem for other metrics but since cosine uses the direction only hence the magnitude(length of document calculated in number of words) would not be a problem. Moreover cosine distance tells the relative distance between two vectors.",
  JACCARD:
    "Jaccard Similarity is just based on element occurrences. Its mostly used when we have labels and have to be matched rather than synonyms of labels. Since in natural language a word is identified by its semantics which is captured by the neighboring words this similarity metric fails in capturing that context.",

  NO_MATCH: "No Match",
  MATCH: "Match",
  LOW_PROB: "Low Prob",
  LOW_PROBABLE_MATCH: "Low Probable Match",
  HIGH_PROB: "High Prob",
  HIGH_PROBABLE_MATCH: "High Probable Match",
  COMPARISON_GRAPH: "Comparison Graph",
  NO_CONTENT_EXTRACTED: "No Content Extracted",

  DOCUMENT: "Document",
  DOCUMENT_NAME: "Document Name",
  TABLE_OF_CONTENTS: "Table Of Contents",
  PAGES_OF_EXTRACT: "Pages Of Extract",

  CONFIRM: "CONFIRM",
  PLEASE_ENTER_YOUR_REGISTERED_USERNAME:
    "Please enter your registered Username",
  TEMPORARY_PASSWORD_REQUIRED: "Temporary Password Required",

  USERNAME_REQUIRED: "UserName is Required",

  LEVEL: "Level",

  COMPARE_SECTION_TO_SECTION: "Compare Section to Section",
  COMPARE_WHOLE_DOCUMENT: "Compare Whole Document",
  THRESHOLD_FOR_COMPARING_HEADING: "Threshold For Comparing Headings",

  TEMPORARY_PASSWORD_SET_TO: "Temporary Password Set To",
  POLICY_META_DATA: "Policy Meta Data",
  POLICY_EXTRACTION_PARAMETERS: "Policy Extraction Parameters",
  NO_EXTRACTION_PARAMETERS_AVAILABLE: "No Extraction Parameters Available",
  START_PAGE: "Start Page",
  END_PAGE: "End Page",
  NORMAL_TEXT: "Normal Text",
  TITLES_LIST: "Titles List",
  FOOTER_TEXT: "Footer Text",
  HEADER_TEXT: "Header Text",
  COMPARISON_META_DATA: "Comparison Meta Data",
  EDIT_COMPARISON: "Edit Comparison",
  POLICY_META_DATA_NOT_AVAILABLE: "Policy Meta Data Not Available",
  COMPARISON_META_DATA_NOT_AVAILABLE: "Comparison Meta Data Not Available",

  REJECTED: "Rejected",
  ACCEPTED_SMALL: "Accepted",
  PENDING_CLARIFICATION: "Pending Clarification",
  DISCARD: "Discarded",
  NOT_PROCESSED: "Not Processed",
  KEYWORDS_VECTORIZED: "Keywords Vectorized",
  AUTO_REJECT_SCORE: "Auto Reject Score",
  AUTO_ACCEPT_SCORE: "Auto Accept Score",
  AUTO_DISCARD_SCORE: "Auto Discard Score",
  DOWNLOAD_BASE_DOCUMENT: "Download Base Document",
  DOWNLOAD_TARGET_DOCUMENT: "Download Target Document",
  APPLY: "Apply",
  KEYWORDS_NGRAM: "Keywords Ngram",
  ENTITIES: "Entities",
  SHOW_TEXT: "Show Text",
  COMPARISON_COUNT: "Comparison Count",
  ARCHIVED: "Archived",
  THRESHOLD_SMALL: "Threshold",
  NOT_AVAILABLE: "Not Available",
  DOCUMENT_INFORMATION: "Document Information",
  DOCUMENT_LANGUAGE: "Document Language",
  ADDED_SUCCESSFULLY_TO_ARCHIVED: "Added Successfully To Archived",
  REMOVED_SUCCESSFULLY_FROM_ARCHIVED: "Removed Successfully From Archived",
  DOCUMENT_OWNER: "Document Owner",
  DOCUMENT_TYPES: "Document Types",
  ADD_DOCUMENT_TAGS: "Add Document Tags",
  DOCUMENT_TAGS: "Document Tags",
  DOCUMENT_UPLOAD: "Document Upload",
  PREPROCESS_YOUR_PDF: "Pre-Process Your PDF",
  COMPARISON_ACTIONS: "Comparison Actions",
  KEYWORDS_FILTER: "Keywords Filter",
  EXTRACTED_POLICY_DOCUMENT: "Extracted Policy Document",
  PLEASE_ADD_VALUE_FOR_AUTO_DECISION: "Please Add Value For Auto Decision",
  NONE: "None",
  COMPARISON_ANALYSIS: "Comparison Analysis",
  LANGUAGE: "Language",
  SOURCE_AND_TARGET_COMPARISON_LANGUAGE_NEED_TO_BE_THE_SAME:
    "Source and Target Comparisons language need to be the same",
  PREPROCESSED_PDF: "Pre-Processed PDF",

  // Configuration Management => EVIDENCE TYPES

  EVIDENCE_SHORT_NAME: "Evidence Short Name",
  EVIDENCE_DESCRIPTION: "Evidence Description",
  EVIDENCE_SOURCE: "Evidence Source",
  CLASSIFICATION: "Classification",
  KEYWORD_DICTIONARY: "Keyword Dictionary",
  EVIDENCE_TYPE_ID: "Evidence Type ID",
  UPLOAD_FILE_NOTE:
    "Select the Evidence type for which you want to upload document to extract keywords",
  SAMPLE_FILE_UPLOADED: "Sample File Uploaded",
  KEYWORDS_EXTRACTED: "Keywords Extracted",
  EXTRACTED_KEYWORDS_NOTE:
    "Please find all the extracted keywords along with the count of no. of occurence in sorted in ascending order",
  DECISION_SUCCESSFULLY_UPDATE: "Decision Successfully Update",
  CHANGE_STATUS: "Change Status",
  SENTENCE_FILTER: "Sentence Filter",
  TOP_2_SENTENCES: "Top 2 Sentences",
  TOP_5_SENTENCES: "Top 5 Sentences",
  ALL_SENTENCES: "All Sentences",
  STATUS_SM: "status",
  PROGRESS_SM: "progress",
  COMPLETED_SM: "completed",
  COMPARISON_DETAIL: "Comparison Detail",
  NAME: "Name",
  FILE_NAME: "File Name",
  ACTIVE: "Active",
  ACTIVE_CAPS: "ACTIVE",
  INACTIVE: "Inactive",
  INACTIVE_CAPS: "INACTIVE",

  SPEED_READING: "Speed Reading",
  KEYWORDS_PHRASES: "Keywords Phrases",
  IMPORTANT_ENTITIES: "Important Entities",
  SELECT_DATE_RANGE: "Select Date Range",

  VIEW_QUESTIONS: "View Questions",
  SIMILAR_QUESTIONS: "Similar Questions",
  CONTROLS: "Controls",
  NARATTIVE: "Narrative",

  COMPLETE_AUDIT: "COMPLETE AUDIT",
  OBSERVATION: "Observation",
  CONTROL_OWNER: "Control Owner",
  POLICY_INSERTION_FAILED: "Policy Insertion Failed",
  EXTRACTION_PROGRESS: "Extraction Progress",
  EXTRACTION_STARTED: "Extraction Started",
  EXTRACTION_COMPLETED: "Extraction Completed",
  EXTRACTION_IN_PROGRESS: "Extraction In Progress",
  PROGRESS_STATUS: "Progress Status",
  SHOW_PROGRESS: "Show Progress",
  EXTRACTION_FAILED: "Extraction Failed",
  UPLOAD_ONLY: "Upload Only",
  LOAD_MORE: "Load More",
  SET_FILTERS: "Set Filters",
  SET_FILTERS_CAPS: "SET FILTERS",
  EXTRACTION_STATUS: "Extraction Status",
  CHANGE_VIEW: "Change View",
  ADVANCE_SEARCH: "Advance Search",
  PAGE_NUMBER: "Page Number",
  REGULATION: "Regulation",
  ASSESSMENT_TAG: "Assessment Tag",
  APPROVERS: "Approvers",
  IS_CURRENTLY_WORKING_ON: "is currently working on",
  SELECT_PERSON: "Select Person",
  SELECT_DELEGATE: "Select Delegate",
  SAVED: "Saved",
  UPDATE: "Update",
  UPDATE_BUTTON: "UPDATE",
  ENTER_KEYWORDS_TO_SEARCH_POLICY: "Enter keywords to search policy",
  SCORE: "Score",
  BY_TAG: "By Tag",
  BY_TEXT: "By Text",
  COMPARISON_PROGRESS: "Comparison Progress",
  COMPARISON_STATUS: "Comparison Status",
  DOCUMENT_DETAILS: "Document Details",
  PRE_PROCESSED_WARN_TEXT:
    "Pre-processing your PDF is not recommended to if the file contains text columns or graphics.",
  PAGES_OF_EXTRACTION: "Pages of Extraction",
  STEP_DOCUMENT_DETAILS: "1. DOCUMENT DETAILS",
  STEP_EXTRACTION_DETAILS: "2. EXTRACTION DETAILS",
  STEP_PREVIEW_AND_SAVE: "3. PREVIEW & SAVE",
  DOCUMENT_CONTENT_IDENTIFIER: "DOCUMENT CONTENT IDENTIFIER",
  COUNT_OF_MATCHES_INDENTIFIED: "Count of matches identified",
  SAMPLE_TEXT_FROM_PAGES: "Sample text from pages",
  ADD_SUB_CATEGORY: "Add Sub Category",
  ADD: "Add",
  REMOVE: "Remove",
  COMPARE_WITH_ORIGINAL: "COMPARE WITH ORIGINAL",
  COMPARE_WITH_PROCESSED_FILE: "COMPARE WITH PROCESSED FILE",
  HELP: "Help",
  COMPARE_ORIGINAL_AND_PRE_PROCESSED_FILE:
    "COMPARE ORIGINAL & PRE-PROCESSED FILE",
  SWITCH_TO_ORIGINAL: "SWITCH TO ORIGINAL",
  CONTINUE_WITH_PRE_PROCESSED_FILE: "CONTINUE WITH PRE-PROCESSED FILE",
  BACK: "BACK",
  FIND_AND_REPLACE: "Find & Replace",
  FIND: "Find",
  REPLACE: "Replace",
  INSTANCES_FOUND: "Instances Found",
  SELECT_QUESTION_CLASSIFICATION: "Select Question Classification",
  QUESTION_CLASSIFICATION: "Question Classification",
  REGULATOR_CONTROL_GROUP: "Regulator Control Group",
  REGULATOR_CONTROL_GROUP_UPDATED: "Regulator Control Group Updated",
  QUESTION_CLASSIFICATION_UPDATED: "Question Classification Updated",
  SELECT_REGULATOR_CONTROL_GROUP: "Select Regulator Control Group",
  SEARCH: "Search",
  ADD_COMMENTS: "Add Comments",
  COMMENTS: "Comments",
  EXPIRY_DATE: "Expiry Date",
  ADD_EXPIRY_DATE: "Add Expiry Date",
  PLEASE_SELECT_EVIDENCE_FILE: "Please Select Evidence File",
  PLEASE_ENTER_COMMENTS: "Please Enter Comments",
  SHOW_REMARKS: "Show Remarks",
  APPROVE: "Approve",
  SHOW_APPROVERS: "Show Approvers",
  EVIDENCE_STATUS_UPDATED: "Evidence Status Updated",
  FILTER_BY_EVIDENCE_STATUS: "Filter By Evidence Status",
  AUDIT_INFORMATION: "Audit Information",
  SELECT_NO_OF_RECORDS: "Select No Of Records",
  MANAGE_ASSESSMENTS: "Manage Assessments",
  DETAILS: "Details",
  EVIDENCE_MAPPING: "Evidence Mapping",
  EVIDENCE_TYPES: "Evidence Types",
  INSIGHT: "Insight",
  SCATTER_VIEW: "Scatter View",
  STACKED_VIEW: "Stacked View",
  HEATMAP: "Heatmap",
  DECISION_HEATMAP: "Decisions Heatmap",
  ENTER_NAME_FOR_COMPARISON: "Enter Name For Comparison",
  COMPARISON_SUMMARY: "COMPARISON SUMMARY",
  POLICY_INSIGHTS: "POLICY INSIGHTS",
  ADD_NEW_RULE_BOOK: "Add New Rule Book",
  SELECT_REGULATOR: "Select Regulator",
  SELECT_REGULATION: "Select Regulation",
  ADD_NEW_QUESTION: "ADD NEW QUESTION",
  putNewQuestion_Failure: "Put New Question Service Failed",
  ADD_NO_BETWEEN_1_TO_1000: "Add Number Between 1 to 1000",
  ENTER_NUMBER_OF_QUESTIONS_TO_ADD: "Enter number of questions to add",
  NOT_ABLE_TO_FIND_QUESTION_REFRENCE: "Not Able To Find Question Refrence",
  PLEASE_REMOVE_VIRUS_FILE: "Please Remove Virus File",
  CURRENT_STATUS: "Current Status",
  BASE_ID: "Base ID",
  TARGET_ID: "Target ID",
  EXTRACTION_PARAMETER: "Extraction Parameter",
  QUESTION_STATUS_UPDATED: "Question Status Updated",
  QUESTION_IS_ALREADY_IN_LIVE_ASSESSMENT:
    "Question is already in live Assessment' Do you wish to amend the Status of that question in the assessment?",
  YES: "YES",
  NO: "NO",
  ONE_DAY: "1 Day",
  FIVE_DAY: "5 Day",
  TEN_DAY: "10 Day",
  TWENTY_DAY: "20 Day",
  ONE_MONTH: "1 Month",
  THREE_MONTH: "3 Month",
  SIX_MONTH: "6 Month",
  EIGHTEEN_MONTH: "18 Month",
  ONE_YEAR: "1 Year",
  TWO_YEAR: "2 Year",
  EXPIRY_PERIODICITY: "Expiry Periodicity",
  SUBMIT_TO_NEXT_APPROVER: "Submit to Next Approver",
  APPROVAL_ORDER: "Approval Order",
  PRIMARY_APPROVER: "Primary Approver",
  DELEGATE_APPROVER: "Delegate Approver",
  APPROVAL_STATUS: "Approval Status",
  APPROVAL_DATE: "Approval Date",
  INTERNAL_OR_EXTERNAL: "Internal OR External",
  SEND_EMAIL: "Send Email",
  SUCCESS: "Success",
  SELECT_APPROVER_FROM_LIST: "Select Approver From List",
  ASSESSMENT_APPROVER_HAS_BEEN_ASSIGNED_SUCCESSFULLY:
    "Assessment approver has been assigned successfully",
  UPLOAD_DOCUMENT: "Upload Document",
  UPLOADED_DOCUMENT: "Uploaded Document",
  RERUN_CLASSIFICATION: "ReRun Classification",
  DOCUMENT_CLASSIFICATION_INPROGRESS: "Document Classification InProgress",
  FAILED: "Failed",
  INCOMPLETE: "Incomplete",
  FETCHING_DOCUMENT_CLASSIFICATION: "Fetching Document Classification",
  EVIDENCE_COUNT: "Evidence Count",
  DOCUMENT_EXPLORER_VIEW: "Document Explorer View",
  DOCUMENT_OWNER_VIEW: "Document Owner View",
  DOCUMENT_TYPE_VIEW: "Document Type View",
  UPLOADED_BY_VIEW: "Uploaded By View",
  UNARCHIVED: "UnArchived",
  CHAT: "Chat",
  POLICY_PREVIEW: "Policy Preview",
  HEADERS: "Headers",
  POLICY_LANGUAGE: "Policy Language",
  RELEASE_DATE: "RELEASE DATE",
  FEATURE_CHANGE: "FEATURE / CHANGE",
  NEW_RELEASE: "NEW RELEASE",
  HISTORY: "HISTORY",
  CLOSE: "CLOSE",
  MARK_AS_READ: "Mark As Read",
  HOW_CAN_I_HELP_YOU: "How Can I Help You ?",
  INVALID_PROMPT:
    "You have entered some keywords which are not permitted according to your organisations policy. Please try again.",
  COMPARISON_OVERVIEW: "Comparison Overview",
  CHUNKING_STATUS: "Chunking Status",
  SECURITY: "Security",
  ENABLE_2FA_AUTHENTICATION: "Enable 2FA Authentication",
  AUTHENTICATION_TYPE: "Authentication Type",
  POLICY_MAPPING: "Policy Mapping",
  SELECT_POLICY_TO_MAP: "Select Policy To Map",
  POLICY_OWNER: "Policy Owner",
  DOCUMENT_TYPE: "Document Type",
  POLICY_DETAILS: "Policy Details",
  CREATE_USER: "Create User",
  USERNAME: "UserName",
  DEPARTMENT_NAME: "Department Name",
  COUNTRY: "Country",
  ROLE: "Role",
  PASSWORD: "Password",
  FIRSTNAME: "FirstName",
  LASTNAME: "LastName",
  ENTITLEMENT: "Entitlement",
  SECURITY_AND_TEAMS: "Security & Teams",
  ASSIGN_TEAM: "Assign Team",
  NOTIFICATION_PREFERENCES: "Notification Preferences",
  POLICY_MAPPING_VIEW: "Policy Mapping View",
  POLICY_MAPS: "Policy Maps",
  SENTENCE_MAPPING: "Sentence Mapping",
  PLEASE_SELECT_HEADERS: "Please Select Headers",
  NETWORK_GRAPH: "Network Graph",
  SOURCE_QUESTIONS: "Source Questions",
  RELATIONSHIP_STRENGTH: "Relationship Strength",
  NO_DATA_FOUND: "No Data Found",
  COMPARISON_CHART: "Comparison Chart",
  SELECT_CUSTOM_RANGE: "Select Custom Range",
  SOURCE_SUB_QUESTIONS: "Source Sub Question",
  NETWORK_SETTINGS: "Network Settings",
  RELATIONSHIP_SETTINGS: "Relationship Settings",
  QUESTION_SETTINGS: "Question Settings",
  GENERATE_GRAPH: "Generate Graph",
  CUSTOMER_LEGAL_ENTITIES: "Customer & Legal Entities",
  CONFIGURATIONS: "Configurations",
  REGULATORS_AND_REGULATIONS: "Regulators and Regulations",
  ENTITLEMENT_LABELS: "Entitlement Labels",
  SOURCE_SYSTEMS: "Source Systems",
  SUBSCRIPTIONS: "Subscriptions",
  SELECT_CUSTOMER: "Select Customer",
  CUSTOMER_NAME: "Customer Name",
  CUSTOMER_ID: "Customer ID",
  CREATE_CUSTOMER: "Create Customer",
  LEGAL_ENTITY_NAME: "Legal Entity Name",
  LEGAL_ENTITY_TYPE: "Legal Entity Type",
  LEGAL_ENTITY_ADDRESS: "Legal Entity Address",
  RESPONSIBLE_DEPARTMENT: "Responsible Department",
  CONTRACT_DURATION: "Contract Duration",
  PRODUCT_SERVICE_PROVIDED: "Product Service Provided",
  COUNTRY_CODE: "Country Code",
  CREATE_LEGAL_ENTITY: "Create Legal Entity",
  UPDATE_LEGAL_ENTITY: "Update Legal Entity",
  TYPE: "Type",
  ADDRESS_TEXT: "Address",
  LEGAL_ENTITY_FORM: "Legal Entity Form",
  ENTITLEMENT_NAME: "Entitlement Name",
  ENTITLEMENT_LABEL: "Entitlement Label",
  ENTITLEMENT_LABEL_LEVEL: "Entitlement Label Level",
  ENTITLEMENT_PARENT: "Entitlement Parent",
  ENTITLEMENT_PATH: "Entitlement Path",
  ENTITLEMENT_TOOLTIP: "Entitlement Tooltip",
  CUSTOMER_DEFAULT: "Customer Default",
  ENTITLEMENT_ICON: "Entitlement Icon",
  SELECT_ACTIVE_ICON: "Select Active Icon",
  SELECT_INACTIVE_ICON: "Select InActive Icon",
  ACTIVE_ICON: "Active Icon",
  INACTIVE_ICON: "InActive Icon",
  SELECT_ENTITLEMENT_NAME: "Select Entitlement Name",
  SELECT_ENTITLEMENT_PARENT: "Select Entitlement Parent",
  CREATE_ENTITLEMENT_LABEL: "Create Entitlement Label",
  UPDATE_ENTITLEMENT_LABEL: "Update Entitlement Label",
  SELECTED_ENTITLEMENT: "Selected Entitlement",
  BACK_TO_ENTITLEMENT_LABELS: "Back to Entitlement Labels",
  ADD_ENTITLEMENT_LABEL: "Add Entitlement Label",
  DOMAIN: "Domain",
  APPLICABLE_FLAG: "Applicable Flag",
  EFFECTIVE_DATE: "Effective Date",
  REGULATION_STATUS: "Regulation Status",
  MAP_REGULATOR: "Map Regulator",
  UPDATE_REGULATOR_DETAILS: "Update Regulator Details",
  UPDATE_REGULATION_DETAILS: "Update Regulation Details",
  EDIT_REGULATOR: "Edit Regulator",
  ASSIGN_REGULATIONS: "Assign Regulations",
  CONTENT: "Content",
  CONTENT_CAPS: "CONTENT",
  FAQ: "FAQ",
  NO_CONTENT_FIND_IN: "No content found in",
  CUSTOMER_CONFIGURATION: "Customer Configuration",
  THEME_CUSTOMISATION: "Theme Customisation",
  PRIMARY_LOGO: "Primary Logo",
  LOGO: "Logo",
  DEFAULT_LANDING_ZONE: "Default Landing Zone",
  DEFAULT_DOCUMENT_URL: "Default Document URL",
  REPORTING_URL: "Reporting URL",
  DEFAULT_PM_URL: "Default PM URL",
  PREPROCESSING_URL: "PreProcessing URL",
  PREPROCESSING_IP: "PreProcessing IP",
  LICENCE_KEY: "Licence Key",
  SAVE_KEY: "Save Key",
  OK: "OK",
  NEW_LICENCE_KEY: "New Licence Key",
  LICENCE_EXPIRY_DATE: "Licence Expiry Date",
  REQUEST_LICENCE_KEY: "Request Licence Key",
  LICENCE_KEY_DETAILS: "Details for the Licence Key",
  LICENCE_RENEWAL_REQUEST: "Licence Renewal Request",
  LICENCE_TYPE: "Licence Type",
  LICENCE_REQUEST_EMAIL_TO: "licence_request@regulativ.ai",
  LICENCE_KEY_VERIFICATION_ERROR_MESSAGE:
    "Login cannot be complete as the Licence key is not valid. Please contact your system administrator.",
  LOGIN_TIMEOUT: "Login Timeout",
  PASSWORD_RESET_EMAIL_ADDRESS: "Password Reset Email Address",
  POLLING_TIMER: "Polling Timer",
  MULTI_PROCESSING: "Multi Processing",
  WORKFLOW_URL: "Workflow URL",
  COMP_THRESHOLD_DEFAULT: "Comp Threshold Default",
  AWS_ACCESS_KEY: "AWS Access Key",
  AWS_SECRET_ACCESS_KEY: "AWS Secret Access Key",
  AI_ASSISTANT_AUDITLOG_ACTIVE: "AI Assistant Auditlog Active",
  AUDITLOG_RETENSION_IN_DAYS: "Auditlog Retension In Days",
  TWOFA_ENABLED: "2FA Enabled",
  TWOFA_SERVICE_NAME: "2FA Service Name",
  VIRUS_CHECKER: "Virus Checker",
  TOPBAR_BACKGROUND: "TopBar Background Colour",
  MENUBAR_BACKGROUND: "MenuBar Background Colour",
  ACTIONBUTTON_BACKGROUND: "ActionButton Background Colour",
  TABLE_HEADER: "Table Header Colour",
  FONT_COLOUR_LIGHT: "FONT Colour Light",
  FONT_COLOUR_DARK: "Font Colour Dark",
  UPDATE_DATE: "Update Date",
  SANCTION_KEYWORD: "Sanction Keyword",
  DOCUMENT_CLASSIFICATION: "Document Classification",
  CLASSIFICATION_NAME: "Classification Name",
  ORGANIZATION_MANAGEMENT: "Organization Management",
  PLEASE_SELECT_CUSTOMER_FIRST: "Please Select Customer First",
  SENCTION_KEYWORDS: "Senction Keywords",
  KEYWORD: "Keyword",
  ID: "Id",
  QUESTION_CLASSIFICATION_NAME: "Question Classification Name",
  DOCUMENT_CLASSIFICATION_NAME: "Document Classification Name",
  ORG_UNIT_COST_CENTER: "Unit Cost Center",
  ORG_UNIT_REFERENCE: "Unit Reference",
  PARENT_ORG_UNIT_ID: "Parent Organization Unit",
  ORG_UNIT_OWNER: "Unit Owner",
  ORG_UNIT_LEVEL: "Unit Level",
  ORG_UNIT_PURPOSE: "Unit Purpose",
  ORG_UNIT_NAME: "Unit Name",
  CISO_CONTROLER: "CISO Controler",
  PARENT_ORGANIZATION: "Parent Organization",
  SCAN_QR_CODE_TO_GET_OTP: "Scan QR Code To Get OTP",
  PLEASE_CHECK_AUTHENTICATOR_APP_FOR_OTP:
    "Please Check Authenticator App For OTP",
  BACK_TO_LOGIN: "Back To Login",
  SIGN_IN: "Sign In",
  FORGOT_PASSWORD: "Forgot password?",
  SERVICE_CONNECTIONS: "Service Connections",
  ENTER_PASSWORD: "Enter Password",
  ENTER_PASSWORD_TO_GENERATE_QR: "Enter Password To Generate QR Code*",
  SERVICE_CONNECTION: "Service Connection",
  SERVICE_URL: "Service URL",
  USER_CREDENTIALS: "User Credentials",
  CLICK_BUTTON_TO_GENERATE_QR_CODE: "Click Button To Generate QR Code*",
  GENERATE_QR_CODE: "Generate QR Code",
  GENERATE_QR: "Generate QR",
  SCAN_THIS_QR_FROM_AUTHENTICATOR_APP: "Scan This QR From Authenticator App*",
  ADD_HELP_TOPIC: "Add Help Topic",
  EDIT_HELP_TOPIC: "Edit Help Topic",
  TOPIC_GROUP: "Topic Group*",
  TOPIC_NAME: "Topic Name*",
  TOPIC_CONTENT: "Topic Content*",
  TOPIC_URL: "Topic URL",
  TOPIC_TYPE: "Type*",
  CONFIRMATION_TO_DELETE:
    "Are you sure you want to remove the selected content?",
  VALIDATE_SANCTION_KEYWORDS_MESSAGE:
    "Restricted words found as per Company Policy. Please amend to proceed.",
  VIEW_QR_CODE: "View QR Code",
  USERS_AND_TEAMS: "Users & Teams",
  ROLE_NAME: "Role Name",
  DEPARTMENT: "Department",
  TWOFA_TYPE: "2FA Type",
  PARENT_TEAM_NAME: "Parent Team Name",
  ANNUAL_BUDGET: "Annual Budget",
  CITY: "City",
  SELECT_COUNTRY: "Select Country",
  TEAMS: "Teams",
  USERS: "Users",
  SELECT_LEGAL_ENTITY: "Select Legal Entity",
  SELECT_PARENT_TEAM: "Select Parent Team",
  UPDATE_USER: "Update User",
  SUPERVISOR: "Supervisor",
  SUPERVISOR_NAME: "Supervisor Name",
  RFI_RECIPIENT: "RFI Recipient",
  ENTER_KEYWORDS_TO_SEARCH_USERS: "Enter Keywords To Search Users",
  OBJECTIVES: "Objectives",
  GUIDANCE: "Guidance",
  ARCHITECTURE: "Architecture",
  SELECT_QUESTION_TYPE: "Select Question Type",
  GUIDANCE_TYPE: "GUIDANCE_TYPE",
  ENTER_KEYWORDS_TO_SEARCH_TEAMS: "Enter Keywords To Search Teams",
  SEND_REQUEST: "Send Request",
  DROP_HERE: "DROP HERE",
  PIN: "PIN",
  UNPIN: "UNPIN",
  EXPAND: "EXPAND",
  REMOVE_CAPS: "REMOVE",
  COLLAPSE: "COLLAPSE",
  ACTIONS_LIST_NAME: "Actions List Name",
  ASSIGN_TO: "Assign To",
  CLOSURE_DATE: "Closure Date",
  PRIORITY: "Priority",
  PLEASE_WAIT_FETCHING_DATA: "Please Wait... Fetching Data",
  PLEASE_SELECT_REGULATION_FIRST: "Please Select Regulation First",
  PLEASE_SELECT_REGULATORY_DOMAIN_COUNTRY_REGULATION_FIRST:
    "Please Select Regulatory Domain, Country and Regulation First",
  MARK_SELECTED_AS_READ: "Mark Selected As Read",
  SHOW_ALL: "Show All",
  CLEAR_FILTERS: "Clear Filters",
  TECHNOLOGY_AND_INFRASTRUCTURE: "Technology And Infrastructure",
  COMPANY: "Company",
  SELECT_PANELS_TO_SHOW: "Select Panels To Show",
  DASHBOARD_PANELS: "Dashboard Panels",
  EDIT_DATA_ASSET: "Edit Data Asset",
  ADD_NEW_DATA_ASSET: "Add New Data Asset",
  DATA_ENTITY_NAME: "Data Entity Name",
  DATA_ASSET_ATTRIBUTE_NAME: "Data Asset Attribute Name",
  DATA_ASSET_VALUE: "Data Asset Value",
  TAB_LABEL: "Tab Label",
  SEND_COPY_TO_ME: "Send copy to me",
  SERVICE_REQUEST_FORM: "Service Request Form",
  SUPPORT_REQUEST_FORM: "Support Request Form",
  SERVICE_REQUEST_RAISED_MESSAGE:
    "Thank you for your service request. The Service Support team will respond as soon as possible.",
  SUPPORT_REQUEST_RAISED_MESSAGE:
    "Thank you for your support request. The Production Support team will respond as soon as possible.",
  SERVICE_TYPE: "Service Type",
  SUPPORT_TYPE: "Support Type",
  SERVICE_REQUEST_EMAIL: "newservicerequest@regulativ.ai",
  SUPPORT_REQUEST_EMAIL: "newsupportrequest@regulativ.ai",
  ERROR_IN_FILE_STRUCTURE:
    'File is not correct Format or Structure. Header row must contain the following columns in this order "Evidence_Shortname, Evidence_Description, Regulatory_Domain"',
  LOGOUT_SERVICE_FAILURE: "Logout Service Failure",
  DOCUMENT_REQUIRED: "Document Required",
  AVAILABLE_STATUS: "Available Status",
  ASSESSMENT_EVIDENCE_STATUS: "Assessment Evidence Status",
  REQUEST_FOR_INFORMATION_STATUS: "Request For Information Status",
  ASSESSMENT_APPROVERS_STATUS: "Assessment Approvers Status",

  SUPPLIERS_BY_CATEGORY: "Suppliers By Category",
  SUPPLIER_STATUS: "Supplier Status",
  THIRD_PARTY_RISK_STATUS: "Third Party Risk Status",
  SUPPLIERS: "Suppliers",
  CONTRACT_STATUS: "Contract Status",
  TPSR: "TPSR",
  ASSIGNED_TO: "Assigned To",
  PENDING_FOR_REVIEW: "Pending for Review",
  ASSIGNED: "Assigned",
  IN_PROGRESS: "InProgress",
  COMPANY_DATA_AND_INFORMATION: "Company Data And Information",
  RISK_MANAGEMENT: "Risk Management",
  RAID: "RAID",
  COMPLIANCE_ACTIONS: "Compliance Actions",
  THREATS_AND_VULNERABILITIES: "Threats and Vulnerabilities",
  POLICY_AND_DOCUMENTATION: "Policy and Documentation",
  THIRD_PARTY_RISK: "Third Party Risk",
  BREACHES_AND_INCIDENTS: "Breaches and Incidents",
  ASSESSMENT_SUMMARY_STATUS: "Assessment Summary Status",
  ACTIVE_USERS: "Active Users",
  LIST_OF_ALL_CLIENTS_ON_ENVIRONMENT: "List of All Clients on Environment",
  SEND_NOTIFICATION: "Send Notification",
  CREATE_MESSAGE: "Create Message",
  ENTER_MESSAGE_BODY: "Enter Message Body",
  SEND_TO_SELECTED_CLIENTS: "Send to Selected Clients",
  CONFIRMATION_MESSAGE:
    "Are you sure you want to send message to ALL selected clients?",
  SELECT_CUSTOMER_FOR_NOTIFICATION:
    "Select atleast one customer to send notification message.",
  IN_APP: "In App",
  ATLEAST_SELECT_ONE_OPTION: "Select atleast one option in In App and Email.",
  SOURCE_SYSTEM_ID: "Source System ID",
  SOURCE: "Source",
  SOURCE_SYSTEM_NAME: "Source System Name",
  DATA_SOURCE_NAMES_AND_TYPES: "Data Source Names and Types",
  FILE_FORMAT: "File Format",
  FREQUENCY: "Frequency",
  DELIVERY_METHOD: "Delivery Method",
  DEDICATED_BUCKET_FOLDER_NAME: "Dedicated Bucket Folder Name",
  FILE_NAME_SYNTAX: "FileName Syntax",
  SCHEDULING_PERIOD: "Scheduling Period",
  DATA_FILE_DETAILS: "Data File Details",
  DATA_FILE_VALUES: "Data File Values",
  NAME_OF_SOURCE: "Name of Source",
  EXPECTED_FILE_FORMAT: "Expected File Format",
  FREQUENCY_OF_FILE: "Frequency of File",
  DESTINATION_FOLDER: "Destination Method",
  FILE_SYNTAX: "File Syntax",
  SCHEDULE: "Schedule",
  DATA_OWNER: "Data Owner",
  DATA_OWNER_EMAIL_ADDRESS: "Data Owner Email Address",
  SOURCE_DATA_DETAILS: "Source Data Details",
  DATA_ATTRIBUTE_DETAILS: "Data Attribute Details",
  BACK_TO_SOURCE_SYSTEMS: "Back to Source Systems",
  ADD_NEW_ATTRIBUTE: "Add New Attribute",
  ATTRIBUTE_NAME: "Attribute Name: ",
  NO_ATTRIBUTE_FOR_THE_SOURCE_FILE: "No attribute for the source file.",
  DATA_ATTRIBUTES_EXPECTED_IN_SOURCE_FILE:
    "Data Attributes Expected in Source File",
  USERLIST: "List of Users",
  MY_SUBSCRIPTIONS: "My Subscriptions",
  SELECT_SUBSCRIPTION_TYPE: "Select Subscription Type",
  SUBSCRIPTION_TYPE: "Subscription Type",
  LIST_OF_PACKAGES: "List of Packages",
  COST_OF_SUBSCRIPTION: "Cost of Subscription",
  BILLING: "Billing",
  ENTER_COUPON_CODE: "Enter Coupon Code",
  CONFIRM_AND_PAY: "Confirm & Pay",
  SELECT_SUBSCRIPTION: "Select Subscription",
  SELECT_PACKAGE: "Select Package",
  SUBSCRIPTION: "Subscription",
  NEW_SUBSCRIPTION: "New Subscription",
  REGGIE_IS_TYPING: "Reggie is typing",
  AUTOSAVE_CHAT_TO_MY_WORKBOOK: "Autosave chat to my workbook",
  START_THE_CHAT_AGAIN: "Start the chat again",
};
