// OP#2830 - UJA8 - UI Development - Manage Teams Profile
// services for Team Member

import apiService from "../../../_services/api.service";

const getTeamDetails = (userID) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetMyTeamDetails", {
      i_USER_ID: userID,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const updateUserProfile = (userData) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/UpdateUserProfileAdmin", userData)
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const updateUserAvatar = (userImage) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/UpdateUserAvatar", userImage)
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

export default {
  getTeamDetails,
  updateUserProfile,
  updateUserAvatar,
};
