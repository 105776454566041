import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import { Box } from "@material-ui/core";
import langConstant from "_lang";
import sessionService from "_services/session.service";
import { useDispatch, useSelector } from "react-redux";
import { GetAssessmentsForAuditorDetails } from "../services";
import MasterAssessment from "_components/MasterAssessmentComp/MasterAssessment";
import { setSteps, setActiveStep } from "_components/Stepper/action";

const steps = [
  { stepname: langConstant.COMPLETE_AUDIT, compID: 1 },
  { stepname: langConstant.SEND_FOR_REVIEW, compID: 2 },
];

const tabs = [
  { name: langConstant.ALL, count: 0, isCount: true, id: 0 },
  { name: langConstant.OBSERVATION, count: 0, isCount: false, id: 1 },
  { name: langConstant.REVIEWED, count: 0, isCount: true, id: 2 },
];

const ReviewScreenStatus = {
  All: "All",
  "Sent Back": "SENT_BACK",
  Reviewed: "FINAL",
};

const AuditAssessment = () => {
  const userID = sessionService.getUserId();
  const location = useLocation();
  const { rid, sid } = useParams();
  const dispatch = useDispatch();
  const [InitialTabs, setInitialTabs] = useState(tabs);
  const [IsDataLoaded, setIsDataLoaded] = useState(false);
  const questionList = useSelector(
    (state) => state.audit.AuditAssessmentDetails
  );
  const observationList = useSelector(
    (state) => state.audit.ObservationDetails
  );
  const trustScoreList = useSelector(
    (state) => state.audit.TrustScoreForAllQuestion
  );

  const DataSetter = async () => {
    return new Promise((resolve) => {
      setTimeout(async () => {
        setIsDataLoaded(false);
        dispatch(setSteps(steps));
        const temp = await dispatch(
          GetAssessmentsForAuditorDetails(sid, userID)
        );
        resolve("resolved");
        setIsDataLoaded(true);
      }, 0);
    });
  };

  useEffect(() => {
    (async () => {
      if (sid && userID) {
        await DataSetter();
      }
    })();
  }, [sid]);

  useEffect(() => {
    if (location?.state?.AssessmentStatus === "AUDITOR_REVIEWED") {
      dispatch(setActiveStep(1));
    }
  }, [location]);

  useEffect(() => {
    if (questionList.length > 0) {
      let reviewedCount = [...questionList].filter(
        (el) => el.QUESTION_STATUS === "AUDIT_REVIEWED"
      );

      let temptab = [
        {
          name: langConstant.ALL,
          count: questionList.length,
          isCount: true,
          id: 0,
        },
        {
          name: langConstant.OBSERVATION,
          count: observationList.length,
          isCount: true,
          id: 1,
        },
        {
          name: langConstant.REVIEWED,
          count: reviewedCount.length,
          isCount: true,
          id: 2,
        },
      ];
      setInitialTabs(temptab);
    }
  }, [questionList, observationList]);

  return (
    <Box>
      {questionList.length > 0 && IsDataLoaded === true && (
        <MasterAssessment
          TopTabsArray={InitialTabs}
          QuestionList={questionList}
          IsAudit={true}
          ReviewScreenStatus={ReviewScreenStatus}
          observationList={observationList}
          trustScoreList={trustScoreList}
          identifier="audit"
        />
      )}
    </Box>
  );
};

export default AuditAssessment;
