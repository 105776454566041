import React, { useEffect, useState } from "react";
import propTypes from "prop-types";
import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  CircularProgress,
  makeStyles,
  Box,
  Grid,
} from "@material-ui/core";
import { Close, Save } from "@material-ui/icons";
import PolicyServices from "../../../../PolicyExplorer/services";
import { useDispatch } from "react-redux";
import { alertActions } from "_actions";
import moment from "moment";
import SelectPageRange from "_components/SelectPageRange";
import AppPagination from "_components/AppPagination";

const styles = makeStyles(() => ({
  notesWrapper: { display: "flex", gap: 8, flexDirection: "column" },
  notesCard: {
    border: "1px solid #d5d5d5",
    borderRadius: "4px",
    padding: ".75rem",
    display: "flex",
    gap: 8,
    alignItems: "center",
  },
  flexOne: {
    flex: 1,
  },
  blankDataWrapper: { margin: "auto", padding: "1rem" },
  loading: { margin: "auto" },
  dialogTitle: { display: "flex", alignItems: "center", padding: ".75rem" },
  closeButton: { position: "absolute", right: 4 },
}));

const calculatePageCount = (rows, rowsPerPage) => {
  return rows?.length > 0
    ? Math.round(Math.ceil(rows.length / rowsPerPage))
    : 0;
};

export const ViewNotesDialog = ({ modal, data }) => {
  const dispatch = useDispatch();
  const classes = styles();
  const [isLoading, setIsLoading] = useState(true);
  const [notes, setNotes] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(
    calculatePageCount(notes, rowsPerPage)
  );

  const getNotes = async () => {
    const payload = {
      ...data,
      in_SUBMISSION_ID: null,
      in_QUESTION_ID: null,
      i_RFI_ID: null,
      i_POLICY_ID: null,
    };
    setIsLoading(true);
    await PolicyServices.getNotes(payload)
      .then((response) => {
        if (response?.status === 200) {
          setNotes(response?.["#result-set-2"]);
        } else {
          dispatch(
            alertActions.error(
              response?.data?.message?.map((item, index) => {
                return <span key={index}>{item}</span>;
              }) || "Something went wrong"
            )
          );
        }
      })
      .catch((err) => {
        console.log("error", err);
        setTimeout(() => {
          modal?.closeModal();
        }, 500);
        dispatch(alertActions.error(err?.toString() || "Something went wrong"));
      })
      .finally(() => setIsLoading(false));
  };

  const handleSelectPageRange = (range) => {
    setRowsPerPage(range.value);
    const totalPageCount =
      notes?.length > 0
        ? Math.round(Math.ceil(notes?.length / range?.value))
        : 0;
    if (page >= totalPageCount) {
      setPage(totalPageCount - 1);
    }
    setPageCount(totalPageCount);
  };

  const handleChangePage = (event) => {
    setPage(event);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    getNotes();
  }, []);

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      onClose={modal?.closeModal}
      open={modal?.isOpen}
      disableEscapeKeyDown
      disableEnforceFocus
    >
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Typography className={classes.flexOne} variant="h6" align="center">
          VIEW NOTES
        </Typography>
        <IconButton
          className={classes.closeButton}
          aria-label="close"
          onClick={modal?.closeModal}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div className={classes.notesWrapper}>
          {isLoading ? (
            <CircularProgress className={classes.loading} />
          ) : (
            <>
              {notes?.length ? (
                notes?.map((item, index) => (
                  <div className={classes.notesCard} key={item?.NOTES_ID}>
                    <span>{`#${index + 1}`}</span>
                    <span className={classes.flexOne}>{item?.NOTES_TEXT}</span>
                    <span>
                      {item?.CREATE_DATE && moment(item?.CREATE_DATE).fromNow()}
                    </span>
                  </div>
                ))
              ) : (
                <span className={classes.blankDataWrapper}>
                  No records found
                </span>
              )}
              <Box my={2}>
                <Grid container justify="flex-end">
                  {notes?.length > 5 && (
                    <SelectPageRange
                      selectedRange={rowsPerPage}
                      handleChange={handleSelectPageRange}
                    />
                  )}
                  {pageCount > 1 && (
                    <AppPagination
                      colSpan={3}
                      count={pageCount}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  )}
                </Grid>
              </Box>
            </>
          )}
        </div>
      </DialogContent>
      <DialogActions style={{ padding: "1rem" }}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={modal?.closeModal}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ViewNotesDialog.propTypes = {
  modal: propTypes.object,
  data: propTypes.object,
};
