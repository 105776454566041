// OP#2801 UJL3 - UI Dev - Manage User Profile
// file stands for user profile pop up (edit profile pop up)

import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Avatar,
  DialogActions,
  Grid,
  Switch,
  Typography,
} from "@material-ui/core";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { useDispatch, useSelector } from "react-redux";
import userServices from "../services";
import { alertActions, loadingActions } from "../../../../_actions";

import langConstant from "_lang";
import TempPassword from "./TempPassword";
import UnlockUser from "./UnlockUser";
import ChangeEntitlement from "./ChangeEntitlement";
import KeyIcon from "@mui/icons-material/Key";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import teamActions from "containers/cyber-assessment/ManageTeam/actions";
import sessionService from "_services/session.service";
import { globalConstants } from "_constants";

import useStyles from "./styles";
import { Drawer, Tab, Tabs } from "@mui/material";
import SecurityComponent from "./Security";
import BasicInformation from "./BasicInformation";
import NotificationTab from "./Notifications";
import CloseIcon from "@mui/icons-material/Close";
import ServiceConnections from "./ServiceConnections";
import { getEmailHtmlTemplate } from "_helpers/utils";

const ProfileDialog = ({
  show,
  setShow,
  userData,
  profilePic,
  setProfileDialog,
  countryData,
  setUserData,
  user_ID,
  isTeamView,
  domainList,
  notificationPreferences,
  twoFADetail,
  setNotificationPreferences,
  setTwoFADetail,
}) => {
  const classes = useStyles();
  const [isEditMode, setIsEditMode] = useState(false);
  const [tempPassPopUp, setTempPassPopUp] = useState(false);
  const [unlockUserPopUp, setUnlockUserPopUp] = useState(false);
  const [entitlementPopUp, setEntitlementPopUp] = useState(false);

  const dispatch = useDispatch();
  const loginUserID = sessionService.getUserId();
  const userTeam = useSelector((state) => state.manageTeamMember.teamMembers);

  const [tabValue, setTabValue] = useState(0);
  const handleChange = async (event, newValue) => {
    setTabValue(newValue);
  };

  const updateUserService = async (
    payload,
    message,
    mode,
    domainData = null
  ) => {
    dispatch(loadingActions.start());
    const response = await userServices.updateUserProfile(payload);

    dispatch(loadingActions.end());

    if (response) {
      if (response.status && response.status === 200) {
        if (
          response["#result-set-1"].length > 0 &&
          response["#result-set-1"][0]["rect"] === "pass"
        ) {
          dispatch(alertActions.success(message));

          if (mode === "userEdit") {
            setIsEditMode(false);
            const userCountry = countryData.find(
              (country) => country.value === payload.i_location
            );
            setUserData((prevState) => ({
              ...prevState,
              Name: payload.i_User_firstname + " " + payload.i_User_lastname,
              COUNTRY_CODE: userCountry.value,
              COUNTRY_SHORT_NAME: userCountry.label,
              DATA_CLASSIFICATION: payload.i_DataClassification,
            }));
            if (!isTeamView) {
              if (
                domainData &&
                domainData.i_USER_REGULATORY_DOMAIN_ID !==
                  domainData.i_REGULATORY_DOMAIN_ID
              ) {
                const newDomainData = {
                  i_USER_ID: domainData.i_USER_ID,
                  i_REGULATORY_DOMAIN_ID: domainData.i_REGULATORY_DOMAIN_ID,
                };

                dispatch(loadingActions.start());
                await userServices.updateUserDomain(newDomainData);
                dispatch(loadingActions.end());
              }

              const selectedDomainsStr = domainData.i_REGULATORY_DOMAIN_ID;
              const selectedDomainIdList = selectedDomainsStr.split(",");
              const userSelectedDomainsLabel = [];

              selectedDomainIdList.forEach((selectedDomainId) => {
                const userDomain = domainList.find(
                  (domain) => domain.value == selectedDomainId
                );
                if (userDomain) userSelectedDomainsLabel.push(userDomain.label);
              });

              setUserData((prevState) => ({
                ...prevState,
                REGULATION_DOMAIN: userSelectedDomainsLabel.join(", "),
                REGULATORY_DOMAIN_ID: selectedDomainIdList,
              }));
            }
            if (user_ID === parseInt(loginUserID)) {
              dispatch({
                type: globalConstants.UPDTAE_USER_NAME,
                data: payload.i_User_firstname + " " + payload.i_User_lastname,
              });
            }
            if (userTeam && userTeam.length) {
              let tempUserTeam = [...userTeam];
              const index = userTeam.findIndex(
                (team) => team.USER_ID === user_ID
              );
              tempUserTeam[index].USER_ID = payload.i_USER_ID;
              tempUserTeam[index].FIRST_NAME = payload.i_User_firstname;
              tempUserTeam[index].LAST_NAME = payload.i_User_lastname;
              dispatch(teamActions.setUserTeamMembers(tempUserTeam));
            }
          } else if (mode === "userStatus") {
            setUserData((prevState) => ({
              ...prevState,
              ACTIVE: !userData.ACTIVE,
            }));
            if (userTeam && userTeam.length) {
              let tempUserTeam = [...userTeam];
              const index = userTeam.findIndex(
                (team) => team.USER_ID === user_ID
              );
              tempUserTeam[index].ACTIVE = !userData.ACTIVE ? 1 : 0;
              dispatch(teamActions.setUserTeamMembers(tempUserTeam));
            }
          }
        } else {
          dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
        }
      } else {
        if (response.data && response.data.message) {
          dispatch(
            alertActions.error(
              response.data.message.map((item, index) => {
                return <div key={index}>{item}</div>;
              })
            )
          );
        } else {
          dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
        }
      }
    }
  };

  // const updateUserDetail = async (user) => {
  //     let name = user.Name
  //     let nameList = []
  //     name = name.split(' ');
  //     nameList.push(name.shift());
  //     nameList.push(name.join(' '));
  //     const payload = {
  //         i_USER_ID: user_ID,
  //         i_User_firstname: nameList[0],
  //         i_User_lastname: nameList[1],
  //         i_PhoneNumber: user.PHONE_NUMBER,
  //         i_departmentName: user.DEPARTMENT_NAME,
  //         i_EmailAddress: user.EMAIL_ADDRESS,
  //         i_location: user.COUNTRY_CODE,
  //         i_Active: userData.ACTIVE,
  //     }
  //     const domainData = {
  //         i_USER_REGULATORY_DOMAIN_ID: userData.USER_REGULATORY_DOMAIN_ID,
  //         i_USER_ID: user_ID,
  //         i_REGULATORY_DOMAIN_ID: user.REGULATORY_DOMAIN_ID
  //     }
  //     await updateUserService(payload, langConstant.USER_DETAILS_SUCCESSFULLY_UPDATED, "userEdit", domainData);
  // }

  const handleClose = () => {
    setShow(false);
    setIsEditMode(false);
  };

  const updateAccountStatus = async () => {
    const payload = {
      i_USER_ID: user_ID,
      i_ACCOUNT_LOCKED_STATUS: 0,
    };
    dispatch(loadingActions.start());
    const response = await userServices.updateUserAccountStatus(payload);
    dispatch(loadingActions.end());
    if (response) {
      if (response.status && response.status === 200) {
        if (
          response["#result-set-1"].length > 0 &&
          response["#result-set-1"][0]["update_status"] === "Pass"
        ) {
          setUserData((prevState) => ({
            ...prevState,
            ACCOUNT_LOCKED_STATUS: false,
          }));
          setUnlockUserPopUp(false);
          dispatch(
            alertActions.success(langConstant.USER_SUCCESSFULLY_UNLOCKED)
          );
        } else {
          dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
        }
      } else {
        if (response.data && response.data.message) {
          dispatch(
            alertActions.error(
              response.data.message.map((item, index) => {
                return <div key={index}>{item}</div>;
              })
            )
          );
        } else {
          dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
        }
      }
    }
  };

  function generatePassword() {
    var length = 16,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal.toUpperCase();
  }
  console.log(userData);

  const sendTempPassword = async () => {
    const newTempPassword = generatePassword();

    dispatch(loadingActions.start());
    const response = await userServices.updateUserTempPassword(
      newTempPassword,
      user_ID
    );
    dispatch(loadingActions.end());

    if (response) {
      if (response.status && response.status === 200) {
        const userObj = {
          TempPassword: newTempPassword,
          UserName: userData?.Name ?? "",
        };
        let templateData = {
          footer: "**This is an automated email. Do not reply to this email.**",
        };

        const htmlBody = getEmailHtmlTemplate(
          "Temporary_Password",
          userObj,
          {},
          templateData
        );

        // userData?.EMAIL_ADDRESS
        const emailResponse = await userServices.sendEmail(
          "",
          "",
          htmlBody,
          "Temporary Login",
          userData?.EMAIL_ADDRESS
        );
        if (
          emailResponse &&
          emailResponse.status &&
          emailResponse.status === 200
        ) {
          setTempPassPopUp(false);
          dispatch(alertActions.success(langConstant.TEMP_PASSWORD_GENERATED));
        } else {
          dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
        }
      } else {
        if (response.data && response.data.message) {
          dispatch(
            alertActions.error(
              response.data.message.map((item, index) => {
                return <div key={index}>{item}</div>;
              })
            )
          );
        } else {
          dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
        }
      }
    }
  };

  const updateUserActiveStatus = async () => {
    if (user_ID !== parseInt(loginUserID)) {
      let name = userData.Name;
      let nameList = [];
      name = name.split(" ");
      nameList.push(name.shift());
      nameList.push(name.join(" "));
      const payload = {
        i_USER_ID: user_ID,
        i_USER_ID2: user_ID,
        i_User_firstname: nameList[0],
        i_User_lastname: nameList[1],
        i_PhoneNumber: userData.PHONE_NUMBER,
        i_departmentName: userData.DEPARTMENT_NAME,
        i_DataClassification: userData.DATA_CLASSIFICATION,
        i_EmailAddress: userData.EMAIL_ADDRESS,
        i_location: userData.COUNTRY_CODE,
        i_Active: !userData.ACTIVE,
      };
      if (!userData.ACTIVE) {
        await updateUserService(
          payload,
          langConstant.USER_ACTIVATED,
          "userStatus"
        );
      } else {
        await updateUserService(
          payload,
          langConstant.USER_DEACTIVATED,
          "userStatus"
        );
      }
    }
  };

  return (
    <Drawer anchor={"right"} open={show} onClose={() => setShow(false)}>
      <Grid style={{ padding: "20px" }}>
        <Grid>
          <span className={classes.profileMDCloseBtn}>
            <CloseIcon onClick={handleClose} />
          </span>
          <Grid className={classes.profileMDTopHedaerMain}>
            <Grid className={classes.profileMDTopHedaer}>
              <div className={classes.profileUploadinlineBlock}>
                {profilePic ? (
                  <Avatar
                    className={classes.profileMDTopHedaerImg}
                    src={profilePic}
                  />
                ) : (
                  <Avatar className={classes.profileMDTopHedaerImg}>
                    {userData.Name[0]}
                  </Avatar>
                )}
                <Grid className={classes.profileHoverChnage}>
                  <span onClick={() => setProfileDialog(true)}>
                    <CameraAltIcon
                      style={{ width: "24px", height: "24px", color: "#fff" }}
                    />
                  </span>
                </Grid>
              </div>
              <Typography className={classes.profileMDTopHedaerCN}>
                <span className={classes.profileMDTopHedaerName}>
                  {userData.Name}
                </span>
                <Typography className={classes.profileMDTopHedaerRole}>
                  {userData.ROLE_NAME}
                </Typography>
              </Typography>
            </Grid>
            <div className={classes.profileMDTopHedaerToggleWrapper}>
              <Switch
                className={classes.profileMDTopHedaerToggleBtn}
                checked={userData.ACTIVE ? userData.ACTIVE : false}
                onClick={updateUserActiveStatus}
              />
              <Typography className={classes.profileMDTopHedaerToggleLabel}>
                {userData.ACTIVE ? "ACTIVE" : "INACTIVE"}
              </Typography>
            </div>
          </Grid>
          <Tabs
            value={tabValue}
            onChange={handleChange}
            TabIndicatorProps={{ style: { padding: 2 } }}
            orientation="horizontal"
            variant="scrollable"
          >
            <Tab
              aria-orientation="horizontal"
              label={langConstant.BASIC_INFORMATION}
            />
            <Tab aria-orientation="horizontal" label={langConstant.SECURITY} />
            <Tab
              aria-orientation="horizontal"
              label={langConstant.NOTIFICATIONS}
            />
            {!isTeamView && (
              <Tab
                aria-orientation="horizontal"
                label={langConstant.SERVICE_CONNECTIONS}
              />
            )}
          </Tabs>
          {tabValue === 0 && (
            <BasicInformation
              userData={userData}
              updateUserService={updateUserService}
              isTeamView={isTeamView}
              isEditMode={isEditMode}
              setIsEditMode={setIsEditMode}
              countryData={countryData}
              domainList={domainList}
              setEntitlementPopUp={setEntitlementPopUp}
              user_ID={user_ID}
            />
          )}
          {tabValue === 1 && (
            <SecurityComponent
              twoFADetail={twoFADetail}
              setTwoFADetail={setTwoFADetail}
              user_ID={user_ID}
              userData={userData}
              setUserData={setUserData}
              isTeamView={isTeamView}
            />
          )}
          {tabValue === 2 && (
            <NotificationTab
              notificationPreferences={notificationPreferences}
              setNotificationPreferences={setNotificationPreferences}
              user_ID={user_ID}
            />
          )}
          {tabValue === 3 && !isTeamView && <ServiceConnections />}
          {isTeamView && (
            <DialogActions className={classes.profiletemPassBTNwrapper}>
              <Button
                color="primary"
                variant="outlined"
                disabled={!userData.ACTIVE}
                onClick={() => setTempPassPopUp(true)}
              >
                <KeyIcon className={classes.sendTempPassIcon} />
                {langConstant.SEND_TEMPORARY_PASSWORD}
              </Button>
              {userData.ACCOUNT_LOCKED_STATUS ? (
                <Button
                  color="primary"
                  variant="outlined"
                  disabled={!userData.ACTIVE}
                  onClick={() => setUnlockUserPopUp(true)}
                >
                  <LockOpenIcon className={classes.unlockBtnIcon} />
                  {langConstant.UNLOCK_USER}
                </Button>
              ) : null}
            </DialogActions>
          )}
        </Grid>
        {tempPassPopUp && (
          <TempPassword
            userName={userData.USER_NAME}
            show={tempPassPopUp}
            setShow={setTempPassPopUp}
            sendTempPassword={sendTempPassword}
          />
        )}
        {unlockUserPopUp && (
          <UnlockUser
            userName={userData.USER_NAME}
            show={unlockUserPopUp}
            setShow={setUnlockUserPopUp}
            updateAccountStatus={updateAccountStatus}
          />
        )}
        {entitlementPopUp && (
          <ChangeEntitlement
            user_role={userData.ROLE_ID}
            user_entitlement={userData.ENTITLEMENT_ID}
            show={entitlementPopUp}
            setShow={setEntitlementPopUp}
            user_ID={user_ID}
            setUserData={setUserData}
          />
        )}
      </Grid>
    </Drawer>
  );
};

ProfileDialog.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  userData: PropTypes.object,
  profilePic: PropTypes.string,
  setProfileDialog: PropTypes.func,
  countryData: PropTypes.arrayOf(PropTypes.object),
  setUserData: PropTypes.func,
  user_ID: PropTypes.number,
  isTeamView: PropTypes.bool,
  domainList: PropTypes.arrayOf(PropTypes.object),
  notificationPreferences: PropTypes.array,
  twoFADetail: PropTypes.array,
  setNotificationPreferences: PropTypes.func,
  setTwoFADetail: PropTypes.func,
};

export default ProfileDialog;
