import {
  Box,
  Grid,
  Button,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import langConstant from "_lang";
import PolicyComparisonServices from "../../../../../PolicyExplorer/services";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { alertActions, loadingActions } from "_actions";
import FilterSentenceComponent from "./filterSentences";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import DownloadIcon from "@mui/icons-material/Download";
import BasePolicySideBar from "./basePolicySidebar";
import ContextMenu from "./contextMenu";
import Tooltip from "@mui/material/Tooltip";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { useModal } from "_hooks";
import { AddNoteDialog } from "containers/cyber-assessment/AuditActions/components/ListDetails/AddNoteDialog";
import { AddEditActionTabMenu } from "_components/ActionList/AddEditActionTabMenu";
import { linkTypes } from "containers/cyber-assessment/AuditActions/constants";
import { downloadFile } from "_helpers/utils";
import { getThemeDetails } from "containers/LoginPage/reducers";

const useStyles = makeStyles(() => ({
  BoxPages: {
    padding: "15px 10px",
    boxShadow: "inset rgb(0 0 0 / 17%) 0px 0px 14px 2px;",
    position: "relative",
    overflow: "hidden",
    height: "650px",
  },
  NoContent: {
    fontWeight: "bold",
    marginTop: "250px",
    textAlign: "center",
  },
  bookContainer: {
    display: "flex",
    marginLeft: "-20px",
    marginRight: "-20px",
    "& .MuiGrid-item": {
      padding: "0px 20px",
    },
    "& .MuiTableContainer-root": {
      height: "100%",
    },
  },
  headerFont: {
    fontWeight: "500",
  },
  activeSentence: {
    border: "2px solid #124571",
    borderRadius: "10px",
  },
  actionWrap: {
    borderRadius: "50%",
    padding: "4px",
    height: "28px",
    width: "28px",
    display: "flex",
    alignItems: "center",
  },
  actionButton: {
    color: "#fff",
    fontSize: "18px !important",
    margin: "0 auto",
  },
}));

const FileComparisonComponent = ({
  basePolicyText,
  targetPolicyText,
  basePolicyInfo,
  targetPolicyInfo,
  vectorsFilter,
  sentenceFilter,
  setDecisionScore,
  decisionScore,
  decisionFilter,
  setComparisonStatus,
  chatBotAvailibility,
  setChatBotAvailibility,
  setSelectedBaseRows,
  selectedBaseRows,
  actionList,
  setActionList,
}) => {
  const classes = useStyles();
  const themeDetails = useSelector(getThemeDetails);

  const docStorageIP = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_IP
  );
  const docStorageURL = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_URL
  );
  const docStorageType = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_TYPE
  );

  const [comparedSentencesView, setComparedSentencesView] = useState(false);
  const [baseFilterText, setBaseFilterText] = useState(null);
  const [baseFilteredRows, setBaseFilteredRows] = useState([]);
  const [headerComparedSentence, setHeaderComparedSentence] = useState([]);
  const [textComparedSentence, setTextComparedSentence] = useState({});

  const [selectedBaseHeader, setSelectedBaseHeader] = useState({
    header: "",
    text: "",
    type: "",
  });
  const [selectedHeader, setSelectedHeader] = useState({
    header: "",
    type: "",
  });
  const [decisionSentence, setDecisionSentence] = useState({
    comparisonDetailId: null,
    decisionTypeId: null,
    linkId: null,
  });
  const [expandedList, setExpandedList] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [expandView, setExpandView] = useState(false);
  const [sentenceCount, setSentenceCount] = useState();

  const [matchedContent, setMatchedContent] = useState([]);

  const open = Boolean(anchorEl);
  const handleMenuClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    setDecisionSentence({
      comparisonDetailId: null,
      decisionTypeId: null,
      linkId: null,
    });
  };

  const { id } = useParams();
  const dispatch = useDispatch();
  const addNoteModal = useModal(),
    addActionModal = useModal();

  useEffect(() => {
    if (sentenceFilter == "top_2") {
      setSentenceCount(2);
    } else if (sentenceFilter == "top_5") {
      setSentenceCount(5);
    } else if (sentenceFilter == "all") {
      setSentenceCount("all");
    }
  }, [sentenceFilter]);

  useEffect(() => {
    (async () => {
      if (selectedBaseHeader.header) {
        setComparedSentencesView(true);
        let tempAllComparedSentences = [...matchedContent];
        let filteredAllComparedSentences = tempAllComparedSentences.filter(
          (item) => item.BASE_DOCUMENT === selectedBaseHeader.header
        );
        let linkList = [];
        let comparedText = {};
        filteredAllComparedSentences.map((sentence) => {
          if (!linkList.includes(sentence.LINK_ID)) {
            linkList.push(sentence.LINK_ID);
            comparedText[sentence.LINK_ID] = [];
          }
        });
        if (selectedBaseHeader.text) {
          tempAllComparedSentences.map((sentence) => {
            if (
              linkList.includes(sentence.LINK_ID) &&
              sentence.TYPE.trim() === "T" &&
              sentence["SEMANTIC_SCORE"] > 0 &&
              sentence.BASE_DOCUMENT === selectedBaseHeader.text
            ) {
              comparedText[sentence.LINK_ID] = [
                ...comparedText[sentence.LINK_ID],
                sentence,
              ];
            }
          });
        } else {
          tempAllComparedSentences.map((sentence) => {
            if (
              linkList.includes(sentence.LINK_ID) &&
              sentence.TYPE.trim() === "T" &&
              sentence["SEMANTIC_SCORE"] > 0
            ) {
              comparedText[sentence.LINK_ID] = [
                ...comparedText[sentence.LINK_ID],
                sentence,
              ];
            }
          });
        }
        setHeaderComparedSentence(filteredAllComparedSentences);
        setTextComparedSentence(comparedText);
        setExpandedList([]);
      }
    })();
  }, [selectedHeader]);

  useEffect(() => {
    if (baseFilterText) {
      const timer = setTimeout(() => onBaseFilterChange(), 1500);
      return () => clearTimeout(timer);
    } else {
      setBaseFilteredRows(basePolicyText);
    }
  }, [baseFilterText, basePolicyText]);

  const pickBackgroundColor = (decision) => {
    if (decision == "1") {
      return "#1bff0014";
    } else if (decision == "2") {
      return "#ff000047";
    } else if (decision == "3") {
      return "#fbff0014";
    } else if (decision == "4") {
      return "#00000045";
    } else {
      return "#ffffff";
    }
  };

  const updateExpandList = (sentenceID) => {
    if (expandedList.includes(sentenceID)) {
      let tempList = [...expandedList];
      tempList = tempList.filter((item) => item !== sentenceID);
      setExpandedList(tempList);
    } else {
      setExpandedList((prevState) => [...prevState, sentenceID]);
    }
  };

  const expandCollapseAll = (type) => {
    if (type === "expand") {
      let tempHeaderComparedSentence = headerComparedSentence.filter(
        (sentence) => textComparedSentence[sentence["LINK_ID"]].length
      );
      setExpandedList(
        tempHeaderComparedSentence.map(
          (sentence) => sentence["POLICY_COMPARISON_DETAILS_ID"]
        )
      );
    } else {
      setExpandedList([]);
    }
  };

  const comparedColumnData = [
    {
      key: "SEMANTIC_SCORE",
      label: langConstant.SEMANTIC_SCORE,
    },
    {
      key: "TARGET_DOCUMENT",
      label: langConstant.SENTENCE,
      format: function formatData(value) {
        return (
          <>
            {textComparedSentence[value["LINK_ID"]] &&
            textComparedSentence[value["LINK_ID"]].length !== 0 ? (
              <Accordion
                style={{ boxShadow: "none" }}
                expanded={expandedList.includes(
                  value["POLICY_COMPARISON_DETAILS_ID"]
                )}
                onClick={() =>
                  updateExpandList(value["POLICY_COMPARISON_DETAILS_ID"])
                }
              >
                <AccordionSummary
                  style={{ padding: "0px", margin: "0px 0px" }}
                  expandIcon={
                    value["TARGET_DOCUMENT"] ? <ExpandMoreIcon /> : null
                  }
                >
                  <Typography className={classes.headerFont}>
                    {value["TARGET_DOCUMENT"]}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid>
                    {sentenceCount !== "all"
                      ? textComparedSentence[value["LINK_ID"]]
                          .slice(0, sentenceCount)
                          .sort(function (a, b) {
                            return b["SEMANTIC_SCORE"] - a["SEMANTIC_SCORE"];
                          })
                          .map((sentence, index) => {
                            return (
                              <Grid key={index}>
                                {(decisionFilter.length === 0 ||
                                  decisionFilter.includes(
                                    sentence["DECISION_TYPE_ID"]
                                  )) && (
                                  <Grid
                                    container
                                    className={
                                      sentence[
                                        "POLICY_COMPARISON_DETAILS_ID"
                                      ] ===
                                        decisionSentence[
                                          "comparisonDetailId"
                                        ] && classes.activeSentence
                                    }
                                    style={{
                                      backgroundColor: pickBackgroundColor(
                                        sentence["DECISION_TYPE_ID"]
                                      ),
                                      cursor: "pointer",
                                    }}
                                  >
                                    <Grid item xs={2} md={1}>
                                      {sentence["SEMANTIC_SCORE"]}
                                    </Grid>
                                    <Grid
                                      item
                                      xs={10}
                                      md={11}
                                      onContextMenu={(event) => {
                                        event.preventDefault();
                                        handleMenuClick(event);
                                        setDecisionSentence({
                                          comparisonDetailId:
                                            sentence[
                                              "POLICY_COMPARISON_DETAILS_ID"
                                            ],
                                          decisionTypeId:
                                            sentence["DECISION_TYPE_ID"],
                                          linkId: sentence["LINK_ID"],
                                        });
                                      }}
                                    >
                                      <Typography>
                                        {sentence["TARGET_DOCUMENT"]}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                )}
                              </Grid>
                            );
                          })
                      : textComparedSentence[value["LINK_ID"]].map(
                          (sentence, index) => {
                            return (
                              <Grid key={index}>
                                {(decisionFilter.length === 0 ||
                                  decisionFilter.includes(
                                    sentence["DECISION_TYPE_ID"]
                                  )) && (
                                  <Grid
                                    container
                                    className={
                                      sentence[
                                        "POLICY_COMPARISON_DETAILS_ID"
                                      ] ===
                                        decisionSentence[
                                          "comparisonDetailId"
                                        ] && classes.activeSentence
                                    }
                                    style={{
                                      backgroundColor: pickBackgroundColor(
                                        sentence["DECISION_TYPE_ID"]
                                      ),
                                      cursor: "pointer",
                                    }}
                                  >
                                    <Grid item xs={2} md={1}>
                                      {sentence["SEMANTIC_SCORE"]}
                                    </Grid>
                                    <Grid
                                      item
                                      xs={10}
                                      md={11}
                                      onContextMenu={(event) => {
                                        event.preventDefault();
                                        handleMenuClick(event);
                                        setDecisionSentence({
                                          comparisonDetailId:
                                            sentence[
                                              "POLICY_COMPARISON_DETAILS_ID"
                                            ],
                                          decisionTypeId:
                                            sentence["DECISION_TYPE_ID"],
                                          linkId: sentence["LINK_ID"],
                                        });
                                      }}
                                    >
                                      <Typography>
                                        {sentence["TARGET_DOCUMENT"]}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                )}
                              </Grid>
                            );
                          }
                        )}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            ) : (
              <Typography className={classes.headerFont}>
                {value["TARGET_DOCUMENT"]}
              </Typography>
            )}
          </>
        );
      },
    },
  ];

  const onBaseFilterChange = async () => {
    dispatch(loadingActions.start());
    const response = await PolicyComparisonServices.getPolicySearchDetails(
      basePolicyInfo["v_base_policy_id"],
      baseFilterText,
      "B"
    );
    dispatch(loadingActions.end());
    if (response.status === 200) {
      setBaseFilteredRows(response["#result-set-1"]);
    }
  };

  return (
    <Box>
      <Grid className={classes.bookContainer}>
        <Grid item style={{ width: expandView ? "25%" : "16%" }}>
          <BasePolicySideBar
            sentences={baseFilteredRows}
            setSelectedBaseRows={setSelectedBaseRows}
            setBaseFilterText={setBaseFilterText}
            selectedBaseRows={selectedBaseRows}
            setExpandView={setExpandView}
            expandView={expandView}
            // tokenizedSentences={basePolicySentences}
          />
        </Grid>
        <Grid item style={{ width: expandView ? "37%" : "42%" }}>
          <FilterSentenceComponent
            sentences={selectedBaseRows}
            vectorsFilter={vectorsFilter}
            type="base"
            setComparedSentencesView={setComparedSentencesView}
            setSelectedBaseHeader={setSelectedBaseHeader}
            setSelectedHeader={setSelectedHeader}
            selectedHeader={selectedHeader}
            policyFolderUrl={basePolicyInfo["v_base_folder"]}
            policyName={basePolicyInfo["v_base_policyname"]}
            policyID={basePolicyInfo["v_base_policy_id"]}
            selectedBaseHeader={selectedBaseHeader}
            setMatchedContent={setMatchedContent}
            chatBotAvailibility={chatBotAvailibility["base"]}
            setChatBotAvailibility={setChatBotAvailibility}
            // tokenizedSentences={basePolicySentences}
          />
        </Grid>
        <Grid item style={{ width: expandView ? "37%" : "42%" }}>
          <Box>
            {comparedSentencesView ? (
              <Grid>
                <Grid container style={{ padding: "8px 0px" }}>
                  <Grid
                    item
                    md={6}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography className={classes.headerFont}>
                      {langConstant.TARGET_DOCUMENT}
                    </Typography>
                    <span
                      className={classes.actionWrap}
                      style={{
                        backgroundColor:
                          themeDetails?.ACTIONBUTTON_BGROUND_COLOUR,
                        marginLeft: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        downloadFile(
                          targetPolicyInfo["v_target_folder"],
                          targetPolicyInfo["v_target_policyname"],
                          docStorageType,
                          docStorageIP,
                          docStorageURL
                        );
                      }}
                    >
                      <Tooltip title={langConstant.DOWNLOAD_TARGET_DOCUMENT}>
                        <DownloadIcon className={classes.actionButton} />
                      </Tooltip>
                    </span>
                  </Grid>
                  <Grid item md={6} style={{ textAlign: "right" }}>
                    {expandedList.length >= 1 ? (
                      <Button
                        color="primary"
                        onClick={() => expandCollapseAll("collapse")}
                        style={{ fontWeight: "bold" }}
                      >
                        <UnfoldLessIcon style={{ marginRight: "5px" }} />{" "}
                        {langConstant.COLLAPSE_ALL}
                      </Button>
                    ) : (
                      <Button
                        color="primary"
                        onClick={() => expandCollapseAll("expand")}
                        style={{ fontWeight: "bold" }}
                      >
                        <UnfoldMoreIcon style={{ marginRight: "5px" }} />{" "}
                        {langConstant.EXPAND_ALL}
                      </Button>
                    )}
                  </Grid>
                </Grid>
                <Box
                  className={classes.BoxPages}
                  onContextMenu={(e) => e.preventDefault()}
                >
                  {headerComparedSentence.length ? (
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            {comparedColumnData.map((column, columnIndex) => {
                              return (
                                <TableCell key={columnIndex}>
                                  {column.label}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {headerComparedSentence.map(
                            (sentenceItem, sentenceIndex) => {
                              return (
                                <TableRow key={sentenceIndex}>
                                  {comparedColumnData.map(
                                    (column, columnIndex) => {
                                      return (
                                        <TableCell
                                          key={columnIndex + sentenceIndex}
                                        >
                                          {column.format
                                            ? column.format(sentenceItem)
                                            : sentenceItem[column.key]}
                                        </TableCell>
                                      );
                                    }
                                  )}
                                </TableRow>
                              );
                            }
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <Typography className={classes.NoContent}>
                      {langConstant.NO_MATCH}
                    </Typography>
                  )}
                </Box>
              </Grid>
            ) : (
              <FilterSentenceComponent
                sentences={targetPolicyText}
                vectorsFilter={vectorsFilter}
                type="target"
                policyFolderUrl={targetPolicyInfo["v_target_folder"]}
                policyName={targetPolicyInfo["v_target_policyname"]}
                policyID={targetPolicyInfo["v_target_policy_id"]}
                setMatchedContent={setMatchedContent}
                chatBotAvailibility={chatBotAvailibility["target"]}
                setChatBotAvailibility={setChatBotAvailibility}
                // tokenizedSentences={targetPolicySentences}
              />
            )}
          </Box>
        </Grid>
        {open && (
          <ContextMenu
            open={open}
            anchorEl={anchorEl}
            handleMenuClose={handleMenuClose}
            comparisonID={id}
            decisionSentence={decisionSentence}
            setDecisionScore={setDecisionScore}
            decisionScore={decisionScore}
            textComparedSentence={textComparedSentence}
            setTextComparedSentence={setTextComparedSentence}
            setComparisonStatus={setComparisonStatus}
            addNoteModal={addNoteModal}
            addActionModal={addActionModal}
          />
        )}
        {addNoteModal?.isOpen && (
          <AddNoteDialog
            modal={addNoteModal}
            action={{ i_POLICYCOMPARISONID: id }}
          />
        )}
        {addActionModal?.isOpen && (
          <AddEditActionTabMenu
            actionList={actionList}
            setActionList={setActionList}
            modal={addActionModal}
            linkedId={id}
            actionListType={linkTypes[2]}
          />
        )}
      </Grid>
    </Box>
  );
};

FileComparisonComponent.propTypes = {
  basePolicyText: PropTypes.array,
  targetPolicyText: PropTypes.array,
  basePolicyInfo: PropTypes.object,
  targetPolicyInfo: PropTypes.object,
  vectorsFilter: PropTypes.string,
  sentenceFilter: PropTypes.string,
  setDecisionScore: PropTypes.func,
  decisionScore: PropTypes.object,
  decisionFilter: PropTypes.array,
  setComparisonStatus: PropTypes.func,
  chatBotAvailibility: PropTypes.object,
  setChatBotAvailibility: PropTypes.func,
  setSelectedBaseRows: PropTypes.func,
  selectedBaseRows: PropTypes.array,
  actionList: PropTypes.object,
  setActionList: PropTypes.func,
};

export default FileComparisonComponent;
