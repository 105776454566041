/*COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. 
*/

import React from "react";
import apiService from "_services/api.service";
import { alertActions, loadingActions } from "_actions";

const putNewTemplateQuestions = (data) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/PutNewTemplateQuestions", data)
      .then(apiService.handleResponse)
      .then(
        (response) => {
          dispatch(loadingActions.end());
        },
        (error) => {
          dispatch(loadingActions.end());
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};

// const putQuestionInRuleBook = (data) => {
//   return (dispatch) => {
//     dispatch(loadingActions.start());
//     apiService
//       .apiCall("dbapi")
//       .send("/v1/api/proc/DRegulativDB/PutQuestionInRuleBook", data)
//       .then(apiService.handleResponse)
//       .then(
//         (response) => {
//           dispatch(loadingActions.end());
//           response["status"] = 200;
//           return response;
//         },
//         (error) => {
//           dispatch(loadingActions.end());
//           if (
//             error.response &&
//             error.response.data &&
//             error.response.data.message
//           ) {
//             dispatch(
//               alertActions.error(
//                 error.response.data.message.map((item, index) => {
//                   return <div key={index}>{item}</div>;
//                 })
//               )
//             );
//           } else {
//             dispatch(alertActions.error(error.toString()));
//           }
//         }
//       );
//   };
// };

const putQuestionInRuleBook = (data) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/PutQuestionInRuleBook", data)
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

export default {
  putNewTemplateQuestions,
  putQuestionInRuleBook,
};
