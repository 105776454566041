/*COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. 
*/
import { makeStyles } from "@material-ui/core";
import { green, grey, red, amber } from "@material-ui/core/colors";
const rootStyles = makeStyles(
  (theme) => ({
    greenButton: {
      color: theme.palette.getContrastText(grey[900]),
      backgroundColor: green[500],
      "&:hover": {
        backgroundColor: green[700],
      },
    },
    blueButton: {
      color: theme.palette.getContrastText(grey[900]),
      paddingLeft: "30px",
      paddingRight: "30px",
      backgroundColor: "#124571",
      "&:hover": {
        backgroundColor: "#124571",
      },
    },
    amberButton: {
      color: theme.palette.getContrastText(grey[900]),
      backgroundColor: amber[800],
      "&:hover": {
        backgroundColor: amber[900],
      },
    },
    //todo Can remove this button since it is no longer being used in Dialog box - 13th May'21
    redButton: {
      color: theme.palette.getContrastText(grey[900]),
      backgroundColor: red[600],
      "&:hover": {
        backgroundColor: red[700],
      },
    },
    redText: {
      color: "#f44336",
    },
    greenText: {
      color: "#43a047",
    },
    progressbar: {
      border: "#707070 1px solid",
      borderRadius: "100px",
      maxWidth: "250px",
      width: "100%",
      margin: "10px",
      overflow: "hidden",
    },
    progressbarInner: {
      with: 0,
      borderRadius: "100px",
      height: "23px",
      backgroundColor: "#009646",
      border: "#fff 4px solid",
    },
    progressLogoWrapper: {
      position: "relative",
      width: "254px",
      height: "56px",
    },
    progressLogoBack: {
      height: "100%",
      width: "100%",
      backgroundSize: "cover",
      filter: "invert(50%)",
      position: "absolute",
    },
    progressLogoFront: {
      filter: "invert(0)",
      height: "100%",
    },
    greenCheckbox: {
      color: green[600],
      "&:checked": {
        color: green[800],
      },
    },
    flexNoWrap: {
      flexWrap: "nowrap",
    },
    mtMinus10: {
      marginTop: "-10px",
    },
    greenChip: {
      color: theme.palette.getContrastText(grey[900]),
      backgroundColor: green[500],
      "&:hover": {
        backgroundColor: green[700],
      },
    },
    redChip: {
      backgroundColor: "rgb(255, 188, 188)",
      color: "#000",
    },
    squareChip: {
      borderRadius: "5px",
      textTransform: "uppercase",
    },
    catTitle: {
      fontWeight: "bold",
      marginLeft: "12px",
      backgroundColor: "#efefef",
      padding: "5px 10px 5px",
      marginBottom: "10px",
      borderRadius: "5px",
    },
    smallAvatar: {
      fontSize: 12,
      width: "24px",
      height: "24px",
    },
    collapsibleHead: {
      backgroundColor: "#efefef",
      height: "100%",
      padding: "5px 10px",
      display: "flex",
      alignItems: "center",
    },
    collapsibleHeadFirst: {
      marginLeft: "-16px",
    },
    collapsibleHeadLast: {
      marginRight: "-58px",
    },
    viewIcon: {
      borderRadius: "50%",
      padding: "4px",
      height: "28px",
      width: "28px",
      display: "flex",
      alignItems: "center",
    },
    primaryCheckBox: {
      // color: ({ actionButtonColor }) => actionButtonColor,
      "&.Mui-checked": {
        color: ({ actionButtonColor }) => `${actionButtonColor} !important`,
      },
    },
  }),
  { index: 1 }
);
export default rootStyles;
