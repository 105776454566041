// OP#2801 UJL3 - UI Dev - Manage User Profile
// file is for put all store procedure of profile update and get
import { alertActions, loadingActions } from "_actions";
import apiService from "../../../_services/api.service";
import React from "react";
import sessionService from "_services/session.service";

const getUserProfileInformation = (userID) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetUserProfileInformationV1", {
      i_USER_ID: userID,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const getTeamUserProfileInfo = (userID) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetTeamUserProfileInfo", {
      i_USER_ID: userID,
      i_USER_ID2: userID,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const getDomainList = () => {
  return apiService
    .apiCall("dbapi")
    .get("/v1/api/proc/DRegulativDB/GetRegulatoryDomains")
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const updateUserProfile = (userData) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/UpdateUserProfileAdmin", userData)
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};
const updateUserDomain = (domainData) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/UpdateUserDomain", domainData)
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const updateUserAvatar = (userImage) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/UpdateUserAvatar", userImage)
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const getAvailableRoles = () => {
  return apiService
    .apiCall("dbapi")
    .get("/v1/api/proc/DRegulativDB/GetAvailableRoles")
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const getAvailableEntitlements = () => {
  return apiService
    .apiCall("dbapi")
    .get("/v1/api/proc/DRegulativDB/GetAvailableEntitlementsV1")
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const getCustomerEntitlementFeatureMapping = (
  customerID,
  entitlementMasterID
) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetCustomerEntitlementFeatureMappingV1", {
      i_CUSTOMER_ID: customerID,
      i_ENTITLEMENT_MASTER_ID: entitlementMasterID,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const getFeatures = () => {
  return apiService
    .apiCall("dbapi")
    .get("/v1/api/proc/DRegulativDB/GetFeaturesV1")
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const updateUserAccountStatus = (userData) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/UpdateUserAccountStatus", userData)
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const generateTemporaryPassword = (userData) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/UpdateUserPassword", userData)
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const updateUserTempPassword = (tempPassword, userID) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/UpdateUserTempPassword", {
      i_USER_ID: userID,
      i_TEMP_PASSWORD: tempPassword,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const sendEmail = (bccEmail = "", ccEmail = "", htmlBody, subject, toEmail) => {
  return apiService
    .apiCall("notification")
    .send("/api/v1/sendMail", {
      bccEmailId: bccEmail,
      ccEmailId: ccEmail,
      htmlBody: htmlBody,
      subject: subject,
      toEmailId: toEmail,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const getNotificationType = (userID, customerID, notificationType) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetNotificationType", {
      i_USER_ID: userID,
      i_CUSTOMER_ID: customerID,
      i_NOTIFICATION_TYPE: notificationType,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const updateUserRoleEntitlement = (payload) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/UpdateUserRoleEntitlement", payload)
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const getUserNotificationPreferences = (userId) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetUserNotificationPreferences", {
      i_USER_ID: userId,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const putUserNotificationPreference = (
  userId,
  notificationIds,
  notificationsOn
) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/PutUserNotificationPreference", {
      i_USER_ID: userId,
      i_USER_ID2: userId,
      i_NOTIFICATION_ID: notificationIds,
      i_NOTIFY_ON: notificationsOn,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const update2FA = (userId, twoFAStatus, serviceName, serviceURL) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/Update2FA", {
      i_USER_ID: userId,
      i_USER_ID2: userId,
      i_2FA_ENABLED: twoFAStatus,
      i_2FA_SERVICE_NAME: serviceName,
      i_2FA_SERVICE_URL: serviceURL,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const getCheckUserEmail = (customerId, emailAddress) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetCheckUserEmail", {
      i_CUSTOMER_ID: customerId,
      i_EMAIL_ADDRESS: emailAddress,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          if (
            response["#result-set-1"] &&
            response["#result-set-1"].length > 0
          ) {
            if (response["#result-set-1"][0]["RESULT"] == "FALSE") {
              return {
                userAllowed: true,
                serviceSuccess: true,
                status: 200,
              };
            } else {
              return {
                userAllowed: false,
                serviceSuccess: true,
                status: 200,
              };
            }
          } else {
            return {
              userAllowed: false,
              serviceSuccess: true,
              status: 200,
            };
          }
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const getCheckUsername = (customerId, userName) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetCheckUsername", {
      i_CUSTOMER_ID: customerId,
      i_USER_NAME: userName,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          if (
            response["#result-set-1"] &&
            response["#result-set-1"].length > 0
          ) {
            if (response["#result-set-1"][0]["RESULT"] == "FALSE") {
              return {
                userAllowed: true,
                serviceSuccess: true,
                status: 200,
              };
            } else {
              return {
                userAllowed: false,
                serviceSuccess: true,
                status: 200,
              };
            }
          } else {
            return {
              userAllowed: false,
              serviceSuccess: true,
              status: 200,
            };
          }
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const putUser = (userData, teamID, userID = null) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/PutUser", {
      i_USER_ID: userID,
      i_USER_ID2: userID,
      i_USER_NAME: userData.USERNAME,
      i_USER_PASSWORD: userData.PASSWORD ? userData.PASSWORD : null,
      i_FIRST_NAME: userData.FIRSTNAME,
      i_LAST_NAME: userData.LASTNAME,
      i_EMAIL_ADDRESS: userData.EMAIL_ADDRESS,
      i_PHONE_NUMBER: userData.PHONE_NUMBER,
      i_COUNTRY_CODE: userData.COUNTRY_CODE,
      i_CUSTOMER_ID: userData.CUSTOMER_ID,
      i_CUSTOMER_ID2: userData.CUSTOMER_ID,
      i_DEPARTMENT: userData.DEPARTMENT_NAME,
      i_ENTITLEMENT_ID: userData.ENTITLEMENT_ID,
      i_ROLE_ID: userData.USER_ROLE,
      i_DATA_CLASSIFICATION: userData.DATA_CLASSIFICATION,
      i_TEAM_ID: teamID,
      i_SUPERVISOR_ID: userData.SUPERVISOR_ID,
      i_RFI_RECIPIENT_ID: userData.IS_RFI_RECIPIENT,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const getCustomerInfoClassification = (userID, setInfoClassification) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetCustomerInfoClassification", {
        i_USER_ID: userID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          const tempClassifications = [];
          response["#result-set-1"].forEach((item) => {
            tempClassifications.push({
              value: item["INFO_CLASSIFICATION_ID"],
              label: item["DESCRIPTION"],
            });
          });
          setInfoClassification(tempClassifications);
        },
        (error) => {
          setInfoClassification([]);
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
      });
  };
};

const getUserServiceConnection = (userID, setServiceConnections) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetUserServiceConnection", {
        i_USER_ID: userID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          setServiceConnections(response["#result-set-1"]);
        },
        (error) => {
          setServiceConnections([]);
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
      });
  };
};

const getServiceConnectionName = (customerID, setAllService) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetServiceConnectionName", {
        i_CUSTOMER_ID: customerID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          setAllService(
            response["#result-set-1"].map((item) => {
              return {
                value: item.ID,
                label: item.CONNECTION_NAME,
                ...item,
              };
            })
          );
        },
        (error) => {
          setAllService([]);
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
      });
  };
};

const updateUserServiceConnection = (
  data,
  serviceConnections,
  setServiceConnections,
  handleClose
) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/UpdateUserServiceConnection", {
        i_USER_ID: data.USER_ID,
        i_SERVICE_CONNECTION_TYPE_ID: data.SERVICE_CONNECTION_TYPE_ID,
        i_USER_CREDENTIALS: data.USER_CREDENTIALS,
        i_SERVICE_URL: data.SERVICE_URL,
        i_ACTIVE: data.ACTIVE,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          let tempServiceConnections = [...serviceConnections];
          const index = tempServiceConnections.findIndex(
            (item) =>
              item.SERVICE_CONNECTION_TYPE_ID == data.SERVICE_CONNECTION_TYPE_ID
          );
          if (index !== -1) {
            tempServiceConnections[index]["USER_CREDENTIALS"] =
              data.USER_CREDENTIALS;
            tempServiceConnections[index]["SERVICE_URL"] = data.SERVICE_URL;
            tempServiceConnections[index]["ACTIVE"] = JSON.parse(data.ACTIVE);
          } else {
            tempServiceConnections.push({
              USER_ID: data.USER_ID,
              SERVICE_CONNECTION_TYPE_ID: data.SERVICE_CONNECTION_TYPE_ID,
              USER_CREDENTIALS: data.USER_CREDENTIALS,
              SERVICE_URL: data.SERVICE_URL,
              CONNECTION_NAME: data.CONNECTION_NAME,
              ACTIVE: data.ACTIVE,
            });
          }
          setServiceConnections(tempServiceConnections);
          // setAllService(response["#result-set-1"]);
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
        handleClose();
      });
  };
};

const generateQRFromKey = (key, setQRCode) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("user")
      .send(`/auth/mfa/generateQR?qrValue=${key}`)
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (response["mfaRegisterCode"]) {
            setQRCode(response["mfaRegisterCode"]);
          }
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
      });
  };
};

const loginWithMFA = (username, password, setQRCode, handleClose) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("user")
      .send("/auth/mfa", {
        username: username,
        password: password,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (response && response.authenticated && response.mfaType == "QR") {
            setQRCode(response.mfaRegisterCode);
          }
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
        handleClose();
      });
  };
};

const getCustomerSupervisorNames = (customerID, setSupervisorOptions) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetCustomerSupervisorNames", {
        i_CUSTOMER_ID: customerID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          setSupervisorOptions(
            response["#result-set-1"].map((item) => {
              return {
                value: item.SUPERVISOR_ID,
                label: item.FIRST_NAME + " " + item.LAST_NAME,
              };
            })
          );
        },
        (error) => {
          setSupervisorOptions([]);
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
      .finally(() => {
        dispatch(loadingActions.end());
      });
  };
};

export default {
  getUserProfileInformation,
  updateUserProfile,
  updateUserAvatar,
  getAvailableRoles,
  updateUserAccountStatus,
  generateTemporaryPassword,
  getAvailableEntitlements,
  updateUserRoleEntitlement,
  updateUserDomain,
  getDomainList,
  getUserNotificationPreferences,
  putUserNotificationPreference,
  update2FA,
  getCheckUserEmail,
  getCheckUsername,
  putUser,
  getCustomerInfoClassification,
  getUserServiceConnection,
  loginWithMFA,
  getServiceConnectionName,
  updateUserServiceConnection,
  generateQRFromKey,
  getCustomerSupervisorNames,
  updateUserTempPassword,
  sendEmail,
  getNotificationType,
  getTeamUserProfileInfo,
  getCustomerEntitlementFeatureMapping,
  getFeatures,
};
