import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Box } from "@material-ui/core";
import langConstant from "_lang";
import sessionService from "_services/session.service";
import { useDispatch, useSelector } from "react-redux";
import { GetAssessmentsForMyAssessments, GetRFIRecipients } from "./services";
import MasterAssessment from "_components/MasterAssessmentComp/MasterAssessment";
import { setSteps } from "_components/Stepper/action";

const ReviewScreenStatus = {
  All: "All",
  "Sent Back": "SENT_BACK",
  Reviewed: "FINAL",
};

const steps = [
  { stepname: langConstant.COMPLETE_ASSESSMENT, compID: 1 },
  { stepname: langConstant.SEND_FOR_REVIEW, compID: 2 },
];

const tabs = [
  { name: langConstant.DASHBOARD, count: 0, isCount: false, id: 0 },
  { name: langConstant.ALL, count: 0, isCount: true, id: 1 },
  { name: langConstant.OPEN, count: 0, isCount: true, id: 2 },
  { name: langConstant.COMPLETED, count: 0, isCount: true, id: 3 },
  { name: langConstant.RFI_SENT, count: 0, isCount: true, id: 4 },
  { name: langConstant.RFI_PENDING, count: 0, isCount: true, id: 5 },
  { name: langConstant.RFI_OVERDUE, count: 0, isCount: true, id: 6 },
  { name: langConstant.RFI_RESOLVED, count: 0, isCount: true, id: 7 },
  { name: langConstant.SENT_BACK, count: 0, isCount: true, id: 8 },
];

const InitiateMyAssessment = () => {
  const userID = sessionService.getUserId();
  const { id, sid } = useParams();
  const dispatch = useDispatch();
  const [IsDataLoaded, setIsDataLoaded] = useState(false);
  const [InitialTabs, setInitialTabs] = useState(tabs);
  const questionList = useSelector(
    (state) => state.MyAssessmnetReducer.MyAssessmentDetails
  );
  const rfiitemList = useSelector(
    (state) => state.MyAssessmnetReducer.RfiItems
  );
  const trustScoreList = useSelector(
    (state) => state.MyAssessmnetReducer.TrustScoreForAllQuestion
  );
  const observationList = useSelector(
    (state) => state.MyAssessmnetReducer.ObservationDetails
  );

  const DataSetter = async () => {
    return new Promise((resolve) => {
      setTimeout(async () => {
        setIsDataLoaded(false);
        dispatch(setSteps(steps));
        const temp = await dispatch(
          GetAssessmentsForMyAssessments(sid, userID)
        );
        const temp2 = await dispatch(GetRFIRecipients(userID));
        resolve("resolved");
        setIsDataLoaded(true);
      }, 0);
    });
  };

  useEffect(() => {
    (async () => {
      if (sid && userID) {
        await DataSetter();
      }
    })();
  }, [sid]);

  useEffect(() => {
    if (questionList.length > 0) {
      let RFI_SENT_COUNT = 0,
        RFI_PENDING_COUNT = 0,
        RFI_OVERDUE_COUNT = 0,
        RFI_RESOLVED_COUNT = 0,
        SENT_BACK_COUNT = 0,
        OPEN_COUNT = 0,
        COMPLETED_COUNT = 0;

      [...questionList].forEach((que) => {
        if (que.QUESTION_STATUS === "RFI_SENT") {
          RFI_SENT_COUNT = RFI_SENT_COUNT + 1;
        }

        if (que.QUESTION_STATUS === "RFI_PENDING") {
          RFI_PENDING_COUNT = RFI_PENDING_COUNT + 1;
        }

        if (que.QUESTION_STATUS === "RFI_OVERDUE") {
          RFI_OVERDUE_COUNT = RFI_OVERDUE_COUNT + 1;
        }

        if (que.QUESTION_STATUS === "RFI_RESOLVED") {
          RFI_RESOLVED_COUNT = RFI_RESOLVED_COUNT + 1;
        }

        if (que.QUESTION_STATUS === "SENT_BACK") {
          SENT_BACK_COUNT = SENT_BACK_COUNT + 1;
        }

        if (
          que?.QUESTION_STATUS === "COMPLETED_AND_LOCKED" ||
          que?.QUESTION_STATUS === "COMPLETED"
        ) {
          COMPLETED_COUNT += 1;
        }

        if (
          que?.QUESTION_STATUS?.trim() !== "COMPLETED_AND_LOCKED" &&
          que?.QUESTION_STATUS?.trim() !== "COMPLETED"
        ) {
          OPEN_COUNT += 1;
        }
      });

      let temptab = [
        tabs[0],
        {
          name: langConstant.ALL,
          count: questionList.length,
          isCount: true,
          id: 1,
        },
        { ...tabs[2], count: OPEN_COUNT },
        { ...tabs[3], count: COMPLETED_COUNT },
        {
          name: langConstant.RFI_SENT,
          count: RFI_SENT_COUNT,
          isCount: true,
          id: 4,
        },
        {
          name: langConstant.RFI_PENDING,
          count: RFI_PENDING_COUNT,
          isCount: true,
          id: 5,
        },
        {
          name: langConstant.RFI_OVERDUE,
          count: RFI_OVERDUE_COUNT,
          isCount: true,
          id: 6,
        },
        {
          name: langConstant.RFI_RESOLVED,
          count: RFI_RESOLVED_COUNT,
          isCount: true,
          id: 7,
        },
        {
          name: langConstant.SENT_BACK,
          count: SENT_BACK_COUNT,
          isCount: true,
          id: 8,
        },
      ];
      setInitialTabs(temptab);
    }
  }, [questionList]);

  return (
    <Box>
      {questionList.length > 0 && IsDataLoaded === true && (
        <MasterAssessment
          TopTabsArray={InitialTabs}
          QuestionList={questionList}
          IsAudit={false}
          ReviewScreenStatus={ReviewScreenStatus}
          observationList={observationList}
          trustScoreList={trustScoreList}
          identifier="myassessment"
          RfiitemsList={rfiitemList}
        />
      )}
    </Box>
  );
};

export default InitiateMyAssessment;
