/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import formActions from "./actions";
import {
  Box,
  TextField,
  FormHelperText,
  Button,
  Grid,
  IconButton,
  Typography,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import PublishIcon from "@material-ui/icons/Publish";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import langConstant from "_lang";
import { useModal } from "_hooks";
import { useFormik } from "formik";
import * as yup from "yup";
import { boldOnSearch } from "_helpers/utils";
import _ from "lodash";
import rootStyles from "./../../../../rootStyles";
import styles from "./styles";
import sessionService from "./../../../../_services/session.service";
import Loader from "_components/Loader";
import regulationAction from "../actions";
import { virusCheckerService } from "_helpers/utils";
import { alertActions, loadingActions } from "_actions";
import VerifiedIcon from "@mui/icons-material/Verified";
import CoronavirusIcon from "@mui/icons-material/Coronavirus";
import BulkQuestionUpload from "./bulkUploadQuestionRegulation";

const UploadRegulatoryForm = () => {
  const validationSchema = yup.object({
    country: yup.string().required("Country is required"),
    domain: yup.string().required("Domain is required"),
    regulationName: yup
      .string()
      .matches(
        "^[a-zA-Z0-9_-]{2,20}$",
        "Regulation should contain 2-20 characters, alphabets, numbers, underscore and hyphen"
      )
      .required("Regulation is required"),
    regulatoryFile: yup
      .mixed()
      .required("File is required")
      .test("fileSize", "File should be less than 20MB", (value) => {
        if (value) {
          let size = value.size / 1024 / 1024;
          return size <= 20; // less then 20MB
        }
      })
      .test("fileFormat", "Upload valid PDF file", (value) => {
        return value && ["application/pdf"].includes(value.type);
      }),
  });

  const classes = styles();
  const rootClasses = rootStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const BulkQuestionModal = useModal();

  const userID = sessionService.getUserId();
  const customerID = sessionService.getCustomerId();

  const alert = useSelector((state) => state.alert);
  const loadingFile = useSelector((state) => state.loadingFile);
  const regulationList = useSelector((state) => state.regulationList);
  const isVirusCheckingAvailable = useSelector(
    (state) => state.authentication?.detailedInfo?.VIRUS_CHECKER
  );
  const newRegulation = useSelector(
    (state) => state.authentication?.detailedInfo?.NEW_REGULATION
  );
  const docStorageIP = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_IP
  );
  const docStorageURL = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_URL
  );
  const docStorageType = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_TYPE
  );

  const [countriesOption, setCountriesOption] = useState([]);
  const [domainOptions, setDomainOption] = useState([]);
  const [regulationOption, setRegulationOption] = useState([]);
  const [isVirusFile, setIsVirusFile] = useState(false);
  const [fileName, setFileName] = useState("");
  const [fileContent, setFileContent] = useState(null);
  const [regulationID, setRegulationID] = useState(null);

  const [count, setCount] = useState(0);
  const inputFileRef = useRef(null);

  const parseCSV = (csvString) => {
    const rows = [];
    let currentField = [];
    let currentRow = [];
    let insideQuotes = false;

    for (let i = 0; i < csvString.length; i++) {
      const char = csvString[i];

      if (char === '"') {
        insideQuotes = !insideQuotes;
      } else if (char === "," && !insideQuotes) {
        currentRow.push(currentField.join(""));
        currentField = [];
      } else if ((char === "\n" || char === "\r\n") && !insideQuotes) {
        currentRow.push(currentField.join(""));
        rows.push(currentRow);
        currentRow = [];
        currentField = [];
      } else {
        currentField.push(char);
      }
    }

    if (currentField.length > 0) {
      currentRow.push(currentField.join(""));
    }
    if (currentRow.length > 0) {
      rows.push(currentRow);
    }

    return rows;
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    let parsedContent = null;
    const parsedFileContent = [];

    if (event.target.files.length > 0) {
      if (isVirusCheckingAvailable == 1) {
        const fData = new FormData();
        fData.append("file", event.target.files[0]);
        dispatch(loadingActions.start());
        dispatch(
          loadingActions.setLoaderText("Checking Virus In Uploaded File")
        );
        const response = await virusCheckerService(fData);
        dispatch(loadingActions.setLoaderText(null));
        dispatch(loadingActions.end());
        if (response.status == 200) {
          if (response.data && response.data.hasVirus) {
            setIsVirusFile(true);
            dispatch(alertActions.success(response.data.message));
            return;
          } else {
            setIsVirusFile(false);
            dispatch(alertActions.success(response.data.message));
            // formik.setFieldValue("regulatoryFile", event.target.files[0]);
          }
        } else {
          dispatch(alertActions.error("Virus Checker Service Failed"));
        }
      }
      //else {
      //   formik.setFieldValue("regulatoryFile", event.target.files[0]);
      // }
    }
    //  else {
    //   formik.setFieldValue("regulatoryFile", "");
    // }

    let hasRequiredHeaders = true;
    const required_headers = [
      "Regulation",
      "SequenceID",
      "RegulatorUniqueRef",
      "QuestionTEXT",
    ];
    let hasInvalidStructure = false;

    if (file && !isVirusFile) {
      setFileName(file.name);
      const reader = new FileReader();
      reader.onload = (e) => {
        const fileData = e.target.result;
        parsedContent = parseCSV(fileData);

        parsedContent.forEach((line, index) => {
          if (hasInvalidStructure) return;

          if (index == 0) {
            //validate file structure
            const headers = line.join(",");
            hasRequiredHeaders = required_headers.every((header) =>
              headers.includes(header.trim())
            );

            if (!hasRequiredHeaders) {
              BulkQuestionModal.closeModal();
              dispatch(
                alertActions.error(langConstant.ERROR_IN_FILE_STRUCTURE)
              );
              hasInvalidStructure = true;
              return;
            }
          } else {
            const [
              Regulation,
              Question_Version,
              SequenceID,
              RegulatorUniqueRef,
              Regulator_Control_Group,
              QuestionTEXT,
              QuestionID,
              Type,
            ] = line;

            parsedFileContent.push({
              RegulatorID: regulationOption.find(
                (reg) => reg.REGULATORY_DOMAIN_ID == formik.values.domain
              ).REGULATOR_ID,
              RegulationID: regulationOption.find(
                (reg) => reg.REGULATORY_DOMAIN_ID == formik.values.domain
              ).REGULATION_ID,
              RegulatoryDomainID: formik.values.domain,
              Regulation: Regulation?.trim(),
              Question_Version: Question_Version?.trim(),
              SequenceID: SequenceID?.trim(),
              RegulatorUniqueRef: RegulatorUniqueRef?.trim(),
              Regulator_Control_Group: Regulator_Control_Group?.trim(),
              QuestionTEXT: QuestionTEXT?.trim(),
              QuestionID: QuestionID?.trim(),
              QuestionType: Type?.trim(),
              NarrativeTemplate: "",
              ModelStrategyID: 1,
              Active: 1,
              IngestFlag: 1,
              QuestionClassification: 56,
              CiscoControlID: 30,
              QuestionTextHTML: QuestionTEXT?.trim(),
              CreateDate: moment().format("DD-MMM-YY"),
              StartDate: moment().format("DD-MMM-YY"),
              EndDate: moment().add(1, "year").format("DD-MMM-YY"),
            });
          }
        });
        setFileContent(parsedFileContent);
      };
      reader.readAsText(file);
    }
    const lastDotIndex = file.name.lastIndexOf(".");

    const bulkEvidenceData = {
      customerID: customerID,
      RegulationID: formik.values.regulationName,
      RegulatorID: formik.values.domain,
      fileName:
        lastDotIndex === -1 ? file.name : file.name.substring(0, lastDotIndex),
      fileContent: parsedFileContent,
      domainOptions: domainOptions,
      regulationOptions: regulationOption,
    };
    BulkQuestionModal.setData(bulkEvidenceData);

    BulkQuestionModal.openModal();
  };

  const openFileExplorer = () => {
    if (!regulationID) {
      dispatch(
        alertActions.error(
          langConstant.PLEASE_SELECT_REGULATORY_DOMAIN_COUNTRY_REGULATION_FIRST
        )
      );
      return;
    }
    document.getElementById("fileInput").click();
  };

  useEffect(() => {
    dispatch(regulationAction.getRegulationList(userID, customerID));
  }, []);

  useEffect(() => {
    const countries =
      regulationList.length > 0
        ? _.sortBy(_.uniqBy(regulationList, "COUNTRY_NAME"), "COUNTRY_NAME")
        : [];

    setCountriesOption(countries);
  }, [regulationList]);

  useEffect(() => {
    const domainsList =
      regulationList.length > 0
        ? _.sortBy(
            _.uniqBy(regulationList, "REGULATION_DOMAIN"),
            "REGULATION_DOMAIN"
          )
        : [];

    setDomainOption(domainsList);
  }, [regulationList]);

  useEffect(() => {}, [alert]);

  //Trigger file Input
  const triggerFileUpload = () => {
    inputFileRef.current.click();
  };

  const handleGenerateRuleBook = () => {
    //Logic for Generate Rule Book
  };

  //Reset file Input
  const resetFileUpload = () => {
    formik.setFieldValue("regulatoryFile", null);
    inputFileRef.current.value = "";
  };
  const fileUploadProgress = (event) => {
    setCount(Math.round((100 * event.loaded) / event.total));
  };
  const formik = useFormik({
    initialValues: {
      regulationName: "",
      country: "",
      regulatoryFile: "",
      domain: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (!isVirusFile) {
        let data = new FormData();
        data.append("file", values.regulatoryFile);
        data.append("countryCode", values.country);
        data.append("regulationCode", values.regulationName);
        data.append("userId", userID);
        data.append(
          "fileLocation",
          customerID.toString() + "/" + newRegulation + "/"
        );
        data.append("bucketName", window.BUCKET_NAME);
        data.append("storageType", docStorageType);
        data.append("preProcessingIP", docStorageIP);
        data.append("preProcessingURL", docStorageURL);
        const redirectTo = () => {
          history.push("/new-regulation");
        };
        dispatch(
          formActions.uploadFile(
            data,
            { "Content-type": "multipart/form-data" },
            redirectTo,
            fileUploadProgress
          )
        );
      } else {
        dispatch(alertActions.error("FILE CONTAINS VIRUS"));
      }
    },
  });
  const handleCountryChange = (e) => {
    formik.handleChange(e);
    const regulationByCountry = _.filter(regulationList, {
      COUNTRY_CODE: e.target.value,
    });
    setRegulationOption(regulationByCountry);
  };
  const handleDomainChange = (e) => {
    formik.handleChange(e);
    const countryByDomain = _.filter(regulationList, {
      REGULATORY_DOMAIN_ID: e.target.value,
    });
    setCountriesOption(countryByDomain);
  };
  if (loadingFile) {
    return <Loader count={count} />;
  }

  return (
    <Box style={{ width: "40%" }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h1 className="page-title">Upload New Regulation</h1>
        </Grid>
      </Grid>

      <form
        autoComplete="off"
        className={classes.form}
        onSubmit={formik.handleSubmit}
      >
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <div>
              <div>
                Regulatory Domain <span>*</span>
              </div>
              <TextField
                size="small"
                variant="outlined"
                fullWidth
                select
                id="domain"
                label=""
                name="domain"
                SelectProps={{
                  displayEmpty: true,
                }}
                value={formik.values.domain}
                onChange={(e) => {
                  handleDomainChange(e);
                  formik.setFieldValue("domainName", "");
                }}
                error={formik.touched.domain && Boolean(formik.errors.domain)}
                helperText={formik.touched.domain && formik.errors.domain}
              >
                <MenuItem key={1} value="">
                  Select
                </MenuItem>
                {domainOptions.map((option, key) => (
                  <MenuItem
                    // key={option.REGULATORY_DOMAIN_ID}
                    key={key}
                    value={option.REGULATORY_DOMAIN_ID}
                  >
                    {option.REGULATION_DOMAIN}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div>
              <div>
                Select Country <span>*</span>
              </div>
              <TextField
                size="small"
                variant="outlined"
                fullWidth
                select
                id="country"
                label=""
                name="country"
                SelectProps={{
                  displayEmpty: true,
                }}
                value={formik.values.country}
                onChange={(e) => {
                  handleCountryChange(e);
                  formik.setFieldValue("regulationName", "");
                }}
                error={formik.touched.country && Boolean(formik.errors.country)}
                helperText={formik.touched.country && formik.errors.country}
              >
                <MenuItem key={1} value="">
                  Select
                </MenuItem>
                {countriesOption.map((option, key) => (
                  <MenuItem
                    // key={option.COUNTRY_CODE}
                    key={key}
                    value={option.COUNTRY_CODE}
                  >
                    {option.COUNTRY_NAME}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div>
              Select Regulation <span>*</span>
            </div>
            <TextField
              size="small"
              variant="outlined"
              fullWidth
              select
              id="regulationName"
              label=""
              name="regulationName"
              SelectProps={{
                displayEmpty: true,
              }}
              value={formik.values.regulationName}
              onChange={(e) => {
                setRegulationID(
                  regulationOption.find(
                    (reg) => reg.REGULATION_ID == e.target.value
                  ).REGULATION_ID
                );

                formik.handleChange(e);
              }}
              error={
                formik.touched.regulationName &&
                Boolean(formik.errors.regulationName)
              }
              helperText={
                formik.touched.regulationName && formik.errors.regulationName
              }
            >
              <MenuItem key={1} value="">
                Select
              </MenuItem>
              {regulationOption.map((option, key) => (
                <MenuItem
                  style={{ whiteSpace: "normal", maxWidth: "250px" }}
                  // key={option.REGULATION_ID}
                  key={key}
                  value={option.REGULATION_ID}
                >
                  {option.REGULATION_NAME}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ width: "69%" }}>
          <Grid item xs={6}>
            <Box py={2}>
              <div>
                Upload File <span>*</span>
              </div>
              <div style={{ position: "relative" }}>
                <Button
                  // onClick={triggerFileUpload}
                  onClick={openFileExplorer}
                  fullWidth
                  color="primary"
                  variant="contained"
                  size="small"
                  startIcon={<FolderOpenIcon />}
                >
                  Browse File
                </Button>

                <input
                  // ref={inputFileRef}
                  type="file"
                  name="regulatoryFile"
                  accept=".csv"
                  id="fileInput"
                  className="hidden-input"
                  // onChange={async (event) => {
                  //   if (event.target.files.length > 0) {
                  //     if (isVirusCheckingAvailable == 1) {
                  //       const fData = new FormData();
                  //       fData.append("file", event.target.files[0]);
                  //       dispatch(loadingActions.start());
                  //       dispatch(
                  //         loadingActions.setLoaderText(
                  //           "Checking Virus In Uploaded File"
                  //         )
                  //       );
                  //       const response = await virusCheckerService(fData);
                  //       dispatch(loadingActions.setLoaderText(null));
                  //       dispatch(loadingActions.end());
                  //       if (response.status == 200) {
                  //         if (response.data && response.data.hasVirus) {
                  //           setIsVirusFile(true);
                  //           dispatch(
                  //             alertActions.success(response.data.message)
                  //           );
                  //         } else {
                  //           setIsVirusFile(false);
                  //           dispatch(
                  //             alertActions.success(response.data.message)
                  //           );
                  //           formik.setFieldValue(
                  //             "regulatoryFile",
                  //             event.target.files[0]
                  //           );
                  //         }
                  //       } else {
                  //         dispatch(
                  //           alertActions.error("Virus Checker Service Failed")
                  //         );
                  //       }
                  //     } else {
                  //       formik.setFieldValue(
                  //         "regulatoryFile",
                  //         event.target.files[0]
                  //       );
                  //     }
                  //   } else {
                  //     formik.setFieldValue("regulatoryFile", "");
                  //   }
                  //   handleFileChange;
                  // }}
                  // style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                {formik.values.regulatoryFile && (
                  <Box pt={1}>
                    <Grid
                      container
                      spacing={1}
                      alignItems="center"
                      direction="row"
                    >
                      <Grid item xs zeroMinWidth>
                        <Typography variant="body2" noWrap>
                          {formik.values.regulatoryFile.name}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <IconButton
                          aria-label="Upload Regulation"
                          size="small"
                          onClick={resetFileUpload}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      </Grid>
                      {isVirusCheckingAvailable == 1 && (
                        <Grid item>
                          {isVirusFile ? (
                            <Tooltip
                              title="Virus Found In File"
                              placement="right"
                            >
                              <CoronavirusIcon color="error" />
                            </Tooltip>
                          ) : (
                            <Tooltip
                              title="Virus Not Found In File"
                              placement="right"
                            >
                              <VerifiedIcon color="success" />
                            </Tooltip>
                          )}
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                )}
                {formik.touched.regulatoryFile &&
                  Boolean(formik.errors.regulatoryFile) && (
                    <FormHelperText
                      style={{ top: "auto", bottom: "-1.2rem" }}
                      error
                      className={classes.marginLeft14}
                    >
                      {formik.errors.regulatoryFile}
                    </FormHelperText>
                  )}
              </div>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ width: "69%" }}>
          <Grid item xs={6}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="default"
              size="small"
              className={rootClasses.greenButton}
              startIcon={<PublishIcon />}
            >
              Upload Regulation
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ width: "69%", marginTop: "10px" }}>
          <Grid item xs={6}>
            <Button
              onClick={handleGenerateRuleBook}
              fullWidth
              variant="contained"
              color="default"
              size="small"
              className={rootClasses.amberButton}
              startIcon={<LocalLibraryIcon />}
            >
              Generate Rule Book
            </Button>
          </Grid>
        </Grid>
        {fileContent && BulkQuestionModal?.isOpen && (
          <BulkQuestionUpload modal={BulkQuestionModal} />
        )}
      </form>
    </Box>
  );
};
export default UploadRegulatoryForm;
