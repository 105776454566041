import { Box, IconButton } from "@material-ui/core";
import React, { useState } from "react";
import langConstant from "_lang";
import NetworkGraph from "_components/NetworkGraph";
import useStyles from "./styles";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterDrawer from "./FilterDrawer";

const ComparisonGraph = () => {
  const [edges, setEdges] = useState([]);
  const [filteredNodes, setFilteredNodes] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);

  const classes = useStyles();

  return (
    <Box>
      <h1>{langConstant.COMPARISON_GRAPH}</h1>
      <NetworkGraph edges={edges} nodes={filteredNodes} />
      <IconButton
        className={classes.filterButton}
        onClick={() => setOpenDrawer(true)}
      >
        <FilterAltIcon />
      </IconButton>
      <FilterDrawer
        show={openDrawer}
        setShow={setOpenDrawer}
        edges={edges}
        setEdges={setEdges}
        filteredNodes={filteredNodes}
        setFilteredNodes={setFilteredNodes}
      />
    </Box>
  );
};

export default ComparisonGraph;
