import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Checkbox, Drawer, Grid, Typography } from "@material-ui/core";
import langConstant from "_lang";
import useStyles from "./styles";
import Select from "react-select";
import { alertActions, loadingActions } from "_actions";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import PolicyServices from "../../PolicyExplorer/services";
import ComparisonServices from "./services";
import NetworkUtils from "./utils";
import { Slider } from "@mui/material";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import OptionComponent from "containers/manage-regulation/NewRegulation/ManageQuestions/component/OptionComponent";

const FilterDrawer = ({
  show,
  setShow,
  edges,
  setEdges,
  filteredNodes,
  setFilteredNodes,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [graphView, setGraphView] = useState({ base: true, target: true });
  const [nodes, setNodes] = useState([]);
  const [comparisonText, setComparisonText] = useState([]);
  const [optionsData, setOptionsData] = useState({ base: [], target: [] });
  const [selectedOption, setSelectedOption] = useState({
    base: [],
    target: [],
  });
  const [selectedRange, setSelectedRange] = useState({
    value: 60,
    label: "60 % - 50 %",
    startRange: 50,
    endRange: 60,
  });
  const [customRange, setCustomRange] = useState([50, 60]);

  const rangeOptions = [
    { value: 70, label: "60 % +", startRange: 60, endRange: 100 },
    { value: 60, label: "60 % - 50 %", startRange: 50, endRange: 60 },
    { value: 50, label: "50 % - 40 %", startRange: 40, endRange: 50 },
    { value: 40, label: "40 % - 30 %", startRange: 30, endRange: 40 },
    { value: 30, label: "30 % - 20 %", startRange: 20, endRange: 30 },
    { value: 20, label: "20 % - 10 %", startRange: 10, endRange: 20 },
    { value: 10, label: "10 % - 0 %", startRange: -100, endRange: 10 },
  ];

  useEffect(() => {
    (async () => {
      dispatch(loadingActions.start());
      const response = await PolicyServices.getPolicyComparisonDetails(id);
      if (response.status == 200) {
        dispatch(
          NetworkUtils.getNodesData(
            response["#result-set-2"][0]["v_base_policy_id"],
            response["#result-set-3"][0]["v_target_policy_id"],
            setOptionsData,
            setSelectedOption,
            setNodes,
            setEdges
          )
        );
      }
      dispatch(loadingActions.end());
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (nodes.length > 0) {
        let networkData = NetworkUtils.filterNodes(
          nodes,
          graphView,
          selectedOption,
          setFilteredNodes,
          optionsData
        );
        dispatch(
          ComparisonServices.getPolicyComparisonNetworkData(
            id,
            networkData.baseHeaders,
            networkData.targetHeaders,
            customRange[0] / 100,
            customRange[1] / 100,
            setComparisonText
          )
        );
      }
    })();
  }, [nodes, selectedRange]);

  useEffect(() => {
    if (comparisonText && comparisonText.length > 0) {
      let baseNodes = filteredNodes.filter(
        (node) => node.policyType === "base"
      );
      let targetNodes = filteredNodes.filter(
        (node) => node.policyType === "target"
      );
      let tempEdges = [
        ...edges.filter(
          (edge) => edge.type === "base" || edge.type === "target"
        ),
      ];
      baseNodes.forEach((node) => {
        let tempComparisonData = comparisonText.filter(
          (comp) =>
            comp.BASE_DOCUMENT === node.text &&
            node.level.startsWith(comp.TYPE.trim()) &&
            comp.SEMANTIC_SCORE >= customRange[0] / 100 &&
            comp.SEMANTIC_SCORE <= customRange[1] / 100
        );
        tempComparisonData.forEach((comp) => {
          const tempTargetNodes = targetNodes.filter(
            (tNode) =>
              tNode.level.startsWith(comp.TYPE.trim()) &&
              tNode.text === comp.TARGET_DOCUMENT
          );
          tempTargetNodes.forEach((tNode) => {
            tempEdges.push({
              from: node.id,
              to: tNode.id,
              type: "comparison",
              label:
                parseFloat(comp.SEMANTIC_SCORE * 100)
                  .toFixed(2)
                  .toString() + "%",
            });
          });
        });
      });
      setEdges(tempEdges);
    } else {
      let tempEdges = [
        ...edges.filter(
          (edge) => edge.type === "base" || edge.type === "target"
        ),
      ];
      setEdges(tempEdges);
    }
  }, [comparisonText, customRange]);

  const handleCustomRange = (e) => {
    setCustomRange(e.target.value);
  };

  const generateGraph = () => {
    if (nodes.length > 0) {
      let networkData = NetworkUtils.filterNodes(
        nodes,
        graphView,
        selectedOption,
        setFilteredNodes,
        optionsData
      );
      dispatch(
        ComparisonServices.getPolicyComparisonNetworkData(
          id,
          networkData.baseHeaders,
          networkData.targetHeaders,
          customRange[0] / 100,
          customRange[1] / 100,
          setComparisonText
        )
      );
    }
  };

  return (
    <Drawer anchor={"right"} open={show} onClose={() => setShow(false)}>
      <Grid style={{ width: "450px", padding: "30px 15px" }}>
        <Grid>
          <Typography className={classes.filterHeader}>
            {langConstant.NETWORK_SETTINGS}
          </Typography>
        </Grid>
        <Grid style={{ padding: "20px 15px" }}>
          <Grid>
            <Typography className={classes.filterSubHeader}>
              {langConstant.RELATIONSHIP_SETTINGS}
            </Typography>
            <Grid
              className={classes.networkFilterWrapper}
              style={{ paddingTop: "15px" }}
            >
              <Typography className={classes.filterLable}>
                {langConstant.RELATIONSHIP_STRENGTH}
              </Typography>
              <Select
                className={classes.filterSelect}
                options={rangeOptions}
                value={selectedRange}
                onChange={(range) => {
                  setSelectedRange(range);
                  setCustomRange([range.startRange, range.endRange]);
                }}
              />
            </Grid>
            <Grid>
              <Typography className={classes.filterLable}>
                {langConstant.SELECT_CUSTOM_RANGE}
              </Typography>
              <Slider
                value={customRange}
                min={selectedRange.startRange}
                max={selectedRange.endRange}
                onChange={(e) => handleCustomRange(e)}
                valueLabelDisplay="auto"
                step={0.5}
                className={classes.filterSlider}
              />
            </Grid>
          </Grid>
          <Grid>
            <Typography className={classes.filterSubHeader}>
              {langConstant.QUESTION_SETTINGS}
            </Typography>
            <Grid
              className={classes.filterWrapper}
              style={{ paddingTop: "15px" }}
            >
              <Typography className={classes.filterLable}>
                {langConstant.BASE_DOCUMENT}
              </Typography>
              <Checkbox
                checked={graphView.base}
                onClick={(e) => {
                  if (!graphView.target && !e.target.checked) {
                    dispatch(
                      alertActions.error(
                        "Required to select at least one document"
                      )
                    );
                  } else {
                    setGraphView((prevState) => ({
                      ...prevState,
                      base: e.target.checked,
                    }));
                  }
                }}
                sx={{
                  "&.Mui-checked": { color: "#124571" },
                  display: "inline",
                }}
                size="small"
              />
            </Grid>
            <Select
              className={classes.filterSelect}
              options={optionsData.base}
              components={{ Option: OptionComponent }}
              value={selectedOption.base}
              isMulti
              closeMenuOnSelect={false}
              close
              hideSelectedOptions={false}
              allowSelectAll={true}
              onChange={(option) => {
                setSelectedOption((prevState) => ({
                  ...prevState,
                  base: option,
                }));
              }}
            />
            <Grid
              className={classes.filterWrapper}
              style={{ paddingTop: "15px" }}
            >
              <Typography className={classes.filterLable}>
                {langConstant.TARGET_DOCUMENT}
              </Typography>
              <Checkbox
                checked={graphView.target}
                onClick={(e) => {
                  if (!graphView.base && !e.target.checked) {
                    dispatch(
                      alertActions.error(
                        "Required to select at least one document"
                      )
                    );
                  } else {
                    setGraphView((prevState) => ({
                      ...prevState,
                      target: e.target.checked,
                    }));
                  }
                }}
                sx={{
                  "&.Mui-checked": { color: "#124571" },
                  display: "inline",
                }}
                size="small"
              />
            </Grid>
            <Select
              className={classes.filterSelect}
              components={{ Option: OptionComponent }}
              options={optionsData.target}
              value={selectedOption.target}
              isMulti
              closeMenuOnSelect={false}
              close
              hideSelectedOptions={false}
              allowSelectAll={true}
              onChange={(option) => {
                setSelectedOption((prevState) => ({
                  ...prevState,
                  target: option,
                }));
              }}
            />
            <Grid className={classes.btnWrapper}>
              <Button
                size="small"
                variant="contained"
                className={classes.btnGraph}
                onClick={generateGraph}
                startIcon={<AutoGraphIcon />}
              >
                {langConstant.GENERATE_GRAPH}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Drawer>
  );
};

FilterDrawer.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  edges: PropTypes.array,
  setEdges: PropTypes.func,
  filteredNodes: PropTypes.array,
  setFilteredNodes: PropTypes.func,
};

export default FilterDrawer;
